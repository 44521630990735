import { ARGENT_WALLET_DETECTOR_ADDRESS, MULTICALL_ADDRESS } from "constants/addresses"
import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import { getMulticallV3Address } from "hooks/1delta/addresses"
import { Call, multicallViem, ProviderType } from "utils/multicall"
import ERC20 from 'abis/erc20.json'
import { getFallbackViemProvider } from "hooks/providers/evm/useViemClient"
import { SupportedChainId } from "constants/chains"

const MultiABI = [
  {
    "inputs": [],
    "name": "getCurrentBlockTimestamp",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "addr",
        "type": "address"
      }
    ],
    "name": "getEthBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getBlockNumber",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "blockNumber",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
]


export type AllowanceEntry = { [target: string]: { allowance: string, owner: string } }

export interface SubscribedData {
  allowanceData?: AllowanceEntry
  balance: string
}


export async function fetchUserTokenData(
  chainId: number,
  account: string,
  assetsToQuery: string[],
  approvalTargets: string[],
  isArgentWallet: boolean | undefined
) {
  const callsBase: Call[] = [{
    address: MULTICALL_ADDRESS[chainId],
    name: 'getCurrentBlockTimestamp',
    params: [],
  }, {
    address: MULTICALL_ADDRESS[chainId],
    name: 'getEthBalance',
    params: [account],
  },
  {
    address: getMulticallV3Address(chainId),
    name: 'getBlockNumber',
    params: [],
  }
  ]
  let argentFetched = false
  if (isArgentWallet === undefined) {
    const detector = ARGENT_WALLET_DETECTOR_ADDRESS[chainId]
    if (detector) {
      argentFetched = true
      callsBase.push(
        {
          address: detector,
          name: 'isArgentWallet',
          params: [account],
        }
      )
    }
  }
  console.log("fetching for", chainId)
  let callsAdditionalBalances: Call[] = []
  let callsAdditionalAllowances: Call[] = []
  if (assetsToQuery) {
    assetsToQuery = assetsToQuery.map(a => a.toLowerCase())
    callsAdditionalBalances = assetsToQuery.map((address) => {
      return {
        address,
        name: 'balanceOf',
        params: [account],
      }

    })
    if (approvalTargets && approvalTargets.length > 0) {
      callsAdditionalAllowances = approvalTargets.map(
        target => assetsToQuery!.map((address) => {
          return {
            address,
            name: 'allowance',
            params: [account, target],
          }

        })
      ).reduce((a, b) => [...a, ...b])
    }
  }
  const calls = [ // always get these
    ...callsBase,
    // these depend on the data subscription
    ...callsAdditionalBalances, ...callsAdditionalAllowances
  ]
  let multicallResult: any[];
  try {
    multicallResult = await multicallViem(
      chainId,
      [...MultiABI, ...ERC20, ...ARGENT_WALLET_DETECTOR_ABI],
      calls,
      undefined,
      false,
      ProviderType.balance
    )
  } catch (e) {
    console.log("failed multicall for subscribed data", chainId, calls.length, e)
    multicallResult = []
  }
  const balanceLength = 0
  const baseLength = callsBase.length
  const base = multicallResult.slice(balanceLength, balanceLength + baseLength)


  let tokenData: { [address: string]: SubscribedData } = {}
  let allowanceMap: { [a: string]: AllowanceEntry } = {}

  const additionalBalancesLength = callsAdditionalBalances.length
  const additionalBalances = multicallResult.slice(balanceLength + baseLength, balanceLength + baseLength + additionalBalancesLength)
  let additionalAllowances: any[]

  /** add fetch to allowances if provided */
  if (approvalTargets && approvalTargets.length > 0) {
    additionalAllowances = multicallResult.slice(balanceLength + baseLength + additionalBalancesLength, multicallResult.length)
    const keys = approvalTargets.map(t => t.toLowerCase())
    const assetCount = assetsToQuery.length
    allowanceMap = Object.assign({}, ...assetsToQuery.map((a, assetIndex) => {
      return {
        [a]: Object.assign({}, ...keys.map((k, keyIndex) => {
          return {
            [k]: {
              allowance: fallbackOnResult(additionalAllowances[assetIndex + keyIndex * assetCount]?.toString(), '0'),
              owner: account
            }
          }
        })
        )
      }
    }
    ))
  }
  tokenData = Object.assign(
    {},
    ...additionalBalances.map((entry: any, index) => {
      const asset = assetsToQuery![index]
      return {
        [asset]: {
          balance: fallbackOnResult(entry.toString(), '0'),
          allowanceData: approvalTargets ? allowanceMap[asset] : {}
        },
      }
    })
  )

  return {
    timestamp: fallbackOnResult(base[0]?.toString()),
    nativeBalance: fallbackOnResult(base[1]?.toString()),
    blockNumber: Number(fallbackOnResult(base[2]?.toString())),
    isArgentWallet: argentFetched ? Boolean(base?.[3] ?? false) : isArgentWallet,
    tokenData,
    account,
    chainId
  }
}


export async function fetchBlockchainMetadata(chainId: number) {
  const calls: Call[] = [{
    address: MULTICALL_ADDRESS[chainId],
    name: 'getCurrentBlockTimestamp',
    params: [],
  },
  {
    address: getMulticallV3Address(chainId),
    name: 'getBlockNumber',
    params: [],
  },
  ]

  const multicallResult = await multicallViem(
    chainId,
    MultiABI,
    calls,
    undefined,
    undefined,
    ProviderType.balance
  )
  const ts = multicallResult[0].toString()
  const bn = multicallResult[1].toString()
  return {
    timestamp: ts === '0x' ? 0 : ts,
    blockNumber: bn === '0x' ? 0 : bn,
    nativeBalance: undefined,
    tokenData: {},
    account: undefined,
    isArgentWallet: undefined,
    chainId
  }

}

function fallbackOnResult(result: string, fallbackValue = '0') {
  return result === '0x' ? fallbackValue : result
}


export async function fetchGasDataForChain(chainId: number) {
  let gasData: any | undefined
  try {
    gasData = await (getFallbackViemProvider({ chainId, id: 0 }) as any).estimateFeesPerGas()
  } catch (e: any) {
    gasData = undefined
  }

  return gasData ? {
    maxFeePerGas: gasData?.maxFeePerGas,
    maxPriorityFeePerGas: gasData?.maxPriorityFeePerGas
  } : {}
}

export function upscaleGas(am: bigint | string | undefined, chainId: number) {
  if (!am) return undefined
  if (chainId === SupportedChainId.TAIKO) // +10%
    return (BigInt(am) * 11n / 10n).toString()
  if (chainId === SupportedChainId.MANTLE)
    return BigInt(am).toString() // +5%
  else return (BigInt(am) * 105n / 100n).toString()
}