import { encodeFunctionData } from "viem"
import AAVE_V3_POOL_ABI from 'abis/aave-v3/pool.json'
import { AaveInterestMode } from "types/1delta"

enum AaveV3Functions {
  Supply = "supply",
  Withdraw = "withdraw",
  Borrow = "borrow",
  Repay = "repay",
  RepayWithATokens = "repayWithATokens",
  SetUserEMode = "setUserEMode",
}

const REFERRAL_CODE = 0

export namespace AaveV3Lending {
  export const encodeDeposit = (receiver: string, asset: string, amount: bigint | string) => {
    return encodeFunctionData({
      abi: AAVE_V3_POOL_ABI,
      functionName: AaveV3Functions.Supply,
      args: [asset, amount, receiver, REFERRAL_CODE]
    })
  }

  export const encodeBorrow = (receiver: string, asset: string, amount: bigint | string, interestRateMode: AaveInterestMode) => {
    return encodeFunctionData({
      abi: AAVE_V3_POOL_ABI,
      functionName: AaveV3Functions.Borrow,
      args: [asset, amount, interestRateMode, REFERRAL_CODE, receiver]
    })
  }

  export const encodeWithdraw = (receiver: string, asset: string, amount: bigint | string) => {
    return encodeFunctionData({
      abi: AAVE_V3_POOL_ABI,
      functionName: AaveV3Functions.Withdraw,
      args: [asset, amount, receiver]
    })
  }

  export const encodeRepay = (receiver: string, asset: string, amount: bigint | string, interestRateMode: AaveInterestMode) => {
    return encodeFunctionData({
      abi: AAVE_V3_POOL_ABI,
      functionName: AaveV3Functions.Repay,
      args: [asset, amount, interestRateMode, receiver]
    })
  }

  export const encodeRepayWithAToken = (asset: string, amount: bigint | string, interestRateMode: AaveInterestMode) => {
    return encodeFunctionData({
      abi: AAVE_V3_POOL_ABI,
      functionName: AaveV3Functions.RepayWithATokens,
      args: [asset, amount, interestRateMode]
    })
  }

  export const encodeSetUserEMode = (mode: number) => {
    return encodeFunctionData({
      abi: AAVE_V3_POOL_ABI,
      functionName: AaveV3Functions.SetUserEMode,
      args: [mode]
    })
  }
}