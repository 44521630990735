import { Percent } from '@1delta/base-sdk'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60n * 30n
export const L2_DEADLINE_FROM_NOW = 60n * 5n

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 25000n
export const L2_TXN_DISMISS_MS = 5000n

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = 60n * 60n * 24n * 7n

export const BIG_INT_ZERO = 0n

// one basis BigInt
const BIPS_BASE = 10000n
export const ONE_BIPS = new Percent(1n, BIPS_BASE)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(100n, BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(300n, BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(500n, BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(1000n, BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(1500n, BIPS_BASE) // 15%

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')
