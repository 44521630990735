import { ADDRESS_ZERO, Currency, CurrencyAmount, Token } from '@1delta/base-sdk'
import {
  TOKEN_META,
} from 'constants/1delta'
import { DEFAULT_CHAINID } from 'constants/chains'
import { AaveInterestMode, NATIVE_ASSET, SupportedAssets, getFallbackAsset } from 'types/1delta'
import {
  addressesAaveATokens,
  addressesAaveSTokens,
  addressesAaveVTokens,
} from './addressesAave'
import { addressesTokens, assetToAddress, tokenToAsset } from './addressesTokens'
import { addressesLendleLTokens, addressesLendleSTokens, addressesLendleVTokens } from './addressesLendle'
import { nativeOnChain } from 'constants/tokens'
import { Lender } from 'types/lenderData/base'
import { addressesAureliusATokens, addressesAureliusSTokens, addressesAureliusVTokens } from './addressesAurelius'
import { addressesAaveV2ATokens, addressesAaveV2STokens, addressesAaveV2VTokens } from './addressesAaveV2'
import { addressesMeridianATokens, addressesMeridianSTokens, addressesMeridianVTokens } from './addressesMeridian'
import { addressesTakoTakoATokens, addressesTakoTakoSTokens, addressesTakoTakoVTokens } from './addressesTakoTako'
import { getAddressesForChainIdFromAssetDict } from './addresses'

const FALLBACK_ADDRESS = "0x0000000000000000000000000000000000000001"


export function getAaveStyleATokenMap(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return getAddressesForChainIdFromAssetDict(addressesAaveV2ATokens, chainId)
    case Lender.AAVE_V3: return getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId)
    case Lender.AURELIUS: return getAddressesForChainIdFromAssetDict(addressesAureliusATokens, chainId)
    case Lender.LENDLE: return getAddressesForChainIdFromAssetDict(addressesLendleLTokens, chainId)
    case Lender.MERIDIAN: return getAddressesForChainIdFromAssetDict(addressesMeridianATokens, chainId)
    case Lender.TAKOTAKO: return getAddressesForChainIdFromAssetDict(addressesTakoTakoATokens, chainId)
    default: return {}
  }
}

export function getAaveStyleVTokenMap(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return getAddressesForChainIdFromAssetDict(addressesAaveV2VTokens, chainId)
    case Lender.AAVE_V3: return getAddressesForChainIdFromAssetDict(addressesAaveVTokens, chainId)
    case Lender.AURELIUS: return getAddressesForChainIdFromAssetDict(addressesAureliusVTokens, chainId)
    case Lender.LENDLE: return getAddressesForChainIdFromAssetDict(addressesLendleVTokens, chainId)
    case Lender.MERIDIAN: return getAddressesForChainIdFromAssetDict(addressesMeridianVTokens, chainId)
    case Lender.TAKOTAKO: return getAddressesForChainIdFromAssetDict(addressesTakoTakoVTokens, chainId)
    default: return {}
  }
}

export function getAaveStyleSTokenMap(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return getAddressesForChainIdFromAssetDict(addressesAaveV2STokens, chainId)
    case Lender.AAVE_V3: return getAddressesForChainIdFromAssetDict(addressesAaveSTokens, chainId)
    case Lender.AURELIUS: return getAddressesForChainIdFromAssetDict(addressesAureliusSTokens, chainId)
    case Lender.LENDLE: return getAddressesForChainIdFromAssetDict(addressesLendleSTokens, chainId)
    case Lender.MERIDIAN: return getAddressesForChainIdFromAssetDict(addressesMeridianSTokens, chainId)
    case Lender.TAKOTAKO: return getAddressesForChainIdFromAssetDict(addressesTakoTakoSTokens, chainId)
    default: return {}
  }
}

/**
 * Get a currency object from lender asset enum and chainId
 * @param chainId network
 * @param asset lender asset enum
 * @returns Currency object
 */
export function safeGetCurrency(chainId: number, asset: SupportedAssets): Currency {
  if (asset === NATIVE_ASSET[chainId]) return nativeOnChain(chainId)
  const address = assetToAddress(asset, chainId)
  // just default to native if no address is found
  if (!address) return nativeOnChain(chainId)
  return new Token(
    chainId,
    address as any,
    TOKEN_META[asset].decimals,
    TOKEN_META[asset].symbol,
    TOKEN_META[asset].name
  )
}

/**
 * Gets a token for a given asset, if none is found, a default token is returned
 * Lending protocol only makes a difference on testnets 
 * as each lender hsa their own e.g. USDC deployment 
 * @param chainId chainId 
 * @param asset asset enum
 * @param lendingProtocol lending protocol
 * @returns Token related to asset or default token
 */
export const safeGetToken = (
  chainId: number,
  asset: SupportedAssets,
): Token => {
  const safeChainId = chainId ?? DEFAULT_CHAINID
  if (asset === NATIVE_ASSET[chainId]) {
    return nativeOnChain(chainId).wrapped
  }

  const tokenAddress = assetToAddress(asset, chainId) ?? FALLBACK_ADDRESS

  try {
    let _asset = String(asset).toUpperCase()
    if (_asset.toUpperCase() === 'MIMATIC') { _asset = SupportedAssets.MAI }
    return new Token(
      safeChainId,
      tokenAddress as any, // defaults to WETH
      TOKEN_META[_asset].decimals,
      TOKEN_META[_asset].symbol,
      TOKEN_META[_asset].name
    )
  } catch (e) {
    console.log("Error getting token from asset:", asset, e)
    return new Token(
      safeChainId,
      tokenAddress as any, // defaults to WETH
      TOKEN_META[SupportedAssets.WETH].decimals,
      TOKEN_META[SupportedAssets.WETH].symbol,
      TOKEN_META[SupportedAssets.WETH].name
    )
  }
}

/**
 * Converts underlying token class to aToken
 * @param currency currency to convert
 * @returns aToken with USDC as fallback
 */
export const toCollateralToken = (currency?: Currency, lender = Lender.AAVE_V3): Token => {
  const chainId = currency?.chainId ?? DEFAULT_CHAINID
  const _asset = tokenToAsset(currency)

  const address = getCollateralTokenAddress(chainId, lender, _asset)
  return new Token(
    chainId,
    address as any,
    TOKEN_META[_asset]?.decimals ?? 18,
    TOKEN_META[_asset]?.symbol,
    TOKEN_META[_asset]?.name
  )
}

/**
 * Converts underlying currency amount to aToken amount
 * @param currencyAmount currencyAmount to convert
 * @param lender aave V3 or a valid aave V2 fork
 * @returns aToken amount with USDC as fallback
 */
export const toATokenAmount = (currencyAmount?: CurrencyAmount<Currency> | undefined, lender = Lender.AAVE_V3): CurrencyAmount<Currency> => {
  const aToken = toCollateralToken(currencyAmount?.currency, lender)
  return CurrencyAmount.fromRawAmount(aToken, currencyAmount?.quotient.toString() ?? '0')
}

/**
 * Converts underlying token class to either a vToken or a sToken
 * @param currency currency to convert
 * @param lender aave V3 or a valid aave V2 fork
 * @param interestMode the interest mode
 * @returns debt token with USDC as fallback
 */
export const toDebtToken = (currency?: Currency, interestMode?: AaveInterestMode, lender = Lender.AAVE_V3): Token => {
  const chainId = currency?.chainId ?? DEFAULT_CHAINID
  const _asset = tokenToAsset(currency)
  const _irMode = interestMode ?? AaveInterestMode.VARIABLE
  const isStable = _irMode === AaveInterestMode.STABLE
  let address = ADDRESS_ZERO

  if (isStable) {
    address = getAaveStyleSTokenMap(chainId, lender)?.[_asset]
  } else {
    address = getAaveStyleVTokenMap(chainId, lender)?.[_asset]
  }
  return new Token(
    chainId,
    (address ?? FALLBACK_ADDRESS) as any,
    TOKEN_META[_asset]?.decimals ?? 18,
    TOKEN_META[_asset]?.symbol,
    `${isStable ? 's' : 'v'}${TOKEN_META[_asset]?.name}`
  )
}

/**
 * Converts underlying currency amount to debtToken amount
 * @param currencyAmount currencyAmount to convert
 * @param interestRateMode ir mode of debt
 * @param lender aave V3 or a valid aave V2 fork
 * @returns debt token amount with USDC as fallback
 */
export const toDebtTokenAmount = (currencyAmount?: CurrencyAmount<Currency> | undefined, interestMode?: AaveInterestMode, lender = Lender.AAVE_V3): CurrencyAmount<Currency> => {
  const debtToken = toDebtToken(currencyAmount?.currency, interestMode, lender)
  return CurrencyAmount.fromRawAmount(debtToken, currencyAmount?.quotient.toString() ?? '0')
}

export const filterSupportedAssets = (token0?: Currency, token1?: Currency): SupportedAssets[] => {
  const chainId = token0?.chainId ?? DEFAULT_CHAINID
  const arr: SupportedAssets[] = []
  const asset0 = tokenToAsset(token0)
  if (token0 && safeGetToken(chainId, asset0)?.equals(token0))
    arr.push(asset0)
  const asset1 = tokenToAsset(token1)
  if (token1 && safeGetToken(chainId, asset1)?.equals(token1))
    arr.push(asset1)

  return arr
}

export const getCollateralTokenAddress = (chainId: number | undefined, lender: Lender | undefined, asset: string | undefined) => {
  if (!chainId || !asset || !lender) return FALLBACK_ADDRESS
  const _asset = getFallbackAsset(asset)
  return getAaveStyleATokenMap(chainId, lender)?.[_asset] ?? FALLBACK_ADDRESS
}

export const getTokenFromAsset = (asset: SupportedAssets, chainId: number) => {
  const address = addressesTokens[asset]?.[chainId]
  const meta = TOKEN_META[asset]
  if (!address || !meta) return undefined
  return new Token(chainId, address, meta.decimals, meta.symbol, meta.name)
}