import { Lender } from 'types/lenderData/base'
import { InitGetUserPositionDataIndexes, InitUserReserveResponse } from './types'
import { TOKEN_META } from 'constants/1delta'
import { getLenderAssets } from 'constants/getAssets'
import { addressToAsset } from 'hooks/1delta/addressesTokens'
import { initBrokerAddresses } from 'hooks/1delta/addresses1Delta'
import { parseRawAmount } from 'utils/tableUtils/prices'

export const getInitUserDataConverter = (
  lender: Lender,
  chainId: number,
  account: string,
  prices: { [asset: string]: number }
): [(data: any[]) => InitUserReserveResponse | undefined, number] => {
  switch (lender) {
    case Lender.INIT: {
      const validOwner = account.toLowerCase()
      const brokerAddress = initBrokerAddresses[chainId] ?? ''
      const expectedNumberOfCalls = 1
      return [
        (_data: any[]) => {
          if (_data.length !== expectedNumberOfCalls) {
            return undefined
          }
          const [data] = _data
          // filter for owner
          const indexes = data[InitGetUserPositionDataIndexes.posMeta].map(
            (e, i) => { return { e, i } }
          ).filter(a => a.e.owner.toLowerCase() === validOwner).map(a => a.i)
          const assets = getLenderAssets(chainId, Lender.INIT)
          // map data
          const result: any = Object.assign(
            {},
            ...indexes.map((index) => {
              const entry = data[InitGetUserPositionDataIndexes.posMeta][index]
              const mode = entry.mode
              const id = entry.posId.toString()
              const collatData = Object.assign({}, ...data[InitGetUserPositionDataIndexes.collaterals][index].map(c => {
                const asset = addressToAsset(chainId, c.underlying)
                const decimals = TOKEN_META[asset]?.decimals ?? 18
                return { [asset]: { deposits: parseRawAmount(c.amount.toString(), decimals) } }
              })
              )
              const debtData = Object.assign({}, ...data[InitGetUserPositionDataIndexes.debts][index].map(c => {
                const asset = addressToAsset(chainId, c.underlying)
                const decimals = TOKEN_META[asset]?.decimals ?? 18
                return { [asset]: { debt: parseRawAmount(c.amount.toString(), decimals) } }
              })
              )

              return {
                [id]: {
                  mode,
                  isApprovedForAll: entry.isApprovedForAll,
                  isAllowed: entry.approved.toLowerCase() === brokerAddress.toLowerCase(),
                  positions: Object.assign({}, ...assets.map(a => {
                    const price = prices[a] ?? 1
                    return {
                      [a]: {
                        deposits: collatData[a]?.deposits ?? 0,
                        debt: debtData[a]?.debt ?? 0,
                        debtStable: 0,
                        debtStableUSD: 0,
                        depositsUSD: price * (collatData[a]?.deposits ?? 0),
                        debtUSD: price * (debtData[a]?.debt ?? 0),
                        collateralActive: true,
                      }
                    }
                  })
                  )
                }
              }
            })
          )

          return {
            chainId,
            tokensData: result,
            assets,
            account
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}