import { getLenderAssets } from "constants/getAssets";
import { AURELIUS_REWARD_ASSETS } from "constants/lenderRewardAssets";
import { getAaveTypeIncentivesControllerAddress, getAaveTypePoolDataProviderAddress } from "hooks/1delta";
import { getAaveStyleATokenMap, getAaveStyleVTokenMap } from "hooks/1delta/tokens";
import { getLenderTokenAddresses } from "hooks/lenders/lenderAddressGetter";
import { Lender } from "types/lenderData/base";

export const buildAaveV2StyleLenderReserveCall = (chainId: number, lender: Lender) => {
  const providerAddress = getAaveTypePoolDataProviderAddress(chainId, lender)
  const underlyingTokens = getLenderTokenAddresses(chainId, lender)
  const assetsToQuery = getLenderAssets(chainId, lender)
  switch (lender) {
    case Lender.AAVE_V2:
    case Lender.TAKOTAKO:
    case Lender.MERIDIAN:
      return [
        ...assetsToQuery.flatMap(tk => [
          {
            address: providerAddress,
            name: 'getReserveData',
            params: [underlyingTokens[tk]],
          },
          {
            address: providerAddress,
            name: 'getReserveConfigurationData',
            params: [underlyingTokens[tk]],
          },
        ]),
      ]
    case Lender.AURELIUS: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const aTokens = getAaveStyleATokenMap(chainId, lender)
      const vTokens = getAaveStyleVTokenMap(chainId, lender)
      const incentivesController = getAaveTypeIncentivesControllerAddress(chainId, lender)
      const [reward0, reward1] = AURELIUS_REWARD_ASSETS.map(t => t.address)
      return [
        ...assetsToQuery.flatMap(asset => [
          {
            address: providerAddress,
            name: 'getReserveData',
            params: [underlyingTokens[asset]],
          },
          {
            address: providerAddress,
            name: 'getReserveConfigurationData',
            params: [underlyingTokens[asset]],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [aTokens[asset], reward0],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [aTokens[asset], reward1],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [vTokens[asset], reward0],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [vTokens[asset], reward1],
          },
        ]),
      ]
    }
    case Lender.LENDLE: {
      const aTokenDict = getAaveStyleATokenMap(chainId, lender)
      const incentivesController = getAaveTypeIncentivesControllerAddress(chainId, lender)
      const vTokenDict = getAaveStyleVTokenMap(chainId, lender)
      return [
        ...assetsToQuery.flatMap(asset => [
          {
            address: providerAddress,
            name: 'getReserveData',
            params: [underlyingTokens[asset]],
          },
          {
            address: providerAddress,
            name: 'getReserveConfigurationData',
            params: [underlyingTokens[asset]],
          },
          {
            address: incentivesController,
            name: 'poolInfo',
            params: [aTokenDict[asset]],
          },
          {
            address: incentivesController,
            name: 'poolInfo',
            params: [vTokenDict[asset]],
          }
        ]),
        {
          address: incentivesController,
          name: 'rewardsPerSecond',
          params: []
        },
        {
          address: incentivesController,
          name: 'totalAllocPoint',
          params: []
        },
      ]
    }
    default: return []
  }
}