import { SupportedChainId } from 'constants/chains'
import { FlatAddressDictionary } from '../1delta/addresses'
import { addressesTokens } from '../1delta/addressesTokens'
import { Lender } from 'types/lenderData/base'
import { getLenderAssets } from 'constants/getAssets'

export const getLenderTokenAddresses = (chainId: number, protocol = Lender.AAVE_V3): FlatAddressDictionary => {
  return Object.assign(
    {},
    ...getLenderAssets(chainId, protocol).map((asset) => {
      return { [asset]: addressesTokens[asset][chainId] }
    })
  )
}

export const getCompoundV3TokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (chainId === SupportedChainId.POLYGON)
    return Object.assign(
      {},
      ...getLenderAssets(SupportedChainId.POLYGON, Lender.COMPOUND_V3).map((asset) => {
        return { [asset]: addressesTokens[asset][SupportedChainId.POLYGON] }
      })
    )

  return {}
}
