import { MultiABI, MULTICALL_ADDRESS } from "constants/addresses"
import { Call, multicallViem } from "utils/multicall"
import ERC20_ABI from 'abis/erc20.json'
import { decodeFunctionResult, encodeFunctionData, fromHex } from "viem"

enum Erc20Names {
  name = 'name',
  symbol = 'symbol',
  decimals = 'decimals'
}
function encodeErc20Call(functionName: string) {
  return (encodeFunctionData as any)({ abi: ERC20_ABI, args: [], functionName })
}

function decodeErc20Call(functionName: string, data: string) {
  return (decodeFunctionResult as any)({ abi: ERC20_ABI, functionName, data })
}

export async function fetchTokenMetaData(
  chainId: number,
  token: string,
) {
  const target = MULTICALL_ADDRESS[chainId]
  // struct Call {
  //     address target;
  //     uint256 gasLimit;
  //     bytes callData;
  // }
  const calls: Call[] = [{
    address: target,
    name: 'multicall',
    params: [[{
      target: token,
      gasLimit: 1e10,
      callData: encodeErc20Call(Erc20Names.symbol)
    }]],
  }, {
    address: target,
    name: 'multicall',
    params: [[{
      target: token,
      gasLimit: 1e10,
      callData: encodeErc20Call(Erc20Names.name)
    }]],
  },
  ]
  const decimalCall = {
    address: token,
    name: Erc20Names.decimals,
    params: [],
  }

  let multicallResult: any[];
  try {
    multicallResult = await multicallViem(
      chainId,
      [...ERC20_ABI, ...MultiABI],
      [decimalCall, ...calls],
      1
    )
    let decimals
    if (multicallResult[0] === '0x') {
      return { chainId }
    } else {
      decimals = Number(multicallResult[0])
    }

    const symbolResult = multicallResult[1]?.[1]?.[0]
    const nameResult = multicallResult[2]?.[1]?.[0]
    let name
    let symbol
    if (!symbolResult.success) {
      symbol = 'unknown'
    } else {
      symbol = parseBytes32OrString(symbolResult.returnData, Erc20Names.symbol)
    }
    if (!nameResult.success) {
      name = 'unknown'
    } else {
      name = parseBytes32OrString(nameResult.returnData, Erc20Names.name)
    }
    return {
      chainId,
      tokenData: {
        address: token.toLowerCase(),
        name,
        symbol,
        decimals
      }
    }
  } catch (e) {
    console.log("failed multicall for subscribed data", e)
    return {
      chainId
    }
  }
}

function parseBytes32OrString(result: string, name: string) {
  if (result.length === 66) {
    return fromHex(result as any, { size: 32, to: 'string' })
  }
  return decodeErc20Call(name, result)
}