import { Call, multicallViem } from 'utils/multicall'

// aave v2 style
import AAVE_POOL_AND_DATA_PROVIDER_ABI from 'abis/aave/AAVEPoolAndDataProvider.json'
import STABLE_DEBT_TOKEN_ABI from 'abis/aave/StableDebtToken.json'
import PULL_REWARDS_ABI from 'abis/meridian/PullRewardsIncentivesController.json'
import REWARDER_ABI from 'abis/aurelius/rewarder.json'
import INCENTIVES_CONTROLLER_ABI from 'abis/lendle/IncentivesController.json'
import MULTI_FEE_DISTRIBUTION_ABI from 'abis/lendle/MultiFeeDistribution.json'

// aave v3 style
import AAVE_V3_POOL_ABI from 'abis/aave/AavePoolV3Upgraded.json'
import AAVE_V3_POOL_DATA_PROVIDER_ABI from 'abis/aave/AaveV3ProtocolDataProvider.json'

// init
import INIT_LENS_ABI from 'abis/init/initLens.json'

// compound V3
import COMET_ABI from 'abis/compound-v3/Comet.json'
import COMET_EXT_ABI from 'abis/compound-v3/CometExt.json'
import COMET_REWARDS_ABI from 'abis/compound-v3/CometRewards.json'

import { isAaveV2Type, isAaveV3Type, Lender } from 'types/lenderData/base'
import { buildAaveV2UserCall } from './aave-v2-type/userCallBuild'
import { buildAaveV3UserCall } from './aave-v3-type/userCallBuild'
import { buildInitUserCall } from './init/userCallBuild'
import { buildCompoundV3UserCall } from './compound-v3/userCallBuild'
import { getAaveV2UserDataConverter } from './aave-v2-type/userCallParse'
import { getAaveV3UserDataConverter } from './aave-v3-type/userCallParse'
import { getInitUserDataConverter } from './init/userCallParse'
import { getCompoundV3UserDataConverter } from './compound-v3/userCallParse'

function buildUserCall(
  chainId: number,
  lender: Lender,
  account: string
) {
  if (isAaveV2Type(lender)) return buildAaveV2UserCall(chainId, lender, account)
  if (isAaveV3Type(lender)) return buildAaveV3UserCall(chainId, lender, account)
  if (lender === Lender.INIT) return buildInitUserCall(chainId, lender, account)
  return buildCompoundV3UserCall(chainId, lender, account)
}

function getUserDataConverter(
  lender: Lender,
  chainId: number,
  account: string,
  prices: { [a: string]: number }
) {
  if (isAaveV2Type(lender)) return getAaveV2UserDataConverter(lender, chainId, account, prices)
  if (isAaveV3Type(lender)) return getAaveV3UserDataConverter(lender, chainId, account, prices)
  if (lender === Lender.INIT) return getInitUserDataConverter(lender, chainId, account, prices)
  return getCompoundV3UserDataConverter(lender, chainId, account, prices)
}

const getAbi = (lender: Lender) => {
  switch (lender) {
    case Lender.AURELIUS:
    case Lender.AAVE_V2:
    case Lender.MERIDIAN:
    case Lender.AURELIUS:
    case Lender.LENDLE:
    case Lender.TAKOTAKO:
      return [
        ...AAVE_POOL_AND_DATA_PROVIDER_ABI,
        ...STABLE_DEBT_TOKEN_ABI,
        ...REWARDER_ABI,
        ...PULL_REWARDS_ABI,
        ...INCENTIVES_CONTROLLER_ABI,
        ...MULTI_FEE_DISTRIBUTION_ABI
      ]
    case Lender.AAVE_V3:
      return [
        ...AAVE_V3_POOL_ABI,
        ...STABLE_DEBT_TOKEN_ABI,
        ...AAVE_V3_POOL_DATA_PROVIDER_ABI
      ]
    case Lender.INIT:
      return [
        ...INIT_LENS_ABI
      ]
    case Lender.COMPOUND_V3:
      return [
        ...COMET_ABI,
        ...COMET_EXT_ABI,
        ...COMET_REWARDS_ABI,
      ]
    default:
      return []
  }
}
export const getLenderUserData = async (
  chainId: number,
  lenders: Lender[],
  account: string,
  prices: { [asset: string]: number }
): Promise<{ [lender: string]: any }> => {
  let calls: {
    call: Call
    abi: any
  }[] = []

  for (const lender of lenders) {
    const abi = getAbi(lender)
    const callData = buildUserCall(chainId, lender, account)
    const mappedCalls = callData.map((call) => ({ call, abi }))
    calls = [...calls, ...mappedCalls]
  }

  const rawResults = await multicallViem(
    chainId,
    calls.flatMap((call) => call.abi),
    calls.map((call) => call.call),
    0,
  )

  const lenderData: { [lender: string]: any } = {}

  let currentSlice = 0
  for (const lender of lenders) {
    const [converter, sliceLength] = getUserDataConverter(lender, chainId, account, prices)

    const data = rawResults.slice(currentSlice, currentSlice + sliceLength)
    const convertedData = converter(data)
    if (convertedData) {
      lenderData[lender] = convertedData
    }

    currentSlice += sliceLength
  }

  return lenderData
}