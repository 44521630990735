import { Flex, FlexProps, Image, Text, TextProps } from '@chakra-ui/react'
import { getRawChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'

interface CryptoPillProps extends FlexProps {
  chainId?: SupportedChainId
  text?: string
  textProps?: TextProps
  isSelected?: boolean
}

export const CryptoPill: React.FC<CryptoPillProps> = ({ chainId, text, textProps, isSelected, ...props }) => {
  const info = getRawChainInfo(chainId)

  return (
    <Flex
      p="0.375rem"
      borderRadius="0.5rem"
      border="1px solid"
      borderColor={
        isSelected
          ? validateColor('Button/Button Primary/Button-primary-default') + ' !important'
          : validateColor('Surface/Surface-secondary')
      }
      backgroundColor={
        isSelected ? validateColor('Surface/Surface-secondary') : validateColor('Surface/Surface-primary')
      }
      gap="0.25rem"
      alignItems="center"
      _hover={{
        backgroundColor: validateColor('Surface/Surface-primary'),
        borderColor: validateColor('Borders/Border-tertiary'),
        cursor: 'pointer',
      }}
      transition="all 0.2s ease-in-out"
      {...props}
    >
      {props.children || (
        <>
          {chainId && <Image src={info?.logoUrl} w="0.875rem" h="0.875rem" />}
          <Text
            style={getTypography('Typography/Body-Labels/Bold/Sub-Label')}
            color={validateColor('Text/Lables/Label-text-default')}
            lineHeight={'1 !important'}
            {...textProps}
          >
            {text}
          </Text>
        </>
      )}
    </Flex>
  )
}
