import { Lender } from 'types/lenderData/base'
import { Call } from 'utils/multicall'
import { getInitFlashAggregatorAddress } from 'hooks/1delta/addresses1Delta'
import { getInitLensAddress } from 'hooks/1delta/addressesInit'

export const buildInitUserCall = (chainId: number, lender: Lender, account: string): Call[] => {
  switch (lender) {
    case Lender.INIT: {
      const lensContract = getInitLensAddress(chainId)
      const brokerAddress = getInitFlashAggregatorAddress(chainId)

      const call: Call = {
        address: lensContract,
        name: 'getUserPositionData',
        params: [account, brokerAddress],
      }

      return [call]
    }
    default:
      return []
  }
}
