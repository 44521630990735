import { validateColor } from 'theme/colors'
import React from 'react'
import { VStack, Text, Divider, HStack, Switch } from '@chakra-ui/react'
import { getTypography } from 'theme/typographies'
import { ThemeButton } from 'components/Button/Action/ThemeButton'
import { useManageUserTheme, useSetPreferPermits } from 'state/user/hooks'
import { isProductionEnv } from 'utils/env'
import { Impersonator } from '../impersonation'
import { ColorMode } from 'theme'
import { FadeInAnimation } from 'components/PageLayout'

interface SettingsProps {
  theme: ColorMode
}

export const Settings: React.FC<SettingsProps> = ({ theme }) => {

  const [preferPermit, switchPreferPermit] = useSetPreferPermits()

  const [, setTheme] = useManageUserTheme()

  const isDark = theme === ColorMode.DARK

  const handleChangeTheme = () => {
    setTheme(isDark ? ColorMode.LIGHT : ColorMode.DARK)
  }

  return (
    <VStack alignItems={'flex-start'} w="100%" gap="1rem" css={FadeInAnimation}>
      <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
        <Text
          style={getTypography('Typography/Small/Normal/Small 2')}
          color={validateColor('Text/Lables/Label-text-disabled')}
        >
          Interface Settings
        </Text>
        <HStack justifyContent={'space-between'} w="100%">
          <Text
            style={getTypography('Typography/Small/Normal/Small 1')}
            color={validateColor('Text/Lables/Label-text-default')}
          >
            Expert Mode
          </Text>
          <Switch isDisabled />
        </HStack>
        <HStack justifyContent={'space-between'} w="100%">
          <Text
            style={getTypography('Typography/Small/Normal/Small 1')}
            color={validateColor('Text/Lables/Label-text-default')}
          >
            Prefer Permits
          </Text>
          <Switch isChecked={preferPermit} onChange={switchPreferPermit} />
        </HStack>
      </VStack>
      <Divider borderColor={validateColor('Borders/Border-secondary')} opacity={1} />
      {/* THEME */}
      <Divider borderColor={validateColor('Borders/Border-secondary')} opacity={1} />
      <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
        <Text
          style={getTypography('Typography/Small/Normal/Small 2')}
          color={validateColor('Text/Lables/Label-text-disabled')}
        >
          Mode
        </Text>
        <HStack justifyContent={'space-between'} w="100%">
          <Text
            style={getTypography('Typography/Small/Normal/Small 1')}
            color={validateColor('Text/Lables/Label-text-default')}
          >
            Color Mode
          </Text>
          <ThemeButton isDark={isDark} onClick={handleChangeTheme} />
        </HStack>
      </VStack>
      {/* IMPERSONATION */}
      {!isProductionEnv() && (
        <>
          <Divider borderColor={validateColor('Borders/Border-secondary')} opacity={1} />
          <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
            <Text
              style={getTypography('Typography/Small/Normal/Small 2')}
              color={validateColor('Text/Lables/Label-text-disabled')}
            >
              Account Impersonation
            </Text>
            <Impersonator />
          </VStack>
        </>
      )}
    </VStack>
  )
}
