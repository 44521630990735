import { Lender } from "types/lenderData/base";
import { InitGeneralPublicResponse } from "./types";
import { INIT_MODES } from 'constants/chains'
import { TOKEN_META } from 'constants/1delta'
import { addressToAsset } from 'hooks/1delta/addressesTokens'
import { convertRateToApr, parseRawAmount } from 'utils/tableUtils/prices'
import { AdditionalYields } from 'state/oracles/reducer'

export const getInitReservesDataConverter = (
  lender: Lender,
  chainId: number,
  prices: { [asset: string]: number },
  additionalYields: AdditionalYields
): [(data: any[]) => InitGeneralPublicResponse | undefined, number] => {
  switch (lender) {
    case Lender.INIT: {
      const expectedNumberOfCalls = INIT_MODES.length
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }
          const modeOne = 0
          let result = Object.assign(
            {}, ...data[modeOne]?.map(
              dat => {
                const asset = addressToAsset(chainId, dat?.underlying)
                const meta = TOKEN_META[asset]
                const totalDeposits = parseRawAmount(dat?.totalSupply.toString(), meta.decimals)
                const totalDebt = parseRawAmount(dat?.totalDebt.toString(), meta.decimals)
                const price = prices[asset]
                const liquidity = totalDeposits - totalDebt
                return {
                  [asset]: {
                    borrowCap: parseRawAmount(dat?.borrowCap.toString(), meta.decimals),
                    canBurn: Boolean(dat?.canBurn),
                    canFlash: Boolean(dat?.canFlash),
                    canMint: Boolean(dat?.canMint),
                    canRepay: Boolean(dat?.canRepay),
                    underlying: dat?.underlying?.toString(),
                    totalDeposits,
                    totalDebt,
                    totalDepositsUSD: price * totalDeposits,
                    totalDebtUSD: price * totalDebt,
                    totalLiquidity: liquidity,
                    totalLiquidityUSD: liquidity * price,
                    depositRate: convertRateToApr(parseRawAmount(dat?.supplyRate.toString(), 18)),
                    variableBorrowRate: convertRateToApr(parseRawAmount(dat?.borrowRate.toString(), 18)),
                    stableBorrowRate: 0,
                    stakingYield: additionalYields.intrinsicYields[asset] ?? 0,
                    rewards: {},
                    config: {},
                    borrowingEnabled: totalDebt !== 0
                  }
                }
              }
            )
          )

          INIT_MODES.forEach((mode, index) => {
            data[index]?.forEach(multicallData => {
              const asset = addressToAsset(chainId, multicallData?.underlying)
              const cf = parseRawAmount(multicallData.collateralFactor, 18)
              result[asset].config[mode] = {
                modeId: mode,
                borrowCollateralFactor: cf,
                collateralFactor: cf,
                borrowFactor: parseRawAmount(multicallData.borrowFactor, 18),
                maxHealthAfterLiq: parseRawAmount(multicallData.maxHealthAfterLiq, 18),
              }
            });
          });

          return { data: result, chainId }
        },
        expectedNumberOfCalls
      ]
    }
    default:
      return [
        () => undefined,
        0
      ]
  }
}