import { createReducer } from '@reduxjs/toolkit'
import { DEFAULT_CHAINID, STATE_CHAINIDS } from 'constants/chains'
import {
  setChainId,
  setAccount,
  setIsSupported,
  setBlockNumber,
  setIsLoading,
  setImpersonatedAccount,
  setUseImpersonatedAccount,
  setSubscribeConfigXChain
} from './actions'
import { fetchAllBalances, fetchgasData, GasData, UserChainEntry } from './fetchAllBalanceData'
import { ChainIdMap } from 'utils/types'
import _ from 'lodash'
import { SubscribedData } from './fethUserTokenData'


export interface SubscribeConfig {
  account?: string
  approvalTargets?: string[]
  tokens?: string[]
}

export type TokenData = { [assetAddress: string]: SubscribedData }

export interface GlobalNetworkState {
  readonly chainId: number
  readonly account: string | undefined
  readonly impersontedAccount: string | undefined
  readonly useImpersonatedAccount: boolean
  readonly connectionIsSupported: boolean
  readonly loading: boolean
  subscribeConfigs: ChainIdMap<SubscribeConfig>
  networkData: {
    [chainId: number]: {
      readonly isArgentWallet: boolean | undefined
      readonly lastTimestamp: string
      readonly blockNumber: number
      readonly nativeBalance: string | undefined
      readonly tokenData: TokenData
      subscribeConfig: SubscribeConfig
      gasData: GasData
    }
  }
}

const initialChainId = Number(DEFAULT_CHAINID)

const initialData = {
  lastTimestamp: String(Math.round(Date.now() / 1000)),
  blockNumber: 0,
  nativeBalance: undefined
}

const initialState: GlobalNetworkState = {
  chainId: initialChainId,
  account: undefined,
  impersontedAccount: undefined,
  useImpersonatedAccount: false,
  connectionIsSupported: true,
  loading: false,
  subscribeConfigs: Object.assign({}, ...STATE_CHAINIDS.map(chainId => { return { [chainId]: { tokens: [], approvalTargets: [] } } })),
  networkData: Object.assign({}, ...STATE_CHAINIDS.map(chainId => {
    return {
      [chainId]: {
        ...initialData,
        isArgentWallet: undefined,
        subscribeConfig: {},
        tokenData: {},
        gasData: {}
      }
    }
  }
  )
  )
}

export default createReducer<GlobalNetworkState>(initialState, (builder) =>
  builder
    .addCase(setChainId, (state, { payload: { chainId } }) => {
      state.chainId = chainId
    })
    .addCase(setImpersonatedAccount, (state, { payload: { account } }) => {
      state.impersontedAccount = account
    })
    .addCase(setUseImpersonatedAccount, (state, { payload: { isUsed } }) => {
      state.useImpersonatedAccount = isUsed
    })
    .addCase(setIsLoading, (state, { payload: { loading } }) => {
      state.loading = loading
    })
    /**
     *  setSubscribeConfigXChain: adds elements to config if not yet included
     *  will not do anything if elements are already there 
     */
    .addCase(setSubscribeConfigXChain, (state, { payload }) => {
      const chainIds = Object.keys(payload)
      chainIds.forEach(chainId => {

        // add entry if it does not exist
        if (!state.subscribeConfigs[chainId]) state.subscribeConfigs[chainId] = {}

        // elements to include
        const tokensAdded = payload[chainId]?.tokens ?? []
        const approvalTargetsAdded = payload[chainId]?.approvalTargets ?? []

        // elements already included
        const tokensAlreadyIncluded = state.subscribeConfigs[chainId].tokens ?? []
        const approvalTargetsAlreadyIncluded = state.subscribeConfigs[chainId].approvalTargets ?? []

        // determine elements added
        const additionalTokens = getAdditionalElements<string>(
          tokensAdded,
          tokensAlreadyIncluded
        )
        const additionalApprovalTargets = getAdditionalElements<string>(
          approvalTargetsAdded,
          approvalTargetsAlreadyIncluded
        )
        // check reference account
        const account = hasAccountChanges(
          state.subscribeConfigs[chainId]?.account,
          payload[chainId]?.account
        )
        // only take action if in fact we have some to add
        if (
          additionalTokens.length > 0 ||
          additionalApprovalTargets.length > 0 ||
          account
        ) {
          state.subscribeConfigs[chainId] = {
            account,
            tokens: [...tokensAlreadyIncluded, ...additionalTokens],
            approvalTargets: [...approvalTargetsAlreadyIncluded, ...additionalApprovalTargets],
          }
        }
      });
    })
    .addCase(setAccount, (state, { payload: { account } }) => {
      state.account = account
    })
    .addCase(setBlockNumber, (state, { payload: { blockNumber, chainId } }) => {
      state.networkData[chainId].blockNumber = blockNumber
    })
    .addCase(setIsSupported, (state, { payload: { isSupported } }) => {
      state.connectionIsSupported = isSupported
    })
    .addCase(fetchgasData.fulfilled, (state, action) => {
      for (const chainId of Object.keys(action.payload)) {
        state.networkData[chainId].gasData = action.payload[chainId]
      }
    })
    .addCase(fetchAllBalances.fulfilled, (state, action) => {
      const chainIds = Object.keys(action.payload)
      for (let i = 0; i < chainIds.length; i++) {
        const chainId = chainIds[i]
        const chainData: UserChainEntry = action.payload[chainId]
        if (!chainData) continue;
        state.networkData[chainId].lastTimestamp = chainData.timestamp
        state.networkData[chainId].blockNumber = chainData.blockNumber
        state.networkData[chainId].isArgentWallet = chainData.isArgentWallet

        if (chainData.account) {
          state.networkData[chainId].nativeBalance = chainData.nativeBalance

          const subscribedKeys = Object.keys(chainData.tokenData)
          for (let i = 0; i < subscribedKeys.length; i++)
            state.networkData[chainId].tokenData[subscribedKeys[i]] = chainData.tokenData[subscribedKeys[i]]
        }
      }
    })
)

function getAdditionalElements<T>(array1: T[], array2: T[]): T[] {
  return array1.filter(function (n) {
    return !array2.includes(n);
  })
};

function hasAccountChanges(a: string | undefined, b: string | undefined) {
  if (!b && !a) return undefined
  if (!a && b) return b
  if (a && !b) return a
  // if (a && b ) 
  if (a === b) return undefined
  else return b
}