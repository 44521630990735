import { SupportedAssets } from 'types/1delta'
import { Call } from 'utils/multicall'
import { getLenderTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { FlatAddressDictionary } from 'hooks/1delta/addresses'
import { Lender } from 'types/lenderData/base'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { getLenderAssets } from 'constants/getAssets'
import { getAaveTypeIncentivesControllerAddress, getAaveTypeMultiFeeDistribution, getAaveTypePoolAddress, getAaveTypePoolDataProviderAddress } from 'hooks/1delta'
import { getAaveStyleATokenMap, getAaveStyleSTokenMap, getAaveStyleVTokenMap } from 'hooks/1delta/tokens'

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

const getBaseAaveV2Calls = (
  chainId: number,
  account: string,
  lender: Lender,
  assetsToQuery: SupportedAssets[],
  aTokens: FlatAddressDictionary,
  vTokens: FlatAddressDictionary,
  sTokens: FlatAddressDictionary
) => {
  const underlyings = getLenderTokenAddresses(chainId, lender)
  const broker = ONE_DELTA_COMPOSER[chainId]
  return assetsToQuery.flatMap((tk) => [
    {
      address: getAaveTypePoolDataProviderAddress(chainId, lender),
      name: 'getUserReserveData',
      params: [underlyings[tk], account],
    },
    {
      address: underlyings[tk],
      name: 'allowance',
      params: [account, getAaveTypePoolAddress(chainId, lender)],
    },
    {
      address: aTokens[tk],
      name: 'allowance',
      params: [account, broker],
    },
    {
      address: vTokens[tk] ?? fallbackDebtToken,
      name: 'borrowAllowance',
      params: [account, broker],
    },
    {
      address: sTokens[tk] ?? fallbackDebtToken,
      name: 'borrowAllowance',
      params: [account, broker],
    },
  ])
}

export const buildAaveV2UserCall = (chainId: number, lender: Lender, account: string): Call[] => {
  switch (lender) {
    case Lender.TAKOTAKO:
    case Lender.AAVE_V2: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const aTokens = getAaveStyleATokenMap(chainId, lender)
      const vTokens = getAaveStyleVTokenMap(chainId, lender)
      const sTokens = getAaveStyleSTokenMap(chainId, lender)
      return getBaseAaveV2Calls(
        chainId,
        account,
        lender,
        assetsToQuery,
        aTokens,
        vTokens,
        sTokens
      )
    }
    case Lender.AURELIUS: {
      const rewarder = getAaveTypeIncentivesControllerAddress(chainId, lender)
      const assetsToQuery = getLenderAssets(chainId, lender)

      const aTokens = getAaveStyleATokenMap(chainId, lender)
      const vTokens = getAaveStyleVTokenMap(chainId, lender)
      const sTokens = getAaveStyleSTokenMap(chainId, lender)

      return [
        ...getBaseAaveV2Calls(
          chainId,
          account,
          lender,
          assetsToQuery,
          aTokens,
          vTokens,
          sTokens
        ),
        {
          address: rewarder,
          name: 'getAllUserRewardsBalance',
          params: [
            [
              ...Object.values(aTokens),
              ...Object.values(vTokens)
            ],
            account
          ],
        }
      ]
    }
    case Lender.MERIDIAN:
    case Lender.LENDLE: {
      const rewarder = getAaveTypeIncentivesControllerAddress(chainId, lender)
      const assetsToQuery = getLenderAssets(chainId, lender)

      const aTokens = getAaveStyleATokenMap(chainId, lender)
      const vTokens = getAaveStyleVTokenMap(chainId, lender)
      const sTokens = getAaveStyleSTokenMap(chainId, lender)

      let lAndVTokensAddresses: string[] = []

      // rewards are accrued for both l and v tokens
      assetsToQuery.forEach((name) => {
        lAndVTokensAddresses.push(aTokens[name], vTokens[name])
      })

      let rewardsBalance: Call
      let unclaimedRewards: Call
      if (lender === Lender.MERIDIAN) {
        rewardsBalance = {
          address: rewarder,
          name: 'getRewardsBalance',
          params: [lAndVTokensAddresses, account],
        }
        // total vested LEND rewards
        unclaimedRewards = {
          address: rewarder,
          name: 'getUserUnclaimedRewards',
          params: [account],
        }
      } else {
        rewardsBalance = {
          address: rewarder,
          name: 'claimableReward',
          params: [account, lAndVTokensAddresses],
        }
        // total vested TAIKo rewards
        unclaimedRewards = {
          address: getAaveTypeMultiFeeDistribution(chainId, lender),
          name: 'earnedBalances',
          params: [account],
        }
      }

      return [
        ...getBaseAaveV2Calls(
          chainId,
          account,
          lender,
          assetsToQuery,
          aTokens,
          vTokens,
          sTokens
        ),
        rewardsBalance,
        unclaimedRewards
      ]
    }
    default:
      return []
  }
}
