import { COMET_RISK_DECIMALS, TOKEN_META } from "constants/1delta";
import { getCompoundV3TokenAddresses } from "hooks/lenders/lenderAddressGetter";
import { AdditionalYields } from "state/oracles/reducer";
import { Lender, LENDER_MODE_NO_MODE } from "types/lenderData/base";
import { parseRawAmount } from "utils/tableUtils/prices";
import {
  CometInterestRateIndexes,
  CompoundV3GeneralPublicResponse
} from "./types";
import { SupportedAssets, toOracleKey } from "types/1delta";
import { compoundV3AssetKey } from "state/lenders/compound-v3/reducer";
import { calculateRateForCompound } from "utils/tableUtils/format";

const SECONDS_PER_DAY = 3600 * 24
const DAYS_IN_YEAR = 365

export const getCompoundV3ReservesDataConverter = (
  lender: Lender,
  chainId: number,
  prices: { [asset: string]: number },
  additionalYields: AdditionalYields
): [(data: any[]) => CompoundV3GeneralPublicResponse | undefined, number] => {
  switch (lender) {
    /** AAVE V3 style with rewards from state */
    case Lender.COMPOUND_V3: {
      const baseAsset = SupportedAssets.USDCE
      const rawAddressDict = getCompoundV3TokenAddresses(chainId)
      const assetsToQuery = Object.keys(rawAddressDict)
      const tokensNoBase = assetsToQuery.filter(a => a !== baseAsset)

      const baseLength = tokensNoBase.length * 2
      const expectedNumberOfCalls = baseLength + 6
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }
          let result = {}
          for (let i = 0; i < tokensNoBase.length; i++) {
            const asset = tokensNoBase[i]
            const resultsAssetInfo = data[i * 2]
            const resultsTotals = data[i * 2 + 1]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const totals = parseRawAmount(resultsTotals[0], decimals)
            result[compoundV3AssetKey(baseAsset, asset)] = {
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: parseRawAmount(resultsAssetInfo.borrowCollateralFactor.toString(), COMET_RISK_DECIMALS),
                  collateralFactor: parseRawAmount(resultsAssetInfo.liquidateCollateralFactor.toString(), COMET_RISK_DECIMALS),
                  borrowFactor: 1
                }
              },
              supplyCap: parseRawAmount(resultsAssetInfo.supplyCap.toString(), decimals),

              // interest rates
              variableBorrowRate: 0,
              depositRate: 0,
              stakingYield: additionalYields.intrinsicYields[asset] ?? 0,

              utilization: 0,
              // debt and liquidity are zero
              totalDebt: 0,
              totalDebtUSD: 0,
              totalLiquidity: 0,
              totalLiquidityUSD: 0,
              // deposits are different outputs for base asset and others
              totalDeposits: totals,
              totalDepositsUSD: totals * (prices[toOracleKey(asset)] ?? 1),
              // rewards
              rewards: {},
              borrowingEnabled: false
            }

          }

          // parse data for rewards calculation
          const resultsSupplyRewards = Number(data[baseLength]?.toString())
          const resultsBorrowRewards = Number(data[baseLength + 1]?.toString())
          const resultsIndexScale = data[baseLength + 2]?.toString()
          const resultsSupply = parseRawAmount(data[baseLength + 3]?.toString(), 6)
          const resultsBorrow = parseRawAmount(data[baseLength + 4]?.toString(), 6)

          const compToSuppliersPerDay = resultsSupplyRewards / Number(resultsIndexScale) * SECONDS_PER_DAY;
          const compToBorrowersPerDay = resultsBorrowRewards / Number(resultsIndexScale) * SECONDS_PER_DAY;

          const resultsInterest = data[baseLength + 5]

          const price = prices[toOracleKey(baseAsset)] ?? 1
          const liquidity = resultsSupply - resultsBorrow
          const compPrice = prices[SupportedAssets.COMP]

          const baseData = {
            [compoundV3AssetKey(baseAsset, baseAsset)]: {
              // collateral factots
              borrowCollateralFactor: 0,
              collateralFactor: 0,
              supplyCap: 0,

              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: 0,
                  collateralFactor: 0,
                  borrowFactor: 1
                }
              },
              // interest rates
              variableBorrowRate: calculateRateForCompound(resultsInterest[CometInterestRateIndexes.borrowRate].toString(), chainId, Lender.COMPOUND_V3),
              depositRate: calculateRateForCompound(resultsInterest[CometInterestRateIndexes.supplyRate].toString(), chainId, Lender.COMPOUND_V3),

              utilization: parseRawAmount(resultsInterest[CometInterestRateIndexes.utilization].toString(), COMET_RISK_DECIMALS),

              totalLiquidity: liquidity,
              totalLiquidityUSD: liquidity * price,

              totalDebt: resultsBorrow,
              totalDeposits: resultsSupply,

              totalDebtUSD: resultsBorrow * price,
              totalDepositsUSD: resultsSupply * price,
              // rewards
              rewards: {
                [SupportedAssets.COMP]: {
                  depositRate: (compPrice * compToSuppliersPerDay / resultsSupply) * DAYS_IN_YEAR * 100 / price,
                  variableBorrowRate: (compPrice * compToBorrowersPerDay / resultsBorrow) * DAYS_IN_YEAR * 100 / price,
                  stableBorrowRate: 0,
                }
              },
              borrowingEnabled: true,
              stakingYield: additionalYields.intrinsicYields[baseAsset] ?? 0,
            }
          }
          return { data: { ...result, ...baseData }, chainId }
        },
        expectedNumberOfCalls
      ]
    }
    default:
      return [
        () => undefined,
        0
      ]
  }
}
