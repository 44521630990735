import { encodeFunctionData } from "viem"
import POS_MANAGER_ABI from 'abis/init/posManager.json'
import INIT_CORE_ABI from 'abis/init/core.json'

export enum InitPosmanagerFunctions {
  NextNonces = "nextNonces",
  Approve = "approve",
}

export namespace PosManager {
  export const encodeNextNonces = (owner: string) => {
    return encodeFunctionData({
      abi: POS_MANAGER_ABI,
      functionName: InitPosmanagerFunctions.NextNonces,
      args: [owner]
    })
  }

  export const encodeApprove = (spender: string, posId: bigint | string) => {
    return encodeFunctionData({
      abi: POS_MANAGER_ABI,
      functionName: InitPosmanagerFunctions.Approve,
      args: [spender, posId]
    })
  }
}

export enum InitCoreFunctions {
  SetPosMode = "setPosMode",
}

export namespace InitCore {
  export const encodeSetPosMode = (posId: bigint | string, mode: number) => {
    return encodeFunctionData({
      abi: INIT_CORE_ABI,
      functionName: InitCoreFunctions.SetPosMode,
      args: [posId, mode]
    })
  }
}