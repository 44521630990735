import { UserApr } from "hooks/asset/useAssetData";

export function divideAccrualsToAprs(aprs: { [assets: string]: UserApr }, nav: number, deposits: number, debt: number) {
  if (nav === 0) return {}
  let d: any = {}
  Object.entries(aprs).map(([k, data]) => {
    d[k] = {
      apr: (data.borrowApr + data.depositApr) / nav,
      borrowApr: data.borrowApr / debt,
      depositApr: data.depositApr / deposits,
    }
  })
  return d
}