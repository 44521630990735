import { Lender } from "types/lenderData/base";
import { addressesAaveV2Core } from "./addressesAaveV2";
import { addressesAaveCore } from "./addressesAave";
import { addressesLendleCore } from "./addressesLendle";
import { addressesAureliusCore } from "./addressesAurelius";
import { addressesMeridianCore } from "./addressesMeridian";
import { addressesTakoTakoCore } from "./addressesTakoTako";

export function getAaveTypePoolAddress(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return addressesAaveV2Core.PoolProxy[chainId]
    case Lender.AAVE_V3: return addressesAaveCore.PoolProxy[chainId]
    case Lender.LENDLE: return addressesLendleCore.PoolProxy[chainId]
    case Lender.AURELIUS: return addressesAureliusCore.PoolProxy[chainId]
    case Lender.MERIDIAN: return addressesMeridianCore.PoolProxy[chainId]
    case Lender.TAKOTAKO: return addressesTakoTakoCore.PoolProxy[chainId]
    default: return '0x0000000000000000000000000000000000000001'
  }
}

export function getAaveTypeOracleAddress(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return addressesAaveV2Core.AaveOracle[chainId]
    case Lender.AAVE_V3: return addressesAaveCore.AaveOracle[chainId]
    case Lender.LENDLE: return addressesLendleCore.AaveOracle[chainId]
    case Lender.AURELIUS: return addressesAureliusCore.AaveOracle[chainId]
    case Lender.MERIDIAN: return addressesMeridianCore.AaveOracle[chainId]
    case Lender.TAKOTAKO: return addressesTakoTakoCore.AaveOracle[chainId]
    default: return '0x0000000000000000000000000000000000000001'
  }
}

export function getAaveTypePoolDataProviderAddress(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return addressesAaveV2Core.PoolDataProvider[chainId]
    case Lender.AAVE_V3: return addressesAaveCore.PoolDataProvider[chainId]
    case Lender.LENDLE: return addressesLendleCore.PoolDataProvider[chainId]
    case Lender.AURELIUS: return addressesAureliusCore.PoolDataProvider[chainId]
    case Lender.MERIDIAN: return addressesMeridianCore.PoolDataProvider[chainId]
    case Lender.TAKOTAKO: return addressesTakoTakoCore.PoolDataProvider[chainId]
    default: return '0x0000000000000000000000000000000000000001'
  }
}

export function getAaveTypeIncentivesControllerAddress(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.AAVE_V2: return addressesAaveV2Core.IncentivesController[chainId]
    case Lender.AAVE_V3: return addressesAaveCore.IncentivesController[chainId]
    case Lender.LENDLE: return addressesLendleCore.IncentivesController[chainId]
    case Lender.AURELIUS: return addressesAureliusCore.IncentivesController[chainId]
    case Lender.MERIDIAN: return addressesMeridianCore.IncentivesController[chainId]
    case Lender.TAKOTAKO: return addressesTakoTakoCore.IncentivesController[chainId]
    default: return '0x0000000000000000000000000000000000000001'
  }
}

export function getAaveTypeMultiFeeDistribution(chainId: number, lender: Lender) {
  switch (lender) {
    case Lender.LENDLE: return addressesLendleCore.MultiFeeDistribution[chainId]
    default: return '0x0000000000000000000000000000000000000001'
  }
}