import {
  ButtonProps,
  HStack,
  ImageProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  TableRowProps,
  Tr,
} from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { IconButtonWithTooltip } from 'components/Button/Action/IconButtonWithTooltip'
import {
  depositIcon,
  withdrawIcon,
  borrowIcon,
  repayIcon,
  swapIcon,
  marginIcon,
  externalLinkIcon,
  arrowUpIcon,
  arrowDownIcon,
} from 'constants/1delta'
import { ColorMode } from 'theme'
import { trackRowActionClicked } from 'utils/analytics'
import { css, SerializedStyles } from '@emotion/react'
import { useUserTheme } from 'state/user/hooks'

export enum Action {
  DEPOSIT = 'Deposit',
  WITHDRAW = 'Withdraw',
  BORROW = 'Borrow',
  REPAY = 'Repay',
  SWAP_IN = 'Sell',
  SWAP_OUT = 'Buy',
  SWAP = 'Swap',
  MARGIN = 'Margin',
  EXPLORER = 'Explorer',
}

interface HoverableRowProps extends TableRowProps {
  theme: ColorMode
  onDeposit?: () => void
  onWithdraw?: () => void
  onBorrow?: () => void
  onRepay?: () => void
  onSwapOut?: () => void
  onSwapIn?: () => void
  onMargin?: () => void
  onSwap?: () => void
  onExplorer?: () => void
  small?: boolean
  iconButtonImageProps?: ImageProps
  iconButtonProps?: ButtonProps
  appendCss?: SerializedStyles
}

const getGradientColors = (theme: ColorMode) => {
  switch (theme) {
    case ColorMode.LIGHT: return [
      '#e6e6e6',
      'rgba(255, 255, 255, 0.6)'
    ]
    default: return [
      '#1c2e3c',
      'rgba(15, 26, 35, 0.6)'
    ]
  }
}

export const HoverableRow: React.FC<HoverableRowProps> = ({
  theme,
  children,
  onDeposit,
  onWithdraw,
  onBorrow,
  onRepay,
  onSwapOut,
  onSwapIn,
  onMargin,
  onSwap,
  onExplorer,
  small,
  iconButtonImageProps,
  iconButtonProps,
  appendCss,
  ...props
}) => {
  const actions = [
    {
      name: Action.DEPOSIT,
      fn: onDeposit,
      icon: depositIcon[theme],
    },
    {
      name: Action.WITHDRAW,
      fn: onWithdraw,
      icon: withdrawIcon[theme],
    },
    {
      name: Action.BORROW,
      fn: onBorrow,
      icon: borrowIcon[theme],
    },
    {
      name: Action.REPAY,
      fn: onRepay,
      icon: repayIcon[theme],
    },
    {
      name: Action.SWAP_OUT,
      fn: onSwapOut,
      icon: arrowUpIcon[theme],
    },
    {
      name: Action.SWAP_IN,
      fn: onSwapIn,
      icon: arrowDownIcon[theme],
    },
    {
      name: Action.MARGIN,
      fn: onMargin,
      icon: marginIcon[theme],
    },
    {
      name: Action.SWAP,
      fn: onSwap,
      icon: swapIcon[theme],
    },
    {
      name: Action.EXPLORER,
      fn: onExplorer,
      icon: externalLinkIcon[theme],
    },
  ]

  const handleAction = (name: Action, fn: (() => void) | undefined, onClose: () => void) => {
    if (fn) {
      trackRowActionClicked(name)
      fn()
      onClose()
    }
  }

  const [gradientStart, gradientEnd] = getGradientColors(useUserTheme())
  const gradientStyles = css`
    @property --gradientStart {
      syntax: '<color>';
      initial-value: transparent;
      inherits: false;
    }
    @property --gradientEnd {
      syntax: '<color>';
      initial-value: transparent;
      inherits: false;
    }

    background: linear-gradient(90deg, var(--gradientStart) 0%, var(--gradientEnd) 100%);
    transition: --gradientStart 0.3s, --gradientEnd 0.3s;

    &:hover,
    &[data-hover],
    &[data-popover-open='true'] {
      --gradientStart: ${gradientStart};
      --gradientEnd: ${gradientEnd};
    }
  `

  return (
    <Popover trigger="hover" placement="top-end" offset={[0, -2]} closeDelay={100} openDelay={50}>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Tr
              css={
                appendCss
                  ? css`
                      ${gradientStyles}
                      ${appendCss}
                    `
                  : gradientStyles
              }
              data-popover-open={isOpen}
              _hover={{
                td: {
                  bg: 'transparent',
                },
                cursor: props.onClick ? 'pointer' : 'default',
                ...props._hover,
              }}
              {...props}
            >
              {children}
            </Tr>
          </PopoverTrigger>
          {actions.some((action) => action.fn) && (
            <PopoverContent
              borderRadius="1.5rem"
              border="1px solid"
              borderColor={validateColor('Borders/Border-tertiary')}
              bg={validateColor('Surface/Surface-primary 2')}
              w="auto"
              boxShadow="dark"
            >
              <PopoverBody p="0">
                <HStack p={small ? '0.325rem 0.65625rem' : '0.5rem 1rem'} gap={'0.5rem'}>
                  {actions.map(
                    (action) =>
                      action.fn && (
                        <IconButtonWithTooltip
                          key={action.name}
                          src={action.icon}
                          tooltipLabel={action.name}
                          onClick={() => handleAction(action.name, action.fn, onClose)}
                          imageProps={iconButtonImageProps}
                          {...iconButtonProps}
                          _hover={{
                            filter:
                              action.name === Action.SWAP_IN
                                ? 'sepia(1) hue-rotate(312deg)'
                                : action.name === Action.SWAP_OUT
                                  ? 'sepia(1) hue-rotate(84deg)'
                                  : '',
                          }}
                          transition={'filter 0.2s ease-in-out'}
                        />
                      )
                  )}
                </HStack>
              </PopoverBody>
            </PopoverContent>
          )}
        </>
      )}
    </Popover>
  )
}
