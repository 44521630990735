import { createAction } from '@reduxjs/toolkit'
import { Field } from 'types/1delta';

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('spot/selectCurrency')
export const switchCurrencies = createAction<void>('spot/switchCurrencies')
export const switchChainId = createAction<void>('spot/switchChainId')

export const typeInput = createAction<{ field: Field; typedValue: string }>('spot/typeInput')
export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
  refetchingTrades: boolean
}>('spot/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('spot/setRecipient')