import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'


export function getCometAddress(chainId: number, market = SupportedAssets.USDCE) {
  return addressesCompoundV3Core.comet[chainId]?.[market]
}

export function getCometRewardsAddress(chainId: number, market = SupportedAssets.USDCE) {
  return addressesCompoundV3Core.cometRewards[chainId]?.[market]
}

export const addressesCompoundV3Core = {
  // each comet has a separate address for the respective base currency
  comet: {
    [SupportedChainId.POLYGON_MUMBAI]: {
      [SupportedAssets.USDCE]: '0xF09F0369aB0a875254fB565E52226c88f10Bc839'
    },
    [SupportedChainId.POLYGON]: {
      [SupportedAssets.USDCE]: '0xF25212E676D1F7F89Cd72fFEe66158f541246445'
    }
  },
  cometExt: {
    [SupportedChainId.POLYGON_MUMBAI]: {
      [SupportedAssets.USDCE]: '0x1c3080d7fd5c97A58E0F2EA19e9Eec4745dC4BDe'
    },
    [SupportedChainId.POLYGON]: {
      [SupportedAssets.USDCE]: '0xbdE8F31D2DdDA895264e27DD990faB3DC87b372d'
    }
  },
  lens: {
    [SupportedChainId.POLYGON_MUMBAI]: '0xC49bfddbbBFB3274e9b9D2059a6344472FC91fBB',
    [SupportedChainId.POLYGON]: '0x47B087eBeD0d5a2Eb93034D8239a5B89d0ddD990'
  },
  irGetter: {
    [SupportedChainId.POLYGON]: '0xF207E9ea58B58da3F33c3c449D7f9918b840d36B'
  },
  cometRewards: {
    [SupportedChainId.POLYGON]: {
      [SupportedAssets.USDCE]: "0x45939657d1CA34A8FA39A924B71D28Fe8431e581"
    }
  }
}

export const addressesCompoundV3TestnetTokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON_MUMBAI]: '0x4DAFE12E1293D889221B1980672FE260Ac9dDd28',
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON_MUMBAI]: '0xDB3cB4f2688daAB3BFf59C24cC42D4B6285828e9',
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON_MUMBAI]: '0x4B5A0F4E00bC0d6F16A593Cae27338972614E713',
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON_MUMBAI]: '0xE1e67212B1A4BF629Bdf828e08A3745307537ccE',
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON_MUMBAI]: '0xfec23a9E1DBA805ADCF55E0338Bf5E03488FC7Fb',
  },
}