import { ethers } from "ethers"
import type { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import { useWeb3ReactWrapped } from "hooks/web3"
import { useCallback } from "react"
import { calculateGasMargin } from "utils/calculateGasMargin"
import { useGasData } from "state/globalNetwork/hooks"
import { TypedEvmData } from "types/1delta"
import { getAddress } from "viem"

export type EVMTransaction = ethers.providers.TransactionRequest
export type ParametrizedEVMTransaction = (params: any) => ethers.providers.TransactionRequest
export type EVMTransactionResponse = ethers.providers.TransactionResponse
export type EVMTransactionReceipt = ethers.providers.TransactionReceipt

export type GenericIntent = (signer: JsonRpcSigner, params?: any) => Promise<string>

export type EVMSignInputs = { typedData?: TypedEvmData, message?: string }

/** try to safely attempt to parse an address */
export function tryGetEVMAddress(addr: string | undefined) {
  try {
    if (!addr) return false
    return getAddress(addr)
  } catch (e) {
    return false
  }
}

/** create a general EVM txn */
export const createEVMTxn = (
  to: string,
  from: string,
  calldata: string,
  value: bigint | number | string | undefined = undefined
): EVMTransaction => {
  return {
    to,
    from,
    data: calldata,
    value
  }
}

// account is not optional
function getSigner(
  provider: JsonRpcProvider | undefined,
  account: string | undefined
): JsonRpcSigner | undefined {
  return provider?.getSigner(account).connectUnchecked()
}


async function sendEvmTransaction(
  provider: JsonRpcProvider | undefined,
  account: string | undefined,
  transaction: EVMTransaction,
  opts: any = {}
) {
  const signer = getSigner(provider, account)
  if (!signer) throw new Error("Cannot send transaction without signer")
  return await signer.sendTransaction({
    ...transaction,
    ...opts
  })
}

/**
 * Function to either sign a raw mesage (e.g. ERC20Permit) or typed data (e.g. Permit2)
 * Will sign the provided one.
 * Both cannot be used simultaneously
 */
async function signEVMDataOrMessage(
  provider: JsonRpcProvider | undefined,
  account: string | undefined,
  inputs: EVMSignInputs
) {
  const signer = getSigner(provider, account)

  // validation
  if (!signer || !account) throw new Error("Cannot sign without signer and account")
  if (inputs.typedData && inputs.message) throw new Error("Cannot sign both message and data")

  if (!inputs.message) {
    if (!inputs.typedData) throw new Error("No typed data to sign")
    return await signer._signTypedData(
      inputs.typedData.domain,
      inputs.typedData.types,
      inputs.typedData.values
    )
  }

  return await provider?.send(
    'eth_signTypedData_v4',
    [account, inputs.message]
  )
}


async function estimateEvmTransaction(
  provider: JsonRpcProvider | undefined,
  transaction: EVMTransaction
) {
  if (!provider) throw new Error("Cannot estimate gas without provider")
  return (await provider?.estimateGas(transaction)).toBigInt()
}

async function waitForEVMTransaction(
  provider: JsonRpcProvider | undefined,
  txHash: string
) {
  if (!provider) throw new Error("Cannot wait for transaction without provider")
  return await provider?.waitForTransaction(txHash)
}

export function useSendEVMTransaction() {
  const { account, chainId, provider, } = useWeb3ReactWrapped()

  const gasData = useGasData(chainId ?? 0)

  const sendTxn = useCallback(async (tx: EVMTransaction) => {
    let opts: any = {}
    const gasEstimate = await estimateEvmTransaction(provider, tx)
    opts = gasEstimate
      ? {
        gasLimit: calculateGasMargin(gasEstimate, chainId),
        ...gasData,
      }
      : {}

    return await sendEvmTransaction(provider, account, tx, opts)
  },
    [
      gasData,
      account,
      chainId
    ]
  )

  const signTxn = useCallback(async (inputs: EVMSignInputs) => {
    return await signEVMDataOrMessage(
      provider,
      account,
      inputs
    )
  },
    [
      account,
      chainId
    ]
  )

  const waitForTxn = useCallback(async (txHash: string) => {
    return waitForEVMTransaction(provider, txHash)
  }, [chainId])

  return {
    sendTxn,
    signTxn,
    waitForTxn,
  }
}