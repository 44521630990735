import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { load, save } from 'redux-localstorage-simple'
import { isTestEnv } from 'utils/env'

import delta from './1delta/reducer'
// lenders
import aaveV3 from './lenders/aave-v3/reducer'
import aaveV2 from './lenders/aave-v2/reducer'
import compoundV3 from './lenders/compound-v3/reducer'
import compoundV2 from './lenders/compound-v2/reducer'
import vix from './lenders/vix/reducer'
import lendle from './lenders/lendle/reducer'
import meridian from './lenders/meridian/reducer'
import venus from './lenders/venus/reducer'
import init from './lenders/init/reducer'
import aurelius from './lenders/aurelius/reducer'
import takotako from './lenders/takotako/reducer'

// spot
import spot from './spot/reducer'

import globalNetwork from './globalNetwork/reducer'
import oracles from './oracles/reducer'
import application from './application/reducer'
import connection from './connection/reducer'
import { updateVersion } from './global/actions'
import lists from './lists/reducer'
import transactions from './transactions/reducer'
import user from './user/reducer'
import wallets from './wallets/reducer'
import margin from './margin/reducer'
import moneyMarket from './moneyMarket/reducer'
import loadingState from './loading/reducer'
import easymode from './easymode/reducer'

/** This is patching the BigInt type to properly serialize to Json in redux */
BigInt.prototype["toJSON"] = function () { return this.toString() }

const PERSISTED_KEYS: string[] = ['user', 'transactions']

const store = configureStore({
  reducer: {
    application,
    user,
    connection,
    transactions,
    wallets,
    globalNetwork,

    // lenders

    // aaves
    aaveV3,
    aaveV2,
    lendle,
    meridian,
    aurelius,
    takotako,

    init,

    // the comet
    compoundV3,

    // compound v2s
    compoundV2,
    vix,
    venus,

    // spot
    spot,

    easymode,

    delta,
    oracles,
    margin,
    moneyMarket,
    loadingState,
    lists,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false })
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: isTestEnv() }),
  devTools: {}
})

store.dispatch(updateVersion())

setupListeners(store.dispatch)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
