import { HStack, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { TransactionInformation } from './hooks/useTransactionHistory'
import { getTypography } from 'theme/typographies'
import { validateColor } from 'theme/colors'
import { TransactionCard, TransactionStatus } from './TransactionCard'
import { TransactionDetailsV2 } from 'state/transactions/types'
import { useScrollbarCss } from 'components/WalletMenu/WalletMenuAssets'

interface TransactionProps {
  pending: TransactionDetailsV2[]
  confirmedTransactions: TransactionInformation[]
  clearTransactions?: () => void
}

export const Transactions = ({ pending, confirmedTransactions, clearTransactions }: TransactionProps) => {
  const scrollbarCss = useScrollbarCss()
  const maxHeight = useBreakpointValue({
    base: '65vh',
    sm: '65vh',
    md: '65vh',
    lg: '72vh',
  })

  return (
    <VStack h="100%">
      {
        clearTransactions && (
          pending.length > 0 ||
          confirmedTransactions.length > 0
        ) && <HStack justify="flex-end" w="100%" h="0.5rem">
          <Text
            onClick={clearTransactions}
            style={getTypography('Typography/Small/Normal/Small 3')}
            color={validateColor('Text/Lables/Label-text-default')}
            _hover={{ opacity: 0.5, cursor: 'pointer' }}
          >
            Clear History
          </Text>
        </HStack>
      }
      <VStack
        gap="1.5rem"
        w="100%"
        h="100%"
        maxH={maxHeight}
        alignItems={'flex-start'}
        overflowX="hidden"
        overflowY="scroll"
        css={scrollbarCss}
      >

        {pending.length > 0 && (
          <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
            {pending.map((pendingTx, i) => {
              return <TransactionCard key={i} details={pendingTx} status={TransactionStatus.PENDING} />
            })}
          </VStack>
        )}
        {confirmedTransactions.length > 0 ? (
          <VStack gap="1rem" w="100%" alignItems={'flex-start'}>
            {pending.length > 0 && (
              <Text
                style={getTypography('Typography/Small/Normal/Small 1')}
                color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
              >
                History
              </Text>
            )}
            <VStack w="100%" gap="0.5rem" alignItems={'flex-start'}>
              {confirmedTransactions.map((confirmedTx, i) => {
                return (
                  <VStack key={i} w="100%" gap="0.5rem">
                    {confirmedTx.transactions.map((cf, j) => {
                      const success = cf.receipt?.status === 1 || typeof cf.receipt?.status === 'undefined'
                      return (
                        <TransactionCard
                          key={j}
                          details={cf}
                          status={success ? TransactionStatus.COMPLETE : TransactionStatus.ERROR}
                        />
                      )
                    })}
                  </VStack>
                )
              })}
            </VStack>
          </VStack>
        ) : (
          pending.length > 0 ? null :
            <VStack gap="0.5rem" w="100%" alignItems={'flex-start'} paddingBottom="0.5rem">
              <Text
                style={getTypography('Typography/Small/Normal/Small 1')}
                color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
              >
                Your Transactions will appear here.
              </Text>
            </VStack>
        )}
      </VStack>
    </VStack>
  )
}
