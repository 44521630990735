import { SupportedChainId } from 'constants/chains'
import { getDeltaComposerAddress, getInitFlashAggregatorAddress } from './addresses1Delta'
import { compoundAddresses } from './addressesCompound'
import { oVixAddresses } from './addresses0Vix'
import { ETHEREUM_CHAINS, POLYGON_CHAINS } from 'constants/1delta'
import { getCometAddress } from './addressesCompoundV3'
import { MarginTradeType, SupportedAssets } from 'types/1delta'
import { useMemo } from 'react'
import { venusAddresses } from './addressesVenus'
import { isAaveV3Type, Lender } from 'types/lenderData/base'
import { getAaveTypePoolAddress } from '.'

const defaultAddress = '0xBA4e9BbEa023AcaE6b9De0322A5b274414e4705C'

/**
 * Get the address to be approved for borrowing or withdrawing on margin
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type
 * @param repaySame repaying same asset requires no approval for Aave V3
 * @returns the contract
 */
export function useMarginApprovalAddress(
  chainId: number,
  protocol: Lender,
  tradeType: MarginTradeType,
  repaySame = false
): string | undefined {
  return useMemo(() => {
    switch (tradeType) {
      case MarginTradeType.Open:
      case MarginTradeType.CollateralSwap:
      case MarginTradeType.DebtSwap: {
        switch (protocol) {
          case Lender.INIT: {
            return getInitFlashAggregatorAddress(chainId)
          }
          default: {
            return getDeltaComposerAddress(chainId)
          }
        }
      }
      case MarginTradeType.Close: {
        if (isAaveV3Type(protocol)) {
          if (repaySame)
            return getAaveTypePoolAddress(chainId, protocol)
          else
            return getDeltaComposerAddress(chainId)
        } else {
          return getDeltaComposerAddress(chainId)
        }
      }
    }
  },
    [
      chainId,
      protocol,
      tradeType,
      repaySame,
    ])
}

/**
 * The receiver of the swap funds on margin, should not be used for repay same on aave V3
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type
 * @returns the contract
 */
export function getMarginToAddress(
  chainId: number,
  protocol: Lender,
  tradeType: MarginTradeType,
): string | undefined {
  return useMemo(() => {
    switch (protocol) {
      case Lender.INIT: {
        return getInitFlashAggregatorAddress(chainId)
      }
      default: {
        return getDeltaComposerAddress(chainId)
      }
    }
  },
    [
      chainId,
      protocol,
      tradeType,
    ])
}

/**
 * 
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type (MarginTradeType)
 * @param baseAsset base asset for compound V3
 * @returns [moneyMarketAddress, marginAddress]
 */
export function useGetMoneyMarketTradeAddresses(
  chainId: number,
  protocol: Lender,
  baseAsset = SupportedAssets.USDCE,
): [string | undefined, string | undefined] {
  return useMemo(() => {
    switch (protocol) {
      case Lender.AAVE_V3:
      case Lender.LENDLE:
      case Lender.YLDR:
      case Lender.AAVE_V2:
      case Lender.AURELIUS:
      case Lender.TAKOTAKO:
      case Lender.MERIDIAN: {
        return [
          getAaveTypePoolAddress(chainId, protocol),
          getDeltaComposerAddress(chainId)
        ]
      }
      case Lender.INIT: {
        const initAggregator = getInitFlashAggregatorAddress(chainId)
        return [initAggregator, initAggregator]
      }
      case Lender.COMPOUND_V2:
      case Lender.VENUS:
      case Lender.OVIX: {
        return [
          undefined,
          undefined,
        ]
      }
      case Lender.COMPOUND_V3: {
        return [
          getCometAddress(chainId, baseAsset),
          getDeltaComposerAddress(chainId)
        ]
      }
    }
  },
    [
      chainId,
      protocol,
      baseAsset,
    ])
}

// ********** COMET ***********

export function getCompoundLensAddress(chainId: number): string {
  return chainId === SupportedChainId.BSC ? venusAddresses.CompoundLens[chainId] :
    (ETHEREUM_CHAINS.includes(chainId)
      ? compoundAddresses.CompoundLens[chainId]
      : oVixAddresses.CompoundLens[chainId]) ?? defaultAddress
}

// account is not optional
export function getCompoundComptrollerAddress(chainId: number): string {
  return chainId === SupportedChainId.BSC
    ? venusAddresses.Unitroller[chainId] :
    chainId === SupportedChainId.GOERLI
      ? compoundAddresses.Unitroller[chainId]
      : POLYGON_CHAINS.includes(chainId)
        ? oVixAddresses.Unitroller[chainId]
        : defaultAddress
}
