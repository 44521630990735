import React from 'react'
import { HStack, VStack, Text, Image, Link, keyframes } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { useCurrency } from 'hooks/Tokens'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { ExplorerDataType, formatAddress, formatHash, getExplorerLink } from 'utils/getExplorerLink'
import {
  TransactionType,
  TransactionDetailsV2,
  ApproveTransactionInfo,
  ClaimRewardsTransactionInfo,
  MarginTradeTransactionInfo,
  QuickActionTransactionInfo,
  SwapTransactionInfo,
  VestRewardsTransactionInfo,
  SwitchEModeTransactionInfo,
} from 'state/transactions/types'
import CurrencyLogo from 'components/CurrencyLogo'
import { LenderLogo } from 'components/LenderLogo'
import { Card } from './Card'
import { default as checkGreen } from 'assets/icons/check-green.svg'
import { default as errorRed } from 'assets/icons/error-red.svg'
import { default as warningYellow } from 'assets/icons/warning-yellow.svg'
import { default as pendingArrows } from 'assets/icons/loading-spinner.svg'
import { Lender } from 'types/lenderData/base'

export const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

type Swaps = QuickActionTransactionInfo | SwapTransactionInfo | MarginTradeTransactionInfo
type Rewards = VestRewardsTransactionInfo | ClaimRewardsTransactionInfo

export enum TransactionStatus {
  COMPLETE = 'complete',
  PENDING = 'pending',
  ERROR = 'error',
  WARNING = 'warning',
}

export type TransactionStyleInfo = {
  style: {
    icon: string
    text: string
    labelColor: string
    dateColor: string
  }
  animation?: any
}

const TransactionStyles: Record<TransactionStatus, TransactionStyleInfo> = {
  [TransactionStatus.COMPLETE]: {
    style: {
      icon: checkGreen,
      text: 'Completed',
      labelColor: validateColor('Text/Lables/Lable-success'),
      dateColor: validateColor('Text/Lables/Label-text-default'),
    },
  },
  [TransactionStatus.PENDING]: {
    style: {
      icon: pendingArrows,
      text: 'Pending',
      labelColor: validateColor('Transactions/Pending/Label'),
      dateColor: validateColor('Text/Lables/Label-text-default'),
    },
    animation: `${rotateKeyframes} 2s linear infinite`,
  },
  [TransactionStatus.ERROR]: {
    style: {
      icon: errorRed,
      text: 'Error',
      labelColor: validateColor('Text/Lables/Lable-error'),
      dateColor: validateColor('Transactions/Error/Date'),
    },
  },
  [TransactionStatus.WARNING]: {
    style: {
      icon: warningYellow,
      text: 'Warning',
      labelColor: validateColor('Text/Lables/Lable-warning'),
      dateColor: validateColor('Transactions/Warning/Date'),
    },
  },
}

const getTransactionStyleInfo = (status: TransactionStatus): TransactionStyleInfo => TransactionStyles[status]

interface TxEntryProps {
  label: string
  value: string
  explorerRef?: string
}

const TxEntry: React.FC<TxEntryProps> = ({ label, value, explorerRef }) => (
  <HStack justifyContent="space-between" alignItems="center" w="100%">
    <Text lineHeight={1}>{label}</Text>
    {explorerRef ? (
      <Link lineHeight={1} isExternal href={explorerRef}>
        {value}
      </Link>
    ) : (
      <Text lineHeight={1}>{value}</Text>
    )}
  </HStack>
)

interface TxCommonProps {
  status: TransactionStatus
  details: TransactionDetailsV2
  styleInfo: TransactionStyleInfo
}

const TxCommonInfo: React.FC<TxCommonProps> = ({ status, details, styleInfo }) => (
  <HStack style={getTypography('Typography/Small/Normal/Small 2')}>
    <Image src={styleInfo.style.icon} w="1rem" h="1rem" alt="Status Icon" animation={styleInfo.animation} />
    <Text color={styleInfo.style.labelColor}>{styleInfo.style.text}</Text>
    {details?.confirmedTime && (
      <Text color={styleInfo.style.dateColor}>{new Date(Number(details?.confirmedTime)).toLocaleString()}</Text>
    )}
  </HStack>
)

const TxPanelBody: React.FC<{ details: TransactionDetailsV2 }> = ({ details }) => {
  const { chainId } = useWeb3ReactWrapped()
  return (
    <VStack
      style={getTypography('Typography/Small/Normal/Small 2')}
      gap="0.5rem"
      alignItems={'flex-start'}
      w="100%"
      color={validateColor('Text/Lables/Label-text-hover')}
    >
      <TxEntry
        label={'Transaction'}
        value={formatHash(details.hash)}
        explorerRef={getExplorerLink(chainId ?? 0, details.hash, ExplorerDataType.TRANSACTION)}
      />
      <TxEntry label={'Date Added'} value={new Date(Number(details?.confirmedTime)).toLocaleString()} />
      <TxEntry
        label={'From'}
        value={formatAddress(details.from)}
        explorerRef={getExplorerLink(chainId ?? 0, details.from, ExplorerDataType.ADDRESS)}
      />
      {details.receipt?.to && (
        <TxEntry
          label={'To'}
          value={formatAddress(details.receipt.to)}
          explorerRef={getExplorerLink(chainId ?? 0, details.receipt.to, ExplorerDataType.ADDRESS)}
        />
      )}
    </VStack>
  )
}

const ApproveTx: React.FC<TxCommonProps> = ({ status, details, styleInfo: style }) => {
  const info = details.info as ApproveTransactionInfo
  const ccy = useCurrency(info.tokenAddress)

  const buttonBody = (
    <VStack w="100%" alignItems={'flex-start'}>
      <HStack w="100%" alignItems={'center'} gap="0.3rem" style={getTypography('Typography/Small/Normal/Small 1')}>
        <Text>{info.type} •</Text>
        <CurrencyLogo currency={ccy} size="1rem" />
      </HStack>
      <TxCommonInfo status={status} details={details} styleInfo={style} />
    </VStack>
  )

  return <Card buttonBody={buttonBody} panelBody={<TxPanelBody details={details} />} />
}

const GeneralTx: React.FC<TxCommonProps> = ({ status, details, styleInfo: style }) => {
  const info = details.info as Swaps
  const ccyIn = useCurrency(info.inputCurrencyId)
  const ccyOut = useCurrency(info.outputCurrencyId)

  const buttonBody = (
    <VStack w="100%" alignItems={'flex-start'}>
      <HStack w="100%" alignItems={'center'} gap="0.5rem" style={getTypography('Typography/Small/Normal/Small 1')}>
        <Text>
          {info.type === TransactionType.MONEY_MARKET ? 'Quick Action' : info.type} • {info?.txType}
        </Text>
        <HStack gap="0.25rem">
          {ccyOut && ccyIn?.equals(ccyOut) ? (
            <CurrencyLogo currency={ccyOut} size="1rem" />
          ) : (
            <>
              <CurrencyLogo currency={ccyIn} size="1rem" />
              <ChevronRightIcon />
              <CurrencyLogo currency={ccyOut} size="1rem" />
            </>
          )}
        </HStack>
      </HStack>
      <TxCommonInfo status={status} details={details} styleInfo={style} />
    </VStack>
  )

  return <Card buttonBody={buttonBody} panelBody={<TxPanelBody details={details} />} />
}

const RewardTx: React.FC<TxCommonProps> = ({ status, details, styleInfo: style }) => {
  const info = details.info as Rewards
  const lender = info.lender as Lender

  const buttonBody = (
    <VStack w="100%" alignItems={'flex-start'}>
      <HStack w="100%" alignItems={'center'} gap="0.5rem" style={getTypography('Typography/Small/Normal/Small 1')}>
        <Text>{info.type}</Text>
        <LenderLogo protocol={lender} w="1rem" h="1rem" />
      </HStack>
      <TxCommonInfo status={status} details={details} styleInfo={style} />
    </VStack>
  )

  return <Card buttonBody={buttonBody} panelBody={<TxPanelBody details={details} />} />
}

const SwitchEModeTx: React.FC<TxCommonProps> = ({ status, details, styleInfo: style }) => {
  const info = details.info as SwitchEModeTransactionInfo
  const lender = info.lender as Lender

  const buttonBody = (
    <VStack w="100%" alignItems={'flex-start'}>
      <HStack w="100%" alignItems={'center'} gap="0.5rem" style={getTypography('Typography/Small/Normal/Small 1')}>
        <Text>Switch E-Mode</Text>
        <LenderLogo protocol={lender} w="1rem" h="1rem" />
      </HStack>
      <TxCommonInfo status={status} details={details} styleInfo={style} />
    </VStack>
  )

  return (
    <Card
      buttonBody={buttonBody}
      panelBody={
        <VStack
          alignItems="flex-start"
          w="100%"
          style={getTypography('Typography/Small/Normal/Small 2')}
          color={validateColor('Text/Lables/Label-text-hover')}
        >
          <TxPanelBody details={details} />
          <TxEntry label="E-Mode" value={info.label} />
        </VStack>
      }
    />
  )
}

interface TransactionCardProps {
  status: TransactionStatus
  details: TransactionDetailsV2
}

export const TransactionCard: React.FC<TransactionCardProps> = ({ status, details }) => {
  const styleInfo = getTransactionStyleInfo(status)

  switch (details.info.type) {
    case TransactionType.APPROVAL:
      return <ApproveTx status={status} details={details} styleInfo={styleInfo} />
    case TransactionType.VEST_REWARDS:
    case TransactionType.CLAIM_REWARDS:
      return <RewardTx status={status} details={details} styleInfo={styleInfo} />
    case TransactionType.SWITCH_EMODE:
      return <SwitchEModeTx status={status} details={details} styleInfo={styleInfo} />
    default:
      return <GeneralTx status={status} details={details} styleInfo={styleInfo} />
  }
}
