import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { C_TOKEN_PRICE_DECIMALS, RESERVE_MANTISSA_DECIMALS, TOKEN_META } from 'constants/1delta'
import { SupportedChainId } from 'constants/chains'
import { getCompoundCTokens } from 'hooks/1delta/addressesCompound'
import { getCompoundLensAddress } from 'hooks/1delta/use1DeltaContract'
import { Call, multicallViem } from 'utils/multicall'
import COMPOUND_LENS_ABI from 'abis/compound-v2/CompoundLens.json'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { calculateRateForCompound } from 'utils/tableUtils/format'
import { getLenderAssets } from 'constants/getAssets'
import { LENDER_MODE_NO_MODE, Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/types'
import { AdditionalYields } from 'state/oracles/reducer'

const getEntry = (entry: any, chainId: number) => {
  return entry
}

export interface CompoundPublicResponse {
  chainId: number
  data: {
    [tokenSymbol: string]: {
      exchangeRateCurrent: number
      // interest rates
      depositRate: number,
      variableBorrowRate: number
      // deposits
      totalDebt: number
      totalDeposits: number
      // flag
      isListed: boolean
      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: 1,
        }
      }
      underlyingAssetAddress: number
      cTokenDecimals: number
      // rewards (TBU)
      compSupplySpeed: number
      compBorrowSpeed: number
      // cap
      borrowCap: number
    }
  }
}

export interface CompoundPublicQueryParams {
  chainId: number
}

export const fetchCompoundV2PublicData: AsyncThunk<CompoundPublicResponse, CompoundPublicQueryParams, any> =
  createAsyncThunk<
    CompoundPublicResponse,
    CompoundPublicQueryParams,
    {
      state: {
        oracles: {
          live: ChainIdMap<number>
          additionalYields: AdditionalYields
        }
      },
    }>(
      'compound-v2/fetchCompoundV2PublicData',
      async ({ chainId }, { getState }) => {
        const isEthereum = chainId === SupportedChainId.MAINNET || chainId === SupportedChainId.GOERLI
        if (!isEthereum) return {
          chainId,
          data: {}
        }
        // get fields from other slices
        const prices = getState().oracles.live
        const additionalYields = getState().oracles.additionalYields
        const rawAddressDict = getCompoundCTokens(chainId, getLenderAssets(chainId, Lender.COMPOUND_V2))

        const lensContract = getCompoundLensAddress(chainId)

        const tokens = Object.values(rawAddressDict)

        const names = Object.keys(rawAddressDict)

        const calls: Call[] = tokens.map((tk) => {
          return {
            address: lensContract,
            name: 'cTokenMetadata',
            params: [tk],
          }
        })
        const multicallResult = await multicallViem(chainId,
          COMPOUND_LENS_ABI,
          calls,
          1
        )

        const result = Object.assign(
          {},
          ...multicallResult.map((entry, index) => {
            const asset = names[index]
            const decs = TOKEN_META[asset].decimals
            const currentEntry = getEntry(entry, chainId)
            const borrowCollateralFactor = parseRawAmount(currentEntry?.collateralFactorMantissa?.toString(), RESERVE_MANTISSA_DECIMALS)
            const exchangeRateCurrent = parseRawAmount(currentEntry?.exchangeRateCurrent?.toString(), C_TOKEN_PRICE_DECIMALS)
            const totalSupplyUnderlying = parseRawAmount(currentEntry?.totalSupply?.toString(), decs) * exchangeRateCurrent
            const totalDebt = parseRawAmount(currentEntry?.totalBorrows?.toString(), decs)
            const totalLiquidity = totalSupplyUnderlying - totalDebt
            const price = prices[asset]
            return {
              [asset]: {
                exchangeRateCurrent,
                // interest rates
                depositRate: calculateRateForCompound(currentEntry?.supplyRatePerBlock?.toString(), chainId, Lender.COMPOUND_V2),
                variableBorrowRate: calculateRateForCompound(currentEntry?.borrowRatePerBlock?.toString(), chainId, Lender.COMPOUND_V2),
                // deposits
                totalDebt,
                totalDeposits: totalSupplyUnderlying,
                totalDebtUSD: totalDebt * price,
                totalDepositsUSD: totalSupplyUnderlying * price,
                totalLiquidity,
                totalLiquidityUSD: totalLiquidity * price,
                stakingYield: additionalYields.intrinsicYields[asset] ?? 0,
                // flag
                isListed: Boolean(currentEntry?.isListed),
                config: {
                  [LENDER_MODE_NO_MODE]: {
                    modeId: LENDER_MODE_NO_MODE,
                    // collateral factors
                    borrowCollateralFactor,
                    collateralFactor: borrowCollateralFactor,
                    borrowFactor: 1,
                  }
                },
                underlyingAssetAddress: currentEntry?.underlyingAssetAddress,
                cTokenDecimals: Number(currentEntry?.cTokenDecimals?.toString()),
                // rewards (TBU)
                compSupplySpeed: parseRawAmount(currentEntry?.compSupplySpeed?.toString(), 5),
                compBorrowSpeed: parseRawAmount(currentEntry?.borrowSpeedKey?.toString(), 5),
                // cap
                borrowCap: parseRawAmount(currentEntry?.borrowCap?.toString(), decs),
              },
            }
          })
        )
        return { data: result, chainId }
      }
    )
