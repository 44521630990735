import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { Lender } from 'types/lenderData/base'
import { CompoundV3UserReserveResponse } from './types'
import { getLenderAssets } from 'constants/getAssets'
import { SupportedAssets, toOracleKey } from 'types/1delta'
import { compoundV3AssetKey } from 'state/lenders/compound-v3/reducer'

export const getCompoundV3UserDataConverter = (
  lender: Lender,
  chainId: number,
  account: string,
  prices: { [asset: string]: number }
): [(data: any[]) => CompoundV3UserReserveResponse | undefined, number] => {
  switch (lender) {
    case Lender.COMPOUND_V3: {
      const names = getLenderAssets(chainId, lender)
      const baseAsset = SupportedAssets.USDCE
      const assetsNoBase = names.filter(a => a !== baseAsset)
      const expectedNumberOfCalls = assetsNoBase.length + 2 + 1 + 1
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          let result = {}
          for (let i = 0; i < assetsNoBase.length; i++) {
            const asset = assetsNoBase[i]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const depositRaw = data[i][0]?.toString()
            const deposits = parseRawAmount(depositRaw, decimals)
            const price = prices[toOracleKey(asset)]
            result[compoundV3AssetKey(baseAsset, asset)] = {
              deposits,
              depositsUSD: deposits * price,
              debt: 0,
              debtUSD: 0,
              collateralActive: true,
              // user depos raw
              depositRaw,
            }
          }

          const depositRaw = data[assetsNoBase.length]?.toString()
          const debtRaw = data[assetsNoBase.length + 1]?.toString()
          const baseDecimals = TOKEN_META[baseAsset].decimals ?? 18
          const deposits = parseRawAmount(depositRaw, baseDecimals)
          const debt = parseRawAmount(debtRaw, baseDecimals)
          const priceBase = prices[toOracleKey(baseAsset)]
          const resultBase = {
            [compoundV3AssetKey(baseAsset, baseAsset)]: {
              deposits,
              debt,
              depositsUSD: deposits * priceBase,
              debtUSD: debt * priceBase,
              // populate values for totals
              debtStable: 0,
              debtStableUSD: 0,
              collateralActive: true,
              // user depos raw
              depositRaw
            },
          }


          // the result is an array: [tokenAddress, owedAmount] (tokenAddress should be always COMP)
          // so we take [1] to get the owedAmount
          const earnedBalances = data[expectedNumberOfCalls - 1]
          const compRewards = parseRawAmount(earnedBalances.owed.toString(), 18)
          return {
            tokensData: { ...result, ...resultBase },
            chainId,
            isAllowed: Boolean(data[expectedNumberOfCalls - 2]),
            baseAsset,
            rewards: {
              [SupportedAssets.COMP]: {
                totalRewards: compRewards,
                claimableRewards: compRewards
              }
            },
            account
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}