import { formatAaveYieldToNumber } from 'utils/tableUtils/format'
import _ from 'lodash'
import { LenderConfigMap } from 'types/lenderData/base'

export const safeDivide = (a: number, b: number) => {
  return a === 0 ? 0 : b !== 0 ? a / b : Infinity
}

export function capitalizeFirstLetter(data: string) {
  if (!data) return ''
  return data.charAt(0).toUpperCase() + data.slice(1)
}

/**
 * Formats e.g. 0.04 to 4%
 * @param n ratio
 * @param decs decimals to show
 * @returns percent as string
 */
export const formatRatioToPercent = (n: number, decs: number) => {
  return `${n >= 0 ? '+' : ''}${roundNumber(n * 100, decs)}%`
}

/**
 * Formats e.g. 0.04 to 4%, adds a + if positive
 * @param n ratio
 * @param decs decimals to show
 * @returns percent as string
 */
export const formatSignedRatioToPercent = (n: number, decs: number) => {
  if (n > 1e7) return '>100K%' // we ceil percentages at 100k and abbreviate
  return `${n > 0 ? '+' : ''}${roundNumber(n * 100, decs)}%` // we assume no lower values that -100%
}

/**
 * Formats e.g. 4 to 4%
 * @param n percent number
 * @param decs decimals to show
 * @returns percent as string
 */
export const formatNumberToPercent = (n: number | undefined, decs = 2) => {
  if (!n) return '0%'
  if (isNaN(n)) return ''
  return `${roundNumber(Number(n ?? 0), decs)}%`
}

export const formatPercentagePoints = (n: number, decs: number) => {
  return `${roundNumber(n * 100, decs)}pp`
}

export const formatNumber = (n: number, decs: number) => {
  if (!n) return '0'
  if (isNaN(n)) return ''
  return roundNumber(n, decs).toLocaleString()
}

/**
 * Round using lodash
 * @param n number to round
 * @param decs decimals
 * @returns number
 */
export const roundNumber = (n: number, decs: number) => {
  return _.round(n, decs)
}

/**
 * Converts a colateral factor e.g. 0.8 to 1/(1-0.8) = 5
 * @param n amount
 * @param decs decimals to show
 * @returns leverage as string
 */
export const convertCollateralFactorToLeverage = (n: number, decs: number): number => {
  return roundNumber(1 / (1 - n), decs)
}

const parseToTimes = (n: number) => `${n}×`

export const parseLeverage = (cf: number, decs = 2) => parseToTimes(convertCollateralFactorToLeverage(cf, decs))

export const parseLeverageRange = (config: LenderConfigMap) => {
  const levs = _.uniq(
    Object.values(config ?? {}).map((cfg) => convertCollateralFactorToLeverage(cfg.borrowCollateralFactor, 2))
  )
  if (!levs) return ''
  if (levs.length === 1) return parseToTimes(levs[0])
  return `${_.min(levs)}-${parseToTimes(Number(_.max(levs)))}`
}

/**
 * Get apr from intinsic interest and reward
 * @param apr apr number
 * @param reward reward [per year] number
 * @returns number, validated for NaN
 */
export const getApr = (apr: number, reward: number, staking: number) => {
  const aprClean = !isNaN(apr) ? apr : 0
  const rewardClean = !isNaN(reward) ? reward : 0
  const stakingClean = !isNaN(staking) ? staking : 0
  if (aprClean + rewardClean + stakingClean < 0.01 && aprClean + rewardClean + stakingClean > -0.01) return '-'
  return `${(aprClean + rewardClean + stakingClean).toFixed(2)}%`
}

// Converters from apy to apr and reversed

const SECONDS_PER_YEAR = 31536000

export const aprToApy = (apr: number) => {
  return (1 + apr / SECONDS_PER_YEAR) ** SECONDS_PER_YEAR - 1
}

export const apyToApr = (apy: number) => {
  return ((apy + 1) ** (1 / SECONDS_PER_YEAR) - 1) * SECONDS_PER_YEAR
}

export const formatAaveRawApyToApr = (raw: bigint | string | undefined) => {
  const apy = formatAaveYieldToNumber(raw)
  return apyToApr(apy / 100) * 100
}
