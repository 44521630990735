import { SerializedBigNumber } from "types/1delta"
import { RewardsMap } from "types/lenderData/base"

export interface InitGeneralPublicResponse {
  data: {
    [tokenSymbol: string]: {
      // reserve market data
      unbacked?: number
      depositRate: number
      averageStableBorrowRate?: number
      liquidityIndex?: SerializedBigNumber
      variableBorrowIndex?: SerializedBigNumber
      lastUpdateTimestamp?: number

      config: {
        [modeId: number]: {
          modeId: number
          // collateral factors
          borrowCollateralFactor: number
          collateralFactor: number
          borrowFactor: number
        }
      }

      // rewards
      rewards?: RewardsMap

      // reserve config
      decimals?: number
      reserveFactor?: SerializedBigNumber
      usageAsCollateralEnabled?: boolean
      stableBorrowRateEnabled?: boolean

      // frozen
      isActive?: boolean
      isFrozen?: boolean
    }
  }
  chainId: number
}

export interface InitReservesQueryParams {
  chainId: number
}

export enum InitGetUserPositionDataIndexes {
  posMeta = 0,
  collaterals,
  debts,
}

export interface InitUserReserveResponse {
  chainId: number
  tokensData: {
    // positionId is the NFT id
    [posId: string]: {
      mode: number;
      isAllowed: boolean;
      isApprovedForAll: boolean;
      positions: {
        [tokenSymbol: string]: {
          deposits: number
          debt: number,
          depositsUSD: number
          debtUSD: number,
          debtStable: 0,
          debtStableUSD: 0,
          collateralActive: boolean
        }
      }
    }
  }
  assets: string[]
  account: string
}

export interface InitUserDataQueryParams {
  chainId: number
  account?: string
}