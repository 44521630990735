import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/types'
import { getLenderUserData } from '../fetchers/fetchUserData'
import { AaveV2UserReserveResponse } from '../fetchers/aave-v2-type/types'

interface AaveV2UserReservesQueryParams {
  chainId: number
  account: string
}

export const fetchAaveV2UserData: AsyncThunk<
  AaveV2UserReserveResponse,
  AaveV2UserReservesQueryParams,
  any
> = createAsyncThunk<
  AaveV2UserReserveResponse,
  AaveV2UserReservesQueryParams,
  {
    state: {
      oracles: {
        live: ChainIdMap<number>
      }
    },
  }>(
    'aave-v2/fetchAaveV2UserData',
    async ({ chainId, account }, { getState }) => {
      if (!account) {
        return { chainId, tokensData: {}, rewards: {}, allowances: {}, account: '' }
      }

      // get fields from other slices
      const prices = getState().oracles.live
      try {
        const lenderData = await getLenderUserData(chainId, [Lender.AAVE_V2], account, prices)
        return lenderData[Lender.AAVE_V2]
      } catch (error) {
        console.error('Error fetching Aave V2 user data:', error)
        return {
          data: {},
          config: {},
          chainId,
        }
      }
    }
  )
