import { IconButtonWithTooltip } from 'components/Button/Action/IconButtonWithTooltip'
import { settings } from 'constants/1delta'
import { useUserTheme } from 'state/user/hooks'

interface SettingsButton {
  onOpenSettings: () => void
}

export const SettingsButton = ({ onOpenSettings }: SettingsButton) => {
  const theme = useUserTheme()
  return <IconButtonWithTooltip
    imageProps={{ w: '1.3rem', h: '1.3rem' }}
    src={settings[theme]}
    onClick={onOpenSettings}
  />
}