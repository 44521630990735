import { Menu, MenuButton, MenuList, Text, Image, VStack, HStack, Link } from '@chakra-ui/react'
import { getChainInfo, getRawChainInfo } from 'constants/chainInfo'
import {
  getLendersOnChain,
  SupportedChainId,
  getAvailableMarginChainIds,
  getAvailableSpotChainIds,
} from 'constants/chains'
import useSelectChain from 'hooks/useSelectChain'
import { useCloseModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { toLenderText } from 'constants/1delta'
import { useChainId } from 'state/globalNetwork/hooks'
import { trackChainSelected, trackLenderSelected } from 'utils/analytics'
import { useAmendPath, useSelectLenderState } from 'state/user/hooks'
import { Lender } from 'types/lenderData/base'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { SecondaryMediumIconRightButton } from 'components/Button/Secondary'
import { default as errorRed } from 'assets/icons/error-red.svg'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { useLocation } from 'react-router-dom'
import { LenderLogo } from 'components/LenderLogo'
import { OverlappingLogos } from 'components/OverlappingLogos'
import { SelectorRadioRow } from './SelectorRadioRow'
import { default as externalLinkIcon } from 'assets/icons/external-link-light.svg'

const LinkIcon = () => {
  return <Image src={externalLinkIcon} w="0.75rem" h="0.75rem" alt="external link" />
}

const BridgeLabel = ({ chainId }: { chainId: SupportedChainId }) => {
  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
    case SupportedChainId.ARBITRUM_RINKEBY:
      return <>Arbitrum Bridge</>
    case SupportedChainId.AVALANCHE:
      return <>Avalanche Bridge</>
    case SupportedChainId.BASE:
      return <>Base Bridge</>
    case SupportedChainId.OPTIMISM:
    case SupportedChainId.OPTIMISM_GOERLI:
      return <>Optimism Bridge</>
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return <>Polygon Bridge</>
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
      return <>Portal Bridge</>
    case SupportedChainId.BLAST:
      return <>Blast Bridge</>
    case SupportedChainId.LINEA:
      return <>Linea Bridge</>
    case SupportedChainId.TAIKO:
      return <>Taiko Bridge</>
    default:
      return <>Bridge</>
  }
}

const ExplorerLabel = ({ chainId }: { chainId: SupportedChainId }) => {
  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
    case SupportedChainId.ARBITRUM_RINKEBY:
      return <>Arbiscan</>
    case SupportedChainId.AVALANCHE:
      return <>Avalanche Explorer</>
    case SupportedChainId.BASE:
      return <>BaseScan</>
    case SupportedChainId.OPTIMISM:
    case SupportedChainId.OPTIMISM_GOERLI:
      return <>OP Explorer</>
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return <>Polygonscan</>
    case SupportedChainId.BSC:
      return <>BscScan</>
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
      return <>Blockscout</>
    case SupportedChainId.MANTLE:
      return <>Mantle Explorer</>
    case SupportedChainId.BLAST:
      return <>Blastscan</>
    case SupportedChainId.LINEA:
      return <>Lineascan</>
    case SupportedChainId.TAIKO:
      return <>Taikoscan</>
    default:
      return <>Etherscan</>
  }
}

function Row({
  targetChain,
  onSelectChain,
  onSelectLender,
  selectedLender,
  showLenders,
}: {
  targetChain: SupportedChainId
  onSelectChain: (targetChain: number) => Promise<boolean>
  onSelectLender: (lender: Lender) => boolean | void
  selectedLender: Lender
  showLenders: boolean
}) {
  const { provider, chainId: chainIdUser, account } = useWeb3ReactWrapped()
  const stateChainId = useChainId()
  if (!provider || !chainIdUser) {
    return null
  }

  const amendPath = useAmendPath()

  const onClick = async (lender: Lender | undefined) => {
    let chainSwitchSuccessful = true
    if (lender) {
      // add history data
      amendPath(lender, targetChain)
      // if not connected, the hook just changes the state chainId
      if (!account || targetChain !== chainIdUser) {
        chainSwitchSuccessful = await onSelectChain(targetChain)
        if (chainSwitchSuccessful && account) trackChainSelected(targetChain)
      }
      if (chainSwitchSuccessful && selectedLender !== lender) {
        onSelectLender(lender)
        account && trackLenderSelected(lender)
      }
    } else {
      // if not connected, the hook just changes the state chainId
      if (!account || targetChain !== chainIdUser || stateChainId !== targetChain) {
        amendPath(undefined, targetChain)
        chainSwitchSuccessful = await onSelectChain(targetChain)
        if (chainSwitchSuccessful && account) trackChainSelected(targetChain)
      }
    }
    closeModal()
  }

  const active = stateChainId === targetChain

  const { explorer, bridge, label, logoUrl } = getChainInfo(targetChain as SupportedChainId)

  const closeModal = useCloseModal(ApplicationModal.NETWORK_SELECTOR)

  const rowContentWithLenders = (
    <VStack w="100%" alignItems="flex-start">
      <Text
        style={getTypography('Typography/Small/Bold/Small 1')}
        color={validateColor('Text/Headings & Titles/Title-text')}
        lineHeight={'1 !important'}
      >
        {label}
      </Text>
      <VStack w="100%" gap="0.125rem">
        {getLendersOnChain(targetChain).map((lender: Lender) => (
          <SelectorRadioRow
            active={targetChain === stateChainId && selectedLender === lender}
            key={lender}
            onClick={async () => await onClick(lender)}
          >
            <HStack justifyContent="flex-start" alignItems="center">
              <LenderLogo protocol={lender} w="16px" h="16px" />
              <Text
                style={getTypography('Typography/Small/Normal/Small 1')}
                color={
                  selectedLender === lender
                    ? validateColor('Text/Headings & Titles/Title-text')
                    : validateColor('Text/Lables/Label-text-hover')
                }
              >
                {toLenderText(lender)}
              </Text>
            </HStack>
          </SelectorRadioRow>
        ))}
      </VStack>
    </VStack>
  )

  const rowContentWithoutLenders = (
    <SelectorRadioRow
      active={targetChain === stateChainId}
      key={targetChain}
      onClick={async () => await onClick(undefined)}
    >
      <HStack justifyContent="flex-start" alignItems="center">
        <Image src={logoUrl} w="16px" h="16px" alt="chain logo" />
        <Text
          style={getTypography('Typography/Small/Normal/Small 1')}
          color={
            targetChain === stateChainId
              ? validateColor('Text/Headings & Titles/Title-text')
              : validateColor('Text/Lables/Label-text-hover')
          }
        >
          {label}
        </Text>
      </HStack>
    </SelectorRadioRow>
  )

  const rowContent = showLenders ? rowContentWithLenders : rowContentWithoutLenders

  if (active) {
    return (
      <VStack width="100%" gap="0.125rem">
        {rowContent}
        <HStack
          w="100%"
          padding="0.38rem"
          style={getTypography('Typography/Small/Normal/Small 2')}
          color={validateColor('Text/Lables/Label-text-hover')}
          justifyContent="space-between"
          bg={validateColor('Surface/Surface-primary 2')}
          borderRadius="0.25rem"
        >
          {bridge && (
            <Link w="auto" href={bridge} isExternal color={validateColor('Text/Lables/Label-text-hover')}>
              <HStack w="auto">
                <BridgeLabel chainId={stateChainId} />
                <LinkIcon />
              </HStack>
            </Link>
          )}
          {explorer && (
            <Link w="auto" href={explorer} isExternal color={validateColor('Text/Lables/Label-text-hover')}>
              <HStack w="auto">
                <ExplorerLabel chainId={stateChainId} />
                <LinkIcon />
              </HStack>
            </Link>
          )}
        </HStack>
      </VStack>
    )
  }
  return rowContent
}

export function NetworkSelector({ isMobile }: { isMobile: boolean }) {
  const { provider, chainId: chainIdConnected, account } = useWeb3ReactWrapped()

  const chainId = useChainId()

  const [selectedLender, selectLender] = useSelectLenderState()

  const info = !provider ? undefined : chainId ? getRawChainInfo(chainId) : undefined

  const selectChain = useSelectChain()

  const chainIsSupported = account ? chainIdConnected === chainId : info !== undefined

  const { pathname } = useLocation()

  const showLenders = pathname.includes('/margin')

  const NetworkAndLenderLogos: React.FC = () => {
    return (
      <>
        <Image src={info?.logoUrl} w="20px" h="20px" />
        {showLenders && <LenderLogo protocol={selectedLender} w="16px" h="16px" />}
      </>
    )
  }
  return !chainId || !provider ? null : (
    <Menu closeOnBlur={true}>
      <SecondaryMediumIconRightButton as={MenuButton} p="0.25rem 0.5rem" h={'30px'}>
        {chainIsSupported ? (
          <>
            <OverlappingLogos>
              <NetworkAndLenderLogos />
            </OverlappingLogos>
          </>
        ) : (
          <HStack alignItems="center">
            <Image src={errorRed} alt="error-red" width="1.25rem" height="1.25rem" />
            <Text
              display="none"
              overflow="hidden"
              textOverflow="ellipsis"
              white-space="nowrap"
              margin="0 0.5rem 0 0.4rem"
              fontSize="1rem"
              width="fit-content"
              fontWeight={500}
              color={validateColor('Text/Lables/Lable-error')}
            >
              Switch Network
            </Text>
          </HStack>
        )}
      </SecondaryMediumIconRightButton>
      <MenuList
        border="none"
        boxShadow="dark"
        width="300px"
        backgroundColor={validateColor('Surface/Surface-primary')}
        padding="1rem"
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={showLenders ? '1.5rem' : '0.5rem'}
      >
        <Row
          onSelectChain={async (targetChainId: SupportedChainId) => await selectChain(targetChainId)}
          targetChain={chainId}
          key={chainId}
          onSelectLender={(lender: Lender) => selectLender(lender)}
          selectedLender={selectedLender}
          showLenders={showLenders}
        />
        {(showLenders ? getAvailableMarginChainIds() : getAvailableSpotChainIds())
          .filter((i) => i !== chainId)
          .map((_chainId: SupportedChainId) => (
            <Row
              onSelectChain={async (targetChainId: SupportedChainId) => await selectChain(targetChainId)}
              targetChain={_chainId}
              key={_chainId}
              onSelectLender={(lender: Lender) => selectLender(lender)}
              selectedLender={selectedLender}
              showLenders={showLenders}
            />
          ))}
      </MenuList>
    </Menu>
  )
}
