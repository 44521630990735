import { validateColor } from 'theme/colors'
import { Box, BoxProps, VStack } from '@chakra-ui/layout'
import { useIsMobile } from 'hooks/useIsMobile'
import { Z_INDEX } from 'theme'
import { css } from '@emotion/react'

export const FadeInAnimationQuick = `
  animation: fadeIn;
  animation-duration: 150ms;
`

export const FadeInAnimation = css`
  animation: fadeIn;
  animation-duration: 300ms;
`

interface PageContainerWithAnimationProps extends BoxProps {}

export const PageContainerWithAnimation: React.FC<PageContainerWithAnimationProps> = ({ children, ...props }) => {
  return (
    <Box css={FadeInAnimation} width={'100%'} maxW="1800px" margin={'auto'} marginTop="0" px="1.5rem" {...props}>
      {children}
    </Box>
  )
}

export const AppWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <VStack
      h="100%"
      minH="100vh"
      bgColor={validateColor('Surface/Page-background')}
      alignItems={'flex-start'}
      style={{ fontFeatureSettings: "'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on" }}
    >
      {children}
    </VStack>
  )
}

export const HeaderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box w="100%" justifyContent={'space-between'} position={'fixed'} top={0} zIndex={Z_INDEX.sticky}>
      {children}
    </Box>
  )
}

export const BodyWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobile = useIsMobile()
  return (
    <VStack
      w="100%"
      h="100%"
      minH="100vh"
      paddingTop={'5.5rem'}
      backgroundColor={validateColor('Surface/Page-background')}
      justifyContent="space-between"
      gap={isMobile ? '0' : '0.5rem'}
    >
      {children}
    </VStack>
  )
}
