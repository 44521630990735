import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/types'
import { getLenderUserData } from '../fetchers/fetchUserData'
import { InitUserDataQueryParams, InitUserReserveResponse } from '../fetchers/init/types'

export const fetchInitUserData: AsyncThunk<
  InitUserReserveResponse,
  InitUserDataQueryParams,
  any
> = createAsyncThunk<
  InitUserReserveResponse,
  InitUserDataQueryParams,
  {
    state: {
      oracles: {
        live: ChainIdMap<number>
      }
    },
  }
>(
  'lender-init/fetchInitUserData',
  async ({ chainId, account }, { getState }) => {
    if (!account) {
      return { chainId, tokensData: {}, rewards: {}, allowances: {}, account: '' }
    }
    // get fields from other slices
    const prices = getState().oracles.live
    try {
      const lenderData = await getLenderUserData(chainId, [Lender.INIT], account, prices)
      return lenderData[Lender.INIT]
    } catch (error) {
      console.error('Error fetching Init user data:', error)
      return {
        data: {},
        chainId,
      }
    }
  }
)