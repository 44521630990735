import { Token } from '@1delta/base-sdk'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { TypedTokenList } from 'state/lists/reducer'
import { getAddress } from 'viem'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4, isMobile = false): string {
  const parsed = isAddress(address)
  if (!parsed) {
    // throw Error(`Invalid 'address' parameter '${address}'.`)
    console.log(`Invalid 'address' parameter '${address}'.`)
    return ""
  }
  if (isMobile) {
    return `${parsed.substring(0, 5)}...`
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function isTokenOnList(chainTokenMap: TypedTokenList, token?: Token | WrappedTokenInfo): boolean {
  return Boolean(token?.isToken && chainTokenMap?.[token.address])
}
