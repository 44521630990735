import { EModeData } from "types/lenderData/aave-v3";
import { RewardsMap } from "types/lenderData/base";
import { AllowanceData } from "../aave-v2-type/types";

export enum AaveV3GetReservesIndexes {
  unbacked = 0,
  accruedToTreasuryScaled = 1,
  totalAToken = 2,
  totalStableDebt = 3,
  totalVariableDebt = 4,
  liquidityRate = 5,
  variableBorrowRate = 6,
  stableBorrowRate = 7,
  averageStableBorrowRate = 8,
  liquidityIndex = 9,
  variableBorrowIndex = 10,
  lastUpdateTimestamp = 11
}


export enum AaveV3PoolGetReservesFields {
  configuration = 0,
  liquidityIndex,
  currentLiquidityRate,
  variableBorrowIndex,
  currentVariableBorrowRate,
  currentStableBorrowRate,
  lastUpdateTimestamp,
  id,
  aTokenAddress,
  stableDebtTokenAddress,
  variableDebtTokenAddress,
  interestRateStrategyAddress,
  accruedToTreasury,
  unbacked,
  isolationModeTotalDebt,
}

export enum AaveV3GetreserveConfigDataIndexes {
  decimals = 0,
  ltv,
  liquidationThreshold,
  liquidationBonus,
  reserveFactor,
  usageAsCollateralEnabled,
  borrowingEnabled,
  stableBorrowRateEnabled,
  isActive,
  isFrozen,
}

/** Names for the emode struct query */

export enum AaveV3GetEModeCategoryDataIndexes {
  ltv = 'ltv',
  liquidationThreshold = 'liquidationThreshold',
  liquidationBonus = 'liquidationBonus',
  priceSource = 'priceSource',
  label = 'label'
}



export interface AaveV3GeneralPublicResponse {
  data: {
    [tokenSymbol: string]: {
      // token amounts
      totalDeposits: number;
      totalDebtStable: number;
      totalDebt: number;
      // USD amounts
      totalDepositsUSD: number;
      totalDebtStableUSD: number;
      totalDebtUSD: number;
      // rates
      depositRate: number;
      variableBorrowRate: number;
      stableBorrowRate: number;
      averageStableBorrowRate: number;
      // misc
      liquidityIndex: string;
      variableBorrowIndex: string;
      lastUpdateTimestamp: number;
      // rewards
      rewards?: RewardsMap
    }
  }
  config: {
    [tokenSymbol: string]: {
      decimals: number;

      config: {
        [modeId: number]: {
          modeId: number
          // collateral factors
          borrowCollateralFactor: number
          collateralFactor: number
          borrowFactor: 1
        }
      }

      // flags
      collateralActive: boolean;
      borrowingEnabled: boolean;
      hasStable: boolean;
      isActive: boolean;
      isFrozen: boolean;

      // eMode
      eMode: {
        label: string;
        category: number;
        borrowCollateralFactor: number;
        collateralFactor: number;
        priceSource: string;
      };

      // caps
      borrowCap: number;
      supplyCap: number;
      debtCeiling: number;
    }
  }
  chainId: number
  eModes: {
    [mode: number]: EModeData
  }
}

export enum AaveTypeGetUserReserveIndexes {
  currentATokenBalance = 0,
  currentStableDebt,
  currentVariableDebt,
  principalStableDebt,
  scaledVariableDebt,
  stableBorrowRate,
  liquidityRate,
  stableRateLastUpdated,
  usageAsCollateralEnabled,
}

export interface AaveV3UserReserveResponse {
  chainId: number
  tokensData: {
    [tokenSymbol: string]: {
      // token amounts
      deposits: number
      debt: number
      debtStable: number
      // usd amounts
      depositsUSD: number
      debtUSD: number
      debtStableUSD: number
      collateralActive: boolean
    }
  }
  allowances: { [asset: string]: AllowanceData }
  userEMode: number
  account: string
}
