import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/types'
import { getLenderUserData } from '../fetchers/fetchUserData'
import { CompoundV3UserReserveResponse } from '../fetchers/compound-v3/types'

interface CompoundV3UserReservesQueryParams {
  chainId: number
  account: string
}

export const fetchCompoundV3UserData: AsyncThunk<
  CompoundV3UserReserveResponse,
  CompoundV3UserReservesQueryParams,
  any
> = createAsyncThunk<
  CompoundV3UserReserveResponse,
  CompoundV3UserReservesQueryParams,
  {
    state: {
      oracles: {
        live: ChainIdMap<number>
      }
    },
  }>(
    'Compound-v3/fetchCompoundV3UserData',
    async ({ chainId, account }, { getState }) => {
      if (!account) {
        return { chainId, tokensData: {}, rewards: {}, allowances: {}, account: '' }
      }
      // get fields from other slices
      const prices = getState().oracles.live
      try {
        const lenderData = await getLenderUserData(chainId, [Lender.COMPOUND_V3], account, prices)
        return lenderData[Lender.COMPOUND_V3]
      } catch (error) {
        console.error('Error fetching Compound V3 user data:', error)
        return {
          data: {},
          chainId,
        }
      }
    }
  )
