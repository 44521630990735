import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { SupportedChainId } from 'constants/chains'
import { fetchAllLendlePrices } from './lendle/fetchPrices'


interface SubgraphResponse {
  chainId: number
  data: {
    [tokenSymbol: string]: number
  }
}

interface SubgraphQueryParams {
  chainId: number
}

/**
 * Fetch prices from Aave-wrapped oracles. It is the same for Aave V3 and V2. 
 */
export const fetchAaveAggregatorHistAsync: AsyncThunk<SubgraphResponse, SubgraphQueryParams, any> =
  createAsyncThunk<SubgraphResponse, SubgraphQueryParams>(
    'oracles/fetchAaveAggregatorHistAsync',
    async ({ chainId }) => {
      if (chainId === SupportedChainId.BSC) return { chainId, data: {} }

      const nowMinus24 = Math.floor(Date.now() / 1000 - 24 * 3600)
      // const data = await fetchAllAaveV3HistPrices(nowMinus24, chainId)
      const { histPrices } = await fetchAllLendlePrices(nowMinus24)
      return { data: histPrices, chainId }
    }
  )
