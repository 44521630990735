import { Connector } from '@web3-react/types'
import PARTICLE_ICON from 'assets/wallets/particle-icon.png'
import { particleConnection, getConnectionName } from 'connection'
import { ConnectionType } from 'connection/types'

import Option from './Option'

const BASE_PROPS = {
  icon: PARTICLE_ICON,
  id: 'particle-wallet',
}

export function ParticleWalletOption({ tryActivation }: { tryActivation: (connector: Connector) => void }) {
  if (!particleConnection.shouldDisplay()) return null

  const isActive = particleConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(particleConnection.connector)}
      header={getConnectionName(ConnectionType.SOCIAL_LOGIN)}
    />
  )
}
