import { Connector } from '@web3-react/types'
import WALLET_CONNECT_ICON_URL from 'assets/svg/uniswap.svg'
import { getConnectionName, uniwalletWCV2ConnectConnection, } from 'connection'
import { ConnectionType } from 'connection/types'


import Option from './Option'

const BASE_PROPS = {
  icon: WALLET_CONNECT_ICON_URL,
  id: 'wallet-connect',
}

export function UniswapOption({ tryActivation }: { tryActivation: (connector: Connector) => void }) {
  const isActive = uniwalletWCV2ConnectConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(uniwalletWCV2ConnectConnection.connector)}
      header={getConnectionName(ConnectionType.UNISWAP_WALLET_V2)}
    />
  )
}
