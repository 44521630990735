import { Call, multicallViem } from 'utils/multicall'

// aave v2 style
import AAVE_POOL_DATA_PROVIDER_ABI from 'abis/lendle/ProtocolDataProvider.json'
import INCENTIVES_CONTROLLER_ABI from 'abis/lendle/IncentivesController.json'
import REWARDER_ABI from 'abis/aurelius/rewarder.json'
import UNI_V2_PAIR_ABI from 'abis/uniswap/uniswap-v2-pair.json'

// aave v3 style
import AAVE_V3_POOL_ABI from 'abis/aave/AavePoolV3Upgraded.json'
import AAVE_V3_POOL_DATA_PROVIDER_ABI from 'abis/aave/AaveV3ProtocolDataProvider.json'

// init
import INIT_PUBLIC_LENS_ABI from 'abis/init/initLens.json'

// compound v3
import COMET_ABI from 'abis/compound-v3/Comet.json'
import COMET_EXT_ABI from 'abis/compound-v3/CometExt.json'
import IR_GETTER_ABI from 'abis/compound-v3/IrGetter.json'

import { isAaveV2Type, isAaveV3Type, Lender } from 'types/lenderData/base'
import { AdditionalYields } from 'state/oracles/reducer'
import { buildAaveV2StyleLenderReserveCall } from './aave-v2-type/publicCallBuild'
import { buildAaveV3StyleLenderReserveCall } from './aave-v3-type/publicCallBuild'
import { buildInitStyleLenderReserveCall } from './init/publicCallBuild'
import { buildCompoundV3StyleLenderReserveCall } from './compound-v3/publicCallBuild'
import { getAaveV2ReservesDataConverter } from './aave-v2-type/publicCallParse'
import { getAaveV3ReservesDataConverter } from './aave-v3-type/publicCallParse'
import { getInitReservesDataConverter } from './init/publicCallParse'
import { getCompoundV3ReservesDataConverter } from './compound-v3/publicCallParse'

function buildLenderCall(chainId: number, lender: Lender) {
  if (isAaveV2Type(lender)) return buildAaveV2StyleLenderReserveCall(chainId, lender)
  if (isAaveV3Type(lender)) return buildAaveV3StyleLenderReserveCall(chainId, lender)
  if (lender === Lender.INIT) return buildInitStyleLenderReserveCall(chainId, lender)
  return buildCompoundV3StyleLenderReserveCall(chainId, lender)
}

function getLenderDataConverter(
  lender: Lender,
  chainId: number,
  prices: { [a: string]: number },
  additionalYields: AdditionalYields
) {
  if (isAaveV2Type(lender)) return getAaveV2ReservesDataConverter(lender, chainId, prices, additionalYields)
  if (isAaveV3Type(lender)) return getAaveV3ReservesDataConverter(lender, chainId, prices, additionalYields)
  if (lender === Lender.INIT) return getInitReservesDataConverter(lender, chainId, prices, additionalYields)
  return getCompoundV3ReservesDataConverter(lender, chainId, prices, additionalYields)
}

const getAbi = (lender: string) => {
  switch (lender) {
    case Lender.AURELIUS:
    case Lender.AAVE_V2:
    case Lender.MERIDIAN:
    case Lender.LENDLE:
    case Lender.TAKOTAKO:
      return [
        ...AAVE_POOL_DATA_PROVIDER_ABI,
        ...UNI_V2_PAIR_ABI,
        ...REWARDER_ABI,
        ...INCENTIVES_CONTROLLER_ABI
      ]
    case Lender.AAVE_V3:
      return [
        ...AAVE_V3_POOL_ABI,
        ...AAVE_V3_POOL_DATA_PROVIDER_ABI
      ]
    case Lender.INIT:
      return [
        ...INIT_PUBLIC_LENS_ABI,
      ]
    case Lender.COMPOUND_V3:
      return [
        ...IR_GETTER_ABI,
        ...COMET_ABI,
        ...COMET_EXT_ABI
      ]
    default:
      return []
  }
}

export const getLenderPublicData = async (
  chainId: number,
  lenders: Lender[],
  prices: { [asset: string]: number },
  additionalYields: AdditionalYields
): Promise<{ [lender: string]: any }> => {
  let calls: {
    call: Call
    abi: any
  }[] = []

  for (const lender of lenders) {
    const abi = getAbi(lender)
    const callData = buildLenderCall(chainId, lender)
    const mappedCalls = callData.map((call) => ({ call, abi }))
    calls = [...calls, ...mappedCalls]
  }

  const rawResults = await multicallViem(
    chainId,
    calls.flatMap((call) => call.abi),
    calls.map((call) => call.call),
    1,
  )

  const invalidLenders: string[] = []
  const lenderData: { [lender: string]: any } = {}

  let currentSlice = 0
  for (const lender of lenders) {
    const [converter, sliceLength] = getLenderDataConverter(lender, chainId, prices, additionalYields)

    const data = rawResults.slice(currentSlice, currentSlice + sliceLength)
    const convertedData = converter(data)
    if (!convertedData) {
      invalidLenders.push(lender)
    } else {
      lenderData[lender] = convertedData
    }

    currentSlice += sliceLength
  }

  return lenderData
}
