import { isAaveType, COMPOUND_V2_FORKS, LENDER_MODE_NO_MODE, Lender, LenderConfigMap, isAaveV3Type, isInit } from "types/lenderData/base";
import { useCallback, useMemo } from 'react'
import { useAccount } from 'state/globalNetwork/hooks'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { SupportedAssets, toErc20Asset } from 'types/1delta'
import { compoundV3AssetKey } from "state/lenders/compound-v3/reducer";
import { AaveV3Public, AaveV3User, ModeBase } from "types/lenderData/aave-v3";
import { CompoundV2Public, CompoundV2User } from "types/lenderData/compound-v2";
import { CompoundV3Public, CompoundV3User } from "types/lenderData/compound-v3";
import { fetchAaveV3UserData } from "state/lenders/aave-v3/fetchUserData";
import { fetchLendleUserData } from "state/lenders/lendle/fetchUserData";
import { fetchCompoundV3UserData } from "state/lenders/compound-v3/fetchUserData";
import { fetchCompoundV2UserData } from "state/lenders/compound-v2/fetchUserData";
import { fetchVenusUserData } from "state/lenders/venus/fetchUserData";
import { Currency } from "@1delta/base-sdk";
import { tokenToAsset } from "hooks/1delta/addressesTokens";
import { fetchInitUserData } from "state/lenders/init/fetchUserData";
import { initBrokerAddresses } from "hooks/1delta/addresses1Delta";
import { InitPublic, InitUser } from "types/lenderData/init";
import { useGetSelectedSubAccountData } from "state/1delta/hooks";
import { INIT_EMODE_LABELS, INIT_MODES } from "constants/chains";
import { fetchAaveV2UserData } from "state/lenders/aave-v2/fetchUserData";
import { fetchMeridianUserData } from "state/lenders/meridian/fetchUserData";
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { trackEModeSwitched } from "utils/analytics";
import { Property } from "utils/analytics/types";
import { AaveV3Lending } from "calldata/external/aave-v3";
import { InitCore } from "calldata/init";
import { createEVMTxn, useSendEVMTransaction } from "calldata";
import { getAaveTypePoolAddress } from "hooks/1delta";
import { getInitCoreAddress } from "hooks/1delta/addressesInit";

export const lenderToReduxSlice = (lender: Lender) => {
  switch (lender) {
    case Lender.AAVE_V3: return 'aaveV3'
    case Lender.AAVE_V2: return 'aaveV2'
    case Lender.LENDLE: return 'lendle'
    case Lender.COMPOUND_V2: return 'compoundV2'
    case Lender.COMPOUND_V3: return 'compoundV3'
    case Lender.VENUS: return 'venus'
    case Lender.OVIX: return 'vix'
    case Lender.INIT: return 'init'
    case Lender.MERIDIAN: return 'meridian'
    case Lender.AURELIUS: return 'aurelius'
    case Lender.TAKOTAKO: return 'takotako'
    default: return String(lender).toLowerCase()
  }
}

export const toLenderAssetKey = (asset: SupportedAssets | string, lender: Lender, baseAsset = SupportedAssets.USDCE) => {
  if (lender !== Lender.COMPOUND_V3) return asset
  else return compoundV3AssetKey(baseAsset, asset)
}

export function useGetCurrentAccount(chainId: number) {
  return useAppSelector((state) => state.delta).userMeta[chainId]?.selectedAccountData?.account
}

export function useGetCurrentAccountBalances(chainId: number, lender = Lender.INIT) {
  const reduxSlice = lenderToReduxSlice(lender)
  const ids = Object.keys(useAppSelector((state) => state[reduxSlice])?.[Number(chainId)]?.userConfigs ?? {})

  return useAppSelector(
    state => Object.assign({},
      ...ids.map(id => {
        return {
          [id]: state[reduxSlice]?.[chainId]?.balanceData?.[id ?? '']
        }
      }
      )
    )
  )
}

export function useGetSingleLenderData(
  chainId: number,
  currency?: Currency | undefined,
  account?: string | undefined,
  protocol = Lender.AAVE_V3,
  baseAsset = SupportedAssets.USDCE
): [
    undefined | CompoundV3Public | CompoundV2Public | AaveV3Public | InitPublic,
    undefined | CompoundV3User | CompoundV2User | AaveV3User | InitUser,
    SupportedAssets
  ] {
  const assetId = COMPOUND_V2_FORKS.includes(protocol) ? tokenToAsset(currency) : toErc20Asset(tokenToAsset(currency))
  return [
    useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.lenderData[
      toLenderAssetKey(assetId, protocol, baseAsset)
    ]),
    useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.userData?.[account ?? '']?.[
      toLenderAssetKey(assetId, protocol, baseAsset)
    ]),
    assetId
  ]
}

export function useGetPairLenderData(
  chainId: number,
  asset0?: SupportedAssets,
  asset1?: SupportedAssets,
  account?: string | undefined,
  protocol = Lender.AAVE_V3,
  baseAsset = SupportedAssets.USDCE
): [
  undefined | CompoundV3Public | CompoundV2Public | AaveV3Public | InitPublic,
  undefined | CompoundV3User | CompoundV2User | AaveV3User | InitUser,
][] {
  return [[
    useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.lenderData[
      toLenderAssetKey(asset0 as any, protocol, baseAsset)
    ]),
    useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.userData?.[account ?? '']?.[
      toLenderAssetKey(asset0 as any, protocol, baseAsset)
    ]),
  ],
  [
    useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.lenderData[
      toLenderAssetKey(asset1 as any, protocol, baseAsset)
    ]),
    useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.userData?.[account ?? '']?.[
      toLenderAssetKey(asset1 as any, protocol, baseAsset)
    ]),
  ]
  ]
}

type AssetConfigData = {
  asset: SupportedAssets,
  config: LenderConfigMap,
  collateralActive: boolean,
  isolationFlag: boolean
}

/**
 * Get enriched config data
 * @param assets asset list to get configs for
 * @param chainId network
 * @param protocol lender
 * @param accounKey account or subAccount
 * @returns configs in the same order as input
 */
function useGetAssetsConfigs(
  assets: SupportedAssets[],
  chainId: number,
  protocol = Lender.AAVE_V3,
  accounKey?: string
): AssetConfigData[] {
  const data = useAppSelector(
    (state) => state[lenderToReduxSlice(protocol)]?.[chainId ?? 0]?.lenderData
  ) ?? {}
  const dataUser = useAppSelector(
    (state) => state[lenderToReduxSlice(protocol)]?.[chainId ?? 0]?.userData[accounKey ?? '']
  ) ?? {}
  return assets.map(a => {
    const collateralActive = Boolean(dataUser[a]?.collateralActive)
    return {
      asset: a,
      config: data[a]?.config,
      collateralActive,
      isolationFlag: (
        data[a]?.debtCeiling > 0 &&
        collateralActive
      )
    }
  })
}


export type RiskFactors = {
  collateralFactor: number,
  borrowCollateralFactor: number,
  borrowFactor: number,
  isolated?: boolean
}

const DEFAULT_FACTORS: RiskFactors = {
  collateralFactor: 0.8,
  borrowCollateralFactor: 0.8,
  borrowFactor: 1,
  isolated: false
}

/**
 * Fetches liquidation threshold (=collateral factor) for given configuration
 * @param assets assets to fetch data for
 * @param chainId chainId
 * @param protocol lender
 * @returns array of collateral factors as given by the asset array
 */
export function useRiskFactors(assets: SupportedAssets[], chainId: number, protocol = Lender.AAVE_V3): {
  factors: RiskFactors[],
  isIsolated: boolean
} {
  const account = useAccount()
  const subAccountData = useGetSelectedSubAccountData(protocol, chainId, account)

  const assetsFromState: AssetConfigData[] = useGetAssetsConfigs(
    assets,
    chainId,
    protocol,
    subAccountData?.accountId
  )

  const userEMode = useUserLenderMode(protocol, chainId, account)
  const isIsolated = assetsFromState.some(x => x.isolationFlag)

  if (protocol === Lender.AAVE_V3) return {
    factors: assetsFromState.map((a) => {
      return getAaveFactors(
        a.collateralActive,
        a.config,
        account,
        userEMode,
        a.isolationFlag,
        isIsolated
      )
    }), isIsolated
  }
  if (protocol === Lender.INIT) return {
    factors: assetsFromState.map((a) => {
      const config = a?.config?.[subAccountData?.mode ?? 1]
      if (!config) return DEFAULT_FACTORS
      return config
    }),
    isIsolated: false
  }
  return {
    factors: assetsFromState.map((a) => {
      const config = a?.config?.[LENDER_MODE_NO_MODE]
      if (!config) return DEFAULT_FACTORS
      return config
    }),
    isIsolated: false
  }
}

const getAaveFactors = (
  active: boolean,
  config: LenderConfigMap | undefined,
  account: string | undefined,
  userEMode: number,
  assetIsIsolated = false,
  isIsolationMode = false
): RiskFactors => {
  const collateralActive = account ? Boolean(active) : true
  const eModeInactive = !userEMode || userEMode == 0 || !Object.values(config ?? {})?.map(
    modes => modes.modeId
  ).includes(userEMode)
  if (!config) return DEFAULT_FACTORS
  if (collateralActive && isIsolationMode) { // this is the enabled isolated asset
    if (eModeInactive)
      return {
        collateralFactor: Number(config?.[LENDER_MODE_NO_MODE]?.collateralFactor ?? 0),
        borrowFactor: 1,
        borrowCollateralFactor: Number(config?.[LENDER_MODE_NO_MODE]?.borrowCollateralFactor ?? 0),
      }
    return {
      collateralFactor: Number(config?.[userEMode]?.collateralFactor ?? 0),
      borrowFactor: 1,
      borrowCollateralFactor: Number(config?.[userEMode]?.borrowCollateralFactor ?? 0),
    }
  } else if (isIsolationMode) { // only isolation mode, but not the isolated asset
    return DEFAULT_FACTORS
  } else { // not isolated
    if (assetIsIsolated) return DEFAULT_FACTORS // target is isolated
    if (eModeInactive)
      return {
        collateralFactor: Number(config?.[LENDER_MODE_NO_MODE]?.collateralFactor ?? 0),
        borrowFactor: 1,
        borrowCollateralFactor: Number(config?.[LENDER_MODE_NO_MODE]?.borrowCollateralFactor ?? 0),
      }
    return {
      collateralFactor: Number(config?.[userEMode]?.collateralFactor ?? 0),
      borrowFactor: 1,
      borrowCollateralFactor: Number(config?.[userEMode]?.borrowCollateralFactor ?? 0),
    }
  }
}

/**
 * Check if the user allows delegation of withdrawals and borrows on Compound V3
 * @param chainId chainId
 * @param asset asset to check
 * @param baseAsset comet base asset
 * @returns 
 */
export const useCompoundV3IsAllowed = (chainId: number, baseAsset: SupportedAssets): boolean => {
  const isAllowed = useAppSelector((state) => state.compoundV3[chainId]?.isAllowed?.[baseAsset])
  return Boolean(isAllowed)
}

/**
 * Check if the user allows delegation of withdrawals and borrows on Compound V3
 * @param chainId chainId
 * @param asset asset to check
 * @param baseAsset comet base asset
 * @returns 
 */
export const useInitIsAllowed = (chainId: number, positionId?: string): boolean => {
  const data = useAppSelector((state) => state.init?.[chainId]?.userConfigs?.[positionId ?? ''])
  return (!data || !positionId) ? false : data.isAllowed || (data.isApprovedForAll === initBrokerAddresses[chainId])
}

/**
 * Fetches user data for a lender and chainId - designed to be used after a trade execution is completed
 * @param lendingProtocol selected lender
 * @param chainId chainId
 * @param account user / smart wallet address
 * @param assets assets to fetch data for
 * @returns a hook that fetches user data in the given configuration
 */
export const useFetchLenderUserData = (lendingProtocol: Lender, chainId: number | undefined, account: string | undefined, baseAsset = SupportedAssets.USDCE) => {
  const dispatch = useAppDispatch()
  return useCallback(() => {
    if (account && chainId)
      switch (lendingProtocol) {
        case Lender.AAVE_V3: {
          dispatch(
            fetchAaveV3UserData({
              chainId,
              account
            }))
          break;
        }
        case Lender.AAVE_V2: {
          dispatch(
            fetchAaveV2UserData({
              chainId,
              account
            }))
          break;
        }
        case Lender.LENDLE: {
          dispatch(
            fetchLendleUserData({
              chainId,
              account
            }))
          break;
        }
        case Lender.MERIDIAN: {
          dispatch(
            fetchMeridianUserData({
              chainId,
              account
            }))
          break;
        }
        case Lender.INIT: {
          dispatch(
            fetchInitUserData({
              chainId,
              account
            }))
          break;
        }
        case Lender.COMPOUND_V2: {
          dispatch(
            fetchCompoundV2UserData({
              chainId,
              accounts: account ? [account] : [],
              assetIds: [],
            })
          )
          break;
        }
        case Lender.VENUS: {
          dispatch(
            fetchVenusUserData({
              chainId,
              accounts: account ? [account] : [],
              assetIds: [],
            })
          )
          break;
        }
        case Lender.COMPOUND_V3: {
          dispatch(
            fetchCompoundV3UserData({
              chainId,
              account
            })
          )
          break;
        }
      }
  },
    [lendingProtocol, account, chainId])
}

/**
 * Gets the current eMode/mode index for the user
 * @param chainId chainId
 * @param account user address
 * @returns eMode index - defaults to 0 if no account provided
 */
export const useUserLenderMode = (protocol: Lender, chainId: number, account: string | undefined) => {
  return useAppSelector((state) => state[lenderToReduxSlice(protocol)]?.[chainId]?.userConfigs?.[account ?? '']?.selectedMode ?? 0)
}

/**
 * Get all available eModes or undefined if chain not supported
 * This is for Aave V3 only
 * @param chainId chainId
 * @returns undefined if chain not supported by Aave, otherwise a dictionary that maps eMode index to eMode data
 */
export const useEModes = (chainId: number, lender: Lender): { [id: number]: ModeBase } => {
  const data = useAppSelector((state) => state[lenderToReduxSlice(lender)][chainId]?.selectedConfig?.data)
  // we deterministically populate INIT
  if (lender === Lender.INIT) {
    return Object.assign({}, ...INIT_MODES.map(id => { return { [id]: { category: id, label: INIT_EMODE_LABELS[id] ?? 'Default' } } }))
  }
  return data
}

/**
 * Gets the hook that submits a transaction for eMode change - pre-checks have to be made to 
 * ensure correct execution
 * @param chainId chainId
 * @param account user address
 * @param lender lender enum -> either Aave V3 or INIT Capital
 * @param subAccount sub account posId for INIT, ignored for Aave
 * @returns transaction hook
 */
export const useSelectEMode = (chainId: number, account: string | undefined, lender = Lender.AAVE_V3, subAccount = '') => {
  const { sendTxn, waitForTxn } = useSendEVMTransaction()
  const addTransaction = useTransactionAdder()
  const eModes = useEModes(chainId, lender)

  if (!account) return (_: number) => null

  return async (nr: number) => {
    const eModeLabel = eModes[nr].label
    try {
      const transaction = getEmodeSwitchTransaction(
        chainId,
        account,
        lender,
        nr,
        subAccount
      )
      if (!transaction) return;
      let tx = await sendTxn(transaction)
      addTransaction(tx, {
        type: TransactionType.SWITCH_EMODE,
        lender: Lender.AAVE_V3,
        eMode: nr,
        label: eModeLabel
      })
      trackEModeSwitched({
        [Property.TX_HASH]: tx.hash,
        [Property.LENDER]: Lender.AAVE_V3,
        [Property.EMODE_ID]: nr.toString(),
        [Property.EMODE_LABEL]: eModeLabel,
      })
      await waitForTxn(tx.hash)
    } catch (e) {
      console.log("error switching EMode", e, nr)
    }
  }
}

function getEmodeSwitchTransaction(
  chainId: number,
  account: string,
  lender: Lender,
  mode: number,
  posId: bigint | string | undefined
) {

  if (isAaveV3Type(lender)) {
    return createEVMTxn(
      getAaveTypePoolAddress(chainId, lender),
      account,
      AaveV3Lending.encodeSetUserEMode(mode)
    )
  }
  if (isInit(lender)) {
    return createEVMTxn(
      getInitCoreAddress(chainId),
      account,
      InitCore.encodeSetPosMode(posId!, mode)
    )
  }
  return undefined
}

export enum AllowanceMode {
  DIRECT,
  WITHDRAW,
  BORROW_VARIABLE,
  BORROW_STABLE,
  NONE
}

/**
 * Fetch Aave V2 style tokenized allowances
 * @param chainId 
 * @param accountKey sub account Id or account
 * @param asset underlying
 * @param lender lender id
 * @param mode allowance type (borrow, borrow stable, withdraw, deposit)
 * @returns stringified BigNumber 
 */
export const useLenderTokenAllowance = (
  chainId: number | undefined,
  accountKey: string | undefined,
  asset: string | undefined,
  lender: Lender,
  mode = AllowanceMode.WITHDRAW
): string => {
  const allowanceData = useAppSelector(state => state[lenderToReduxSlice(lender)]?.[chainId ?? 0]?.userData?.[accountKey ?? '']?.[asset ?? SupportedAssets.USDCE])
  return useMemo(() => {
    if (!asset || !allowanceData || !isAaveType(lender)) return '0'
    switch (mode) {
      case AllowanceMode.BORROW_VARIABLE:
        return allowanceData?.allowanceBorrowingVariable ?? '0'
      case AllowanceMode.BORROW_STABLE:
        return allowanceData?.allowanceBorrowingStable ?? '0'
      case AllowanceMode.WITHDRAW:
        return allowanceData?.allowanceWithdrawal ?? '0'
      case AllowanceMode.DIRECT:
        return allowanceData?.allowanceDepositDirect ?? '0'
      default:
        return '0'
    }
  },
    [allowanceData, asset, lender, mode, chainId]
  )
}