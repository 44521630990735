import { colorPaths } from "./colorPaths";
import { primitives } from "./primitives";

export const colorPathsLight: colorPaths = {
  "Surface/Page-background": primitives["Colors/Whites/0"],
  "Surface/Surface-pre-primary": primitives["Colors/Whites/200"],
  "Surface/Surface-primary": primitives["Colors/Whites/100"],
  "Surface/Surface-primary 2": primitives["Colors/Whites/200"],
  "Surface/Surface-secondary": primitives["Colors/Whites/200"],
  "Surface/Surface-tertiary": primitives["Colors/Whites/300"],
  "Surface/Surface-action-panel-card-surface": primitives["Colors/Whites/200"],

  "Text/Headings & Titles/Title-text": primitives["Colors/Grayscale/500"],
  "Text/Subheadings & Sub Titles/Sub-headings": primitives["Colors/Grayscale/300"],

  "Text/Lables/Label-text-default": primitives["Colors/Grayscale/300"],
  "Text/Lables/Label-text-hover": primitives["Colors/Grayscale/300"],
  "Text/Lables/Label-text-active": primitives["Colors/Grayscale/300"],
  "Text/Lables/Label-text-disabled": primitives["Colors/Grayscale/200"],
  "Text/Lables/Lable-warning": primitives["Colors/Accent 2/500"],
  "Text/Lables/Lable-success": primitives["Colors/Greens/500"],
  "Text/Lables/Lable-error": primitives["Colors/Accent 3/500"],

  "Button/Button Primary/Button-primary-default": primitives["Colors/Greens/500"],
  "Button/Button Primary/Button-primary-hover": primitives["Colors/Greens/300"],
  "Button/Button Primary/Button-primary-active": primitives["Colors/Greens/500"],
  "Button/Button Primary/Button-primary-disabled": primitives["Colors/Grayscale/100"],
  "Button/Button Primary/Button-primary-text-default": primitives["Colors/Grayscale/500"],
  "Button/Button Primary/Button-primary-text-hover": primitives["Colors/Grayscale/400"],
  "Button/Button Primary/Button-primary-border-active": primitives["Colors/Grayscale/600"],

  "Button/Button Secondary/Button-secondary-default": primitives["Colors/Whites/0"],
  "Button/Button Secondary/Button-secondary-hover": primitives["Colors/Whites/0"],
  "Button/Button Secondary/Button-secondary-active": primitives["Colors/Whites/0"],
  "Button/Button Secondary/Button-secondary-text-default": primitives["Colors/Grayscale/500"],
  "Button/Button Secondary/Button-secondary-text-hover": primitives["Colors/Grayscale/400"],
  "Button/Button Secondary/Button-secondary-border-default": primitives["Colors/Grayscale/100"],
  "Button/Button Secondary/Button-secondary-border-hover": primitives["Colors/Grayscale/200"],
  "Button/Button Secondary/Button-secondary-border-active": primitives["Colors/Grayscale/500"],

  "Button/Button Tertiary/Button-tertiary-text-default": primitives["Colors/Grayscale/500"],
  "Button/Button Tertiary/Button-tertiary-text-hover": primitives["Colors/Grayscale/300"],
  "Button/Button Tertiary/Button-tertiary-text-active": primitives["Colors/Grayscale/500"],

  "Navigation/Navigation-text-inactive": primitives["Colors/Grayscale/300"],
  "Navigation/Navigation-text-hover": primitives["Colors/Grayscale/400"],
  "Navigation/Navigation-text-active": primitives["Colors/Grayscale/600"],

  "Inputs/Input Fields/Input-text": primitives["Colors/Whites/100"],
  "Inputs/Checkmark/Primary-Checkmark-default": primitives["Colors/Grayscale/500"],
  "Inputs/Checkmark/Primary-Checkmark-Icon-default": primitives["Colors/Whites/0"],
  "Inputs/Radio Buttons/Primary-radio-default": primitives["Colors/Grayscale/300"],

  "Borders/Border-primary": primitives["Colors/Whites/200"],
  "Borders/Border-secondary": primitives["Colors/Whites/400"],
  "Borders/Border-tertiary": primitives["Colors/Whites/900"],
  "Borders/Border-accordion": primitives["Colors/Whites/300"],

  "Icons/Icon-default": primitives["Colors/Grayscale/500"],

  "Notifications/Background": primitives["Colors/Accent 1/500"],
  "Notifications/Number": primitives["Colors/Grayscale/900"],

  "Transactions/Pending/Label": primitives["Colors/Accent 1/500"],
  "Transactions/Warning/Date": primitives["Colors/Grayscale/300"],
  "Transactions/Error/Date": primitives["Colors/Grayscale/300"],

  "Slider/Text": primitives["Colors/Grayscale/250"],

  "SubAccount/Label": primitives["Colors/Accent 1/400"],
}
