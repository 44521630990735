import { Lender } from "types/lenderData/base";
import { Call } from "utils/multicall";
import { INIT_MODES } from "constants/chains";
import { getInitLensAddress } from "hooks/1delta/addressesInit";

export const buildInitStyleLenderReserveCall = (chainId: number, lender: Lender) => {
  switch (lender) {
    case Lender.INIT: {
      const lensAddress = getInitLensAddress(chainId)

      const calls: Call[] = INIT_MODES.map((mode) => {
        return {
          address: lensAddress,
          name: 'getMarketData',
          params: [mode],
        }
      })

      return calls
    }
    default: return []
  }
}