import { Connector } from '@web3-react/types'
import { getIsCoinbaseWallet, getIsInjected, getIsMetaMaskWallet } from 'connection/utils'
import usePrevious from 'hooks/usePrevious'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { updateConnectionError } from 'state/connection/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateSelectedWallet } from 'state/user/reducer'
import { useConnectedWallets } from 'state/wallets/hooks'
import { isMobile } from 'utils/userAgent'
import { CoinbaseWalletOption, OpenCoinbaseWalletOption } from './CoinbaseWalletOption'
import { InjectedOption, InstallMetaMaskOption, MetaMaskOption } from './InjectedOption'
import PendingView from './PendingView'
import { getConnection, getConnectionName } from 'connection'
import { WalletConnectOption } from './WalletConnectOption'
import { UniswapOption } from './UniswapOption'
import { ParticleWalletOption } from './ParticleWalletOption'
import { trackWalletConnected } from 'utils/analytics'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { Box, Card, Divider, HStack, Link, Text, VStack } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import AuthenticatedHeader from './AuthenticatedHeader'
import { ModalCloseButton } from 'components/Button/Action/ModalCloseButton'
import { useWalletMenuDisclosure } from 'state/application/hooks'
import { useIsMobile } from 'hooks/useIsMobile'
import { Settings } from 'components/Menu/Settings/GeneralSettings'
import { useUserTheme } from 'state/user/hooks'
import { arrowRightIcon } from 'constants/1delta'
import { IconButtonWithTooltip } from 'components/Button/Action/IconButtonWithTooltip'
import { FadeInAnimation } from 'components/PageLayout'
import { SettingsButton } from './SettingsButton'

const WALLET_VIEWS = {
  OPTIONS: 'options',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

export default function WalletMenu() {
  const dispatch = useAppDispatch()
  const { connector, account, chainId } = useWeb3ReactWrapped()
  const previousAccount = usePrevious(account)

  const [connectedWallets, addWalletToConnectedWallets] = useConnectedWallets()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  const [lastActiveWalletAddress, setLastActiveWalletAddress] = useState<string | undefined>(account)

  const [userJustConnected, setUserJustConnected] = useState(false)

  const [pendingConnector, setPendingConnector] = useState<Connector | undefined>()

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const pendingError = useAppSelector((state) =>
    pendingConnector ? state.connection.errorByConnectionType[getConnection(pendingConnector).type] : undefined
  )

  const openOptions = useCallback(() => {
    setWalletView(WALLET_VIEWS.OPTIONS)
  }, [setWalletView])

  useEffect(() => {
    if (pendingConnector && walletView !== WALLET_VIEWS.PENDING) {
      updateConnectionError({ connectionType: getConnection(pendingConnector).type, error: undefined })
      setPendingConnector(undefined)
    }
  }, [pendingConnector, walletView])

  // When new wallet is successfully set by the user, trigger logging of Amplitude analytics event.
  useEffect(() => {
    if (account && account !== lastActiveWalletAddress) {
      const walletType = getConnectionName(connector)
      const isReconnect =
        connectedWallets.filter((wallet) => wallet.account === account && wallet.walletType === walletType).length > 0
      if (!isReconnect) addWalletToConnectedWallets({ account, walletType })
    }
    setLastActiveWalletAddress(account)
  }, [connectedWallets, addWalletToConnectedWallets, lastActiveWalletAddress, account, connector, chainId])

  useEffect(() => {
    if (userJustConnected && account) {
      trackWalletConnected(account)
    }
    setUserJustConnected(false)
  }, [userJustConnected])

  useEffect(() => {
    if (account) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [account])

  const { onCloseWalletMenu } = useWalletMenuDisclosure()
  const sizeIsMobile = useIsMobile()

  const theme = useUserTheme()

  const tryActivation = useCallback(
    async (connector: Connector) => {
      const connectionType = getConnection(connector).type

      // @ts-ignore:disable-next-line
      gtag('event', 'connect_wallet')
      try {
        setPendingConnector(connector)
        setWalletView(WALLET_VIEWS.PENDING)
        dispatch(updateConnectionError({ connectionType, error: undefined }))

        await connector.activate()

        dispatch(updateSelectedWallet({ wallet: connectionType }))
        setUserJustConnected(true)
      } catch (error) {
        console.debug(`web3-react connection error: ${error}`)
        dispatch(updateConnectionError({ connectionType, error: error.message }))
      }
    },
    [dispatch]
  )

  const getOptions = useCallback(() => {
    const isInjected = getIsInjected()
    const isMetaMask = getIsMetaMaskWallet()
    const isCoinbaseWallet = getIsCoinbaseWallet()

    const isCoinbaseWalletBrowser = isMobile && isCoinbaseWallet
    const isMetaMaskBrowser = isMobile && isMetaMask
    const isInjectedMobileBrowser = isCoinbaseWalletBrowser || isMetaMaskBrowser

    let injectedOption
    if (!isInjected) {
      if (!isMobile) {
        injectedOption = <InstallMetaMaskOption />
      }
    } else if (!isCoinbaseWallet) {
      if (isMetaMask) {
        injectedOption = <MetaMaskOption tryActivation={tryActivation} />
      } else {
        injectedOption = <InjectedOption tryActivation={tryActivation} />
      }
    }

    let coinbaseWalletOption
    if (isMobile && !isInjectedMobileBrowser) {
      coinbaseWalletOption = <OpenCoinbaseWalletOption />
    } else if (!isMobile || isCoinbaseWalletBrowser) {
      coinbaseWalletOption = <CoinbaseWalletOption tryActivation={tryActivation} />
    }

    const walletConnectionOption =
      (!isInjectedMobileBrowser && <WalletConnectOption tryActivation={tryActivation} />) ?? null
    // @ts-ignore
    const uniswapOption = <UniswapOption tryActivation={tryActivation} /> ?? null

    const particleOption = <ParticleWalletOption tryActivation={tryActivation} />

    return (
      <>
        {injectedOption}
        {coinbaseWalletOption}
        {walletConnectionOption}
        {uniswapOption}
        {particleOption}
      </>
    )
  }, [tryActivation])

  const onOpenSettings = () => setIsSettingsOpen(!isSettingsOpen)

  const Content = useCallback(() => {
    if (!isSettingsOpen && account && chainId && connector && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AuthenticatedHeader
          openOptions={openOptions}
          account={account}
          chainId={chainId}
          connector={connector}
          onOpenSettings={onOpenSettings}
        />
      )
    }

    let headerRow
    if (!isSettingsOpen && account && (walletView === WALLET_VIEWS.PENDING || walletView === WALLET_VIEWS.ACCOUNT)) {
      headerRow = (
        <HStack padding="1rem 1rem" font-weight={500}>
          <Text
            textDecoration="none"
            color={validateColor('Text/Lables/Label-text-default')}
            display="flex"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => setWalletView(account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS)}
          >
            <ArrowLeft />
          </Text>
        </HStack>
      )
    } else {
      headerRow = (
        <VStack w="100%" gap="0">
          <HStack width="100%" justifyContent="space-between">
            <HStack w="auto" gap={isSettingsOpen ? '0.5rem' : '0'}>
              {isSettingsOpen && (
                <IconButtonWithTooltip
                  imageProps={{ w: '1.3rem', h: '1.3rem', transform: 'rotate(180deg)' }}
                  src={arrowRightIcon[theme]}
                  onClick={() => setIsSettingsOpen(false)}
                />
              )}
              <Text
                style={getTypography('Typography/Captions/Normal/Caption 2')}
                color={validateColor('Text/Headings & Titles/Title-text')}
              >
                {isSettingsOpen ? 'Settings' : 'Connect a Wallet'}
              </Text>
            </HStack>
            {sizeIsMobile && <ModalCloseButton onClick={onCloseWalletMenu} />}
          </HStack>
          <Box w="100%" py="1rem">
            <Divider borderColor={validateColor('Borders/Border-secondary')} opacity={1} />
          </Box>
        </VStack>
      )
    }

    function getTermsOfService() {
      return (
        <Card width="100%" padding="1rem" background={validateColor('Surface/Surface-primary 2')}>
          <HStack style={{ flexWrap: 'wrap' }}>
            <Text fontSize={12} color={validateColor('Text/Lables/Label-text-disabled')}>
              By connecting a wallet, you agree to our{' '}
              <Link style={{ textDecoration: 'underline' }} href="https://1delta.io/terms/" target="_blank">
                Terms of Service
              </Link>
              .
            </Text>
          </HStack>
        </Card>
      )
    }

    return (
      <VStack
        whiteSpace="wrap"
        position="relative"
        padding="1.5rem"
        gap="0"
        w="100%"
        alignItems="flex-start"
        height="100%"
        css={FadeInAnimation}
      >
        {headerRow}
        <VStack gap="1rem" height={'100%'} justifyContent="space-between" w="100%">
          {!account && walletView === WALLET_VIEWS.PENDING && pendingConnector && (
            <PendingView
              openOptions={openOptions}
              connector={pendingConnector}
              error={!!pendingError}
              tryActivation={tryActivation}
            />
          )}
          {!isSettingsOpen && walletView !== WALLET_VIEWS.PENDING && (
            <VStack w="100%" gap="0.5rem" data-testid="option-grid">
              {getOptions()}
            </VStack>
          )}
          {isSettingsOpen && <Settings theme={theme} />}
          <HStack>
            {!isSettingsOpen && !pendingError && getTermsOfService()}
            {!isSettingsOpen && <SettingsButton onOpenSettings={onOpenSettings} />}
          </HStack>
        </VStack>
      </VStack>
    )
  }, [
    account,
    chainId,
    connector,
    walletView,
    isSettingsOpen,
    pendingConnector,
    pendingError,
    theme,
    sizeIsMobile,
    onCloseWalletMenu,
  ])

  const walletOptions = useMemo(() => getOptions(), [getOptions])

  return <Content />
}
