import { addressesCompoundV3Core } from "hooks/1delta/addressesCompoundV3";
import { getCompoundV3TokenAddresses } from "hooks/lenders/lenderAddressGetter";
import { SupportedAssets } from "types/1delta";
import { Lender } from "types/lenderData/base";
import { Call } from "utils/multicall";

export const buildCompoundV3StyleLenderReserveCall = (chainId: number, lender: Lender) => {
  switch (lender) {
    case Lender.COMPOUND_V3: {
      const baseAsset = SupportedAssets.USDCE
      const rawAddressDict = getCompoundV3TokenAddresses(chainId)
      const cometContractAddress = addressesCompoundV3Core.comet[chainId]?.[baseAsset]
      const assetsToQuery = Object.keys(rawAddressDict)
      const tokensNoBase = assetsToQuery.filter(a => a !== baseAsset)

      const callsAssetInfoAndTotals: Call[] = tokensNoBase.flatMap((tk) => {
        return [
          {
            address: cometContractAddress,
            name: 'getAssetInfoByAddress',
            params: [rawAddressDict[tk]],
          },
          {
            address: cometContractAddress,
            name: 'totalsCollateral',
            params: [rawAddressDict[tk]],
          }
        ]
      })

      const callSupplyRewards: Call = {
        address: cometContractAddress,
        name: 'baseTrackingSupplySpeed',
        params: [],
      }

      const callBorrowRewards: Call = {
        address: cometContractAddress,
        name: 'baseTrackingBorrowSpeed',
        params: [],
      }

      const callIndexScale: Call = {
        address: cometContractAddress,
        name: 'baseIndexScale',
        params: [],
      }

      const callSupply: Call = {
        address: cometContractAddress,
        name: 'totalSupply',
        params: [],
      }

      const callBorrow: Call = {
        address: cometContractAddress,
        name: 'totalBorrow',
        params: [],
      }

      const callInterest: Call = {
        address: addressesCompoundV3Core.irGetter[chainId],
        name: 'getCometInterest',
        params: [cometContractAddress],
      }
      const calls = [
        ...callsAssetInfoAndTotals,
        callSupplyRewards,
        callBorrowRewards,
        callIndexScale, // rewards data
        callSupply,
        callBorrow,  // supply / borrow
        callInterest
      ]
      return calls
    }
    default: return []
  }
}