import { SerializedBigNumber } from "types/1delta";
import { RewardsMap, UserRewardEntry } from "types/lenderData/base";

export enum AaveV2TypeGetReserveDataIndexes {
  availableLiquidity = 0,
  totalStableDebt,
  totalVariableDebt,
  liquidityRate,
  variableBorrowRate,
  stableBorrowRate,
  averageStableBorrowRate,
  liquidityIndex,
  variableBorrowIndex,
  lastUpdateTimestamp,
}

export enum AaveV2TypeGetReserveConfigurationData {
  decimals = 0,
  ltv,
  liquidationThreshold,
  liquidationBonus,
  reserveFactor,
  usageAsCollateralEnabled,
  borrowingEnabled,
  stableBorrowRateEnabled,
  isActive,
  isFrozen,
}

export enum AaveV2TypeGetUserReserveData {
  currentATokenBalance = 0,
  currentStableDebt,
  currentVariableDebt,
  principalStableDebt,
  scaledVariableDebt,
  stableBorrowRate,
  liquidityRate,
  stableRateLastUpdated,
  usageAsCollateralEnabled,
}


export enum AaveV2TypeIncentivesControllerPoolInfoIndexes {
  totalSupply = 0,
  allocPoint,
  lastRewardTime,
  accRewardPerShare,
  onwardIncentives,
}



export interface AaveV2GeneralPublicResponse {
  data: {
    [tokenSymbol: string]: {
      // token amounts
      totalDeposits: number;
      totalDebtStable: number;
      totalDebt: number;
      // USD amounts
      totalDepositsUSD: number;
      totalDebtStableUSD: number;
      totalDebtUSD: number;

      // reserve market data
      depositRate: number
      variableBorrowRate: number
      stableBorrowRate: number
      lastUpdateTimestamp?: number
      stakingYield: number

      // rewards
      rewards?: RewardsMap

      // reserve config
      decimals?: number
      usageAsCollateralEnabled?: boolean
      hasStable?: boolean

      // frozen
      isActive?: boolean
      isFrozen?: boolean
    }
  }
  config: {
    [tokenSymbol: string]: {
      decimals: number;

      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: number
        }
      }

      // flags
      collateralActive: boolean;
      borrowingEnabled: boolean;
      hasStable: boolean;
      isActive: boolean;
      isFrozen: boolean;
    }
  }
  chainId: number
}


export interface AaveV2UserReserveResponse {
  chainId: number
  account: string
  tokensData: {
    [tokenSymbol: string]: {
      // token amounts
      deposits: number
      debt: number
      debtStable: number
      // usd amounts
      depositsUSD: number
      debtUSD: number
      debtStableUSD: number

      collateralActive: boolean
      claimableRewards: number
    }
  },
  rewards: UserRewardEntry
  allowances: { [asset: string]: AllowanceData }
}

export interface AllowanceData {
  allowanceDepositDirect: SerializedBigNumber
  allowanceWithdrawal: SerializedBigNumber
  allowanceBorrowingVariable: SerializedBigNumber
  allowanceBorrowingStable: SerializedBigNumber
}
