import { zeroAddress } from 'viem'
import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesTakoTakoCore: AddressDictionary = {
  AaveOracle: {
    [SupportedChainId.TAIKO]: '0x4Feb232c357F5Fb11A6Ff43FC46808BebC2f55b5'
  },
  PoolProxy: {
    [SupportedChainId.TAIKO]: '0x3A2Fd8a16030fFa8D66E47C3f1C0507c673C841e'
  },
  PoolDataProvider: {
    [SupportedChainId.TAIKO]: '0x3bD0baC54f4Ca268Fc03a4DdcF9d292cbF8D2AF0'
  },
  IncentivesController: {
    [SupportedChainId.TAIKO]: '0x'
  },
  MultiFeeDistribution: {
    [SupportedChainId.TAIKO]: '0x'
  },
}

export const addressesTakoTakoATokens: AddressDictionary = {
  [SupportedAssets.USDC]: {
    [SupportedChainId.TAIKO]: '0x79a741ebfe9c323cf63180c405c050cdd98c21d8'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.TAIKO]: '0x7945f98240b310bd21f8814bdceeba6775a9a36a'
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: '0xbbfa45a92d9d071554b59d2d29174584d9b06bc3'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.TAIKO]: '0x6afa285ab05657f7102f66f1b384347aef3ef6aa'
  },
}

export const addressesTakoTakoVTokens: AddressDictionary = {
  [SupportedAssets.USDC]: {
    [SupportedChainId.TAIKO]: '0x72c6bdf69952b6bc8acc18c178d9e03eac5ead50'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.TAIKO]: '0x820c66d8316856655adb42b3b6cb6a1728d29567'
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: '0x0f0244337f1215e6d8e13af1b5ae639244d8a6f6'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.TAIKO]: '0x19871b9911ddbd422e06f66427768f9b65d36f81'
  },
}

export const addressesTakoTakoSTokens: AddressDictionary = {
  [SupportedAssets.USDC]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
}
