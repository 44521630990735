import { getLenderAssets } from "constants/getAssets";
import { getAaveTypePoolAddress, getAaveTypePoolDataProviderAddress } from "hooks/1delta";
import { getLenderTokenAddresses } from "hooks/lenders/lenderAddressGetter";
import { Lender } from "types/lenderData/base";
import { Call } from "utils/multicall";

export const AAVE_V3_EMODES = [0, 1, 2, 3, 4]

export const buildAaveV3StyleLenderReserveCall = (chainId: number, lender: Lender) => {
  const providerAddress = getAaveTypePoolDataProviderAddress(chainId, lender)
  const underlyingTokens = getLenderTokenAddresses(chainId, lender)
  const assetsToQuery = getLenderAssets(chainId, lender)
  const poolAddress = getAaveTypePoolAddress(chainId, lender)
  switch (lender) {
    case Lender.AAVE_V3: {
      const getEModeCategoryData: Call[] = AAVE_V3_EMODES.flatMap((eMode) => {
        return [
          {
            address: poolAddress,
            name: 'getEModeCategoryData',
            params: [eMode],
          },
          {
            address: poolAddress,
            name: 'getEModeCategoryBorrowableBitmap',
            params: [eMode],
          },
          {
            address: poolAddress,
            name: 'getEModeCategoryCollateralBitmap',
            params: [eMode],
          }
        ]
      })

      const callDecimals: Call = {
        address: providerAddress,
        name: 'getDebtCeilingDecimals',
        params: [],
      }

      const callReservesList: Call = {
        address: poolAddress,
        name: 'getReservesList',
        params: [],
      }


      const calls = [
        ...assetsToQuery.flatMap(tk => [
          {
            address: providerAddress,
            name: 'getReserveData',
            params: [underlyingTokens[tk]],
          },
          {
            address: providerAddress,
            name: 'getReserveConfigurationData',
            params: [underlyingTokens[tk]],
          },
          {
            address: providerAddress,
            name: 'getSiloedBorrowing',
            params: [underlyingTokens[tk]],
          },
          {
            address: providerAddress,
            name: 'getReserveCaps',
            params: [underlyingTokens[tk]],
          },
          {
            address: providerAddress,
            name: 'getDebtCeiling',
            params: [underlyingTokens[tk]],
          }
        ]),
        ...getEModeCategoryData,
        callReservesList,
        callDecimals
      ]
      return calls
    }
    default: return []
  }
}