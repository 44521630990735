import { SupportedChainId } from 'constants/chains'

/**
 * Returns the gas value plus a margin for unexpected or variable gas costs
 * @param value the gas value to pad
 */
export function calculateGasMargin(value: bigint | string, chainId: SupportedChainId | undefined = undefined): bigint {
  if (!chainId)
    return BigInt(value.toString()) * 105n / 100n

  if (chainId === SupportedChainId.TAIKO) return BigInt(value.toString()) * 115n / 100n

  return BigInt(value.toString()) * 105n / 100n
}
