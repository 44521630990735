import { SerializedBigNumber, SupportedAssets } from "types/1delta";
import { RewardsMap, UserRewardEntry } from "types/lenderData/base";

export enum CometInterestRateIndexes {
  borrowRate = 0,
  supplyRate = 1,
  utilization = 2
}

export interface CompoundV3GeneralPublicResponse {
  data: {
    [tokenSymbol: string]: {

      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: 1
        }
      }

      liquidationFactor: string;
      supplyCap: number;

      // interest rates
      variableBorrowRate: number;
      depositRate: number;

      utilization: number;

      totalDebt: number;
      // deposits are different outputs for base asset and others
      totalDeposits: number;

      // rewards
      rewards?: RewardsMap
    }
  },
  chainId: number;
}

export interface CompoundV3UserReserveResponse {
  tokensData: {
    [tokenSymbol: string]: {
      // tokens
      deposits: number
      debt: number
      // dollars
      depositsUSD: number
      debtUSD: number

      collateralActive: boolean
      // user collateral raw (to withdraw max)
      depositRaw: SerializedBigNumber;
    }
  },
  isAllowed: boolean
  chainId: number
  baseAsset: SupportedAssets
  rewards: UserRewardEntry
  account: string
}