import { SupportedAssets } from 'types/1delta'
import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { Lender, UserRewardEntry } from 'types/lenderData/base'
import { AaveV2TypeGetUserReserveData, AaveV2UserReserveResponse, AllowanceData } from './types'
import { getLenderAssets } from 'constants/getAssets'
import { AURELIUS_REWARD_ASSETS } from 'constants/lenderRewardAssets'


export const getAaveV2UserDataConverter = (
  lender: Lender,
  chainId: number,
  account: string,
  prices: { [asset: string]: number }
): [(data: any[]) => AaveV2UserReserveResponse | undefined, number] => {
  switch (lender) {
    case Lender.AURELIUS: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 5 + 1
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const tokensData: { [asset: string]: any } = {}
          const allowances: { [asset: string]: AllowanceData } = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 5]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const price = prices[asset] ?? 1

            const currentATokenBalance = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentATokenBalance].toString(), decimals)
            const currentStableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentStableDebt].toString(), decimals)
            const currentVariableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentVariableDebt].toString(), decimals)

            tokensData[asset] = {
              deposits: currentATokenBalance,
              debtStable: currentStableDebt,
              debt: currentVariableDebt,
              depositsUSD: currentATokenBalance * price,
              debtStableUSD: currentStableDebt * price,
              debtUSD: currentVariableDebt * price,
              stableBorrowRate: reserveData[AaveV2TypeGetUserReserveData.stableBorrowRate].toString(),
              collateralActive: Boolean(reserveData[AaveV2TypeGetUserReserveData.usageAsCollateralEnabled]),
              claimableRewards: 0,
            }

            allowances[asset] = {
              allowanceDepositDirect: data[i * 5 + 1].toString(),
              allowanceWithdrawal: data[i * 5 + 2].toString(),
              allowanceBorrowingVariable: data[i * 5 + 3].toString(),
              allowanceBorrowingStable: data[i * 5 + 4].toString(),
            }
          }

          const startIndex = assetsToQuery.length * 5
          const rewardMapping = Object.fromEntries(data[startIndex][0].map((key: string, index: number) => [key.toLowerCase(), data[startIndex][1][index]]))
          const rewards: UserRewardEntry = {}
          for (let i = 0; i < AURELIUS_REWARD_ASSETS.length; i++) {
            const asset = AURELIUS_REWARD_ASSETS[i]
            const reward = parseRawAmount(rewardMapping[asset.address.toLowerCase()].toString(), asset.decimals)
            rewards[asset.symbol] = {
              totalRewards: reward,
              claimableRewards: reward
            }
          }

          return {
            chainId,
            account,
            tokensData,
            rewards,
            allowances
          }
        },
        expectedNumberOfCalls,
      ]
    }
    case Lender.LENDLE: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 5 + 2

      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const claimableRewards = data[expectedNumberOfCalls - 2]

          // map claimable rewards to tokens
          // foreach asset, we sum the l and v token rewards
          const mappedClaimableRewardsToTokens = assetsToQuery.map((name, index) => {
            return {
              [name]:
                parseRawAmount(claimableRewards[index * 2].toString(), 18) +
                parseRawAmount(claimableRewards[index * 2 + 1].toString(), 18)
            }
          })

          const tokensData: { [asset: string]: any } = {}
          const allowances: { [asset: string]: AllowanceData } = {}


          let totalClaimableLend = 0

          for (let i = 0; i < assetsToQuery.length; i++) {

            const asset = assetsToQuery[i]

            const reserveData = data[i * 5]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const price = prices[asset] ?? 1

            const currentATokenBalance = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentATokenBalance].toString(), decimals)
            const currentStableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentStableDebt].toString(), decimals)
            const currentVariableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentVariableDebt].toString(), decimals)

            totalClaimableLend += mappedClaimableRewardsToTokens[i][asset]

            tokensData[asset] = {
              deposits: currentATokenBalance,
              debtStable: currentStableDebt,
              debt: currentVariableDebt,
              depositsUSD: currentATokenBalance * price,
              debtStableUSD: currentStableDebt * price,
              debtUSD: currentVariableDebt * price,
              stableBorrowRate: reserveData[AaveV2TypeGetUserReserveData.stableBorrowRate].toString(),
              collateralActive: Boolean(reserveData[AaveV2TypeGetUserReserveData.usageAsCollateralEnabled]),
              claimableRewards: mappedClaimableRewardsToTokens[i][asset],
            }

            allowances[asset] = {
              allowanceDepositDirect: data[i * 5 + 1].toString(),
              allowanceWithdrawal: data[i * 5 + 2].toString(),
              allowanceBorrowingVariable: data[i * 5 + 3].toString(),
              allowanceBorrowingStable: data[i * 5 + 4].toString(),
            }
          }

          const rewards: UserRewardEntry = {}

          const earnedBalances = data[expectedNumberOfCalls - 1]
          const lendRewards = parseRawAmount(earnedBalances[0].toString(), 18)

          rewards[SupportedAssets.LEND] = { totalRewards: lendRewards + totalClaimableLend, claimableRewards: totalClaimableLend }

          return {
            chainId,
            account,
            tokensData,
            rewards,
            allowances
          }
        },
        expectedNumberOfCalls,
      ]
    }
    case Lender.MERIDIAN: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 5 + 2

      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const totalTaikoRewards = data[expectedNumberOfCalls - 2]
          const claimableTaikoRewards = data[expectedNumberOfCalls - 1]

          const claimableRewards = parseRawAmount(claimableTaikoRewards.toString(), 18)
          const totalRewards = parseRawAmount(totalTaikoRewards.toString(), 18)
          // map claimable rewards to tokens
          // foreach asset, we sum the l and v token rewards
          const mappedClaimableRewardsToTokens = assetsToQuery.map((name, index) => {
            return {
              [name]:
                parseRawAmount(claimableRewards[index * 2], 18) +
                parseRawAmount(claimableRewards[index * 2 + 1], 18)
            }
          })

          const tokensData: { [asset: string]: any } = {}
          const allowances: { [asset: string]: AllowanceData } = {}

          for (let i = 0; i < assetsToQuery.length; i++) {

            const asset = assetsToQuery[i]

            const reserveData = data[i * 5]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const price = prices[asset] ?? 1

            const currentATokenBalance = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentATokenBalance].toString(), decimals)
            const currentStableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentStableDebt].toString(), decimals)
            const currentVariableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentVariableDebt].toString(), decimals)

            tokensData[asset] = {
              deposits: currentATokenBalance,
              debtStable: currentStableDebt,
              debt: currentVariableDebt,
              depositsUSD: currentATokenBalance * price,
              debtStableUSD: currentStableDebt * price,
              debtUSD: currentVariableDebt * price,
              stableBorrowRate: reserveData[AaveV2TypeGetUserReserveData.stableBorrowRate].toString(),
              collateralActive: Boolean(reserveData[AaveV2TypeGetUserReserveData.usageAsCollateralEnabled]),
              claimableRewards: mappedClaimableRewardsToTokens[i][asset],
            }

            allowances[asset] = {
              allowanceDepositDirect: data[i * 5 + 1].toString(),
              allowanceWithdrawal: data[i * 5 + 2].toString(),
              allowanceBorrowingVariable: data[i * 5 + 3].toString(),
              allowanceBorrowingStable: data[i * 5 + 4].toString(),
            }
          }

          const rewards: UserRewardEntry = {}

          rewards[SupportedAssets.TAIKO] = {
            totalRewards,
            claimableRewards
          }

          return {
            chainId,
            account,
            tokensData,
            rewards,
            allowances
          }
        },
        expectedNumberOfCalls,
      ]
    }
    case Lender.TAKOTAKO:
    case Lender.AAVE_V2: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 5
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const tokensData: { [asset: string]: any } = {}
          const allowances: { [asset: string]: AllowanceData } = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 5]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const price = prices[asset] ?? 1

            const currentATokenBalance = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentATokenBalance].toString(), decimals)
            const currentStableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentStableDebt].toString(), decimals)
            const currentVariableDebt = parseRawAmount(reserveData[AaveV2TypeGetUserReserveData.currentVariableDebt].toString(), decimals)

            tokensData[asset] = {
              deposits: currentATokenBalance,
              debtStable: currentStableDebt,
              debt: currentVariableDebt,
              depositsUSD: currentATokenBalance * price,
              debtStableUSD: currentStableDebt * price,
              debtUSD: currentVariableDebt * price,
              stableBorrowRate: reserveData[AaveV2TypeGetUserReserveData.stableBorrowRate].toString(),
              collateralActive: Boolean(reserveData[AaveV2TypeGetUserReserveData.usageAsCollateralEnabled]),
              claimableRewards: 0,
            }

            allowances[asset] = {
              allowanceDepositDirect: data[i * 5 + 1].toString(),
              allowanceWithdrawal: data[i * 5 + 2].toString(),
              allowanceBorrowingVariable: data[i * 5 + 3].toString(),
              allowanceBorrowingStable: data[i * 5 + 4].toString(),
            }
          }

          return {
            chainId,
            account,
            tokensData,
            rewards: {},
            allowances
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}