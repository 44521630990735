import { TEN } from "@1delta/base-sdk";
import { SupportedChainId } from "constants/chains";
import _ from "lodash";
import { formatEther, formatUnits, parseUnits } from "viem";

/**
 * Parses a price string to number
 * Aave has 8 decimal prices whereas Lendle uses 18 decimals
 * @param price price as serialized BigNumber
 * @param chainId chainId 
 * @returns price as number
 */
export const formatAavePrice = (price: string, chainId = SupportedChainId.POLYGON): number => {
  return Number(formatEther(BigInt(price ?? '0') * (
    // 10^(18 - decimals)
    TEN ** (chainId === SupportedChainId.MANTLE ? 0n : 10n)
  )))
}

export const parseRawAmount = (answer?: string, decimals = 18): number => {
  if (!answer || answer === "0x") return 0
  return Number(formatUnits(BigInt(answer ?? '0'), decimals ?? 18))
}

export const parseNumberToRaw = (n: number | string, decs = 18) => {
  return parseUnits(String(n), decs)
}

export const skimToBigNumber = (num: string | number, decs = 18) => {
  return parseUnits(String(_.round(Number(num), decs)?.toFixed(decs)), decs).toString()
}

export const exoticAnswerToPrice = (answer?: string, decimals?: number, answer2?: string, decimals2?: number): number => {
  return Number(formatUnits(BigInt(answer ?? '0'), decimals ?? 18)) *
    Number(formatUnits(BigInt(answer2 ?? '0'), decimals2 ?? 18))
}


// converts rate per second to rate per year
export const convertRateToApr = (ratePerSecond: number) => {
  return ratePerSecond * 3600 * 24 * 365 * 100
}