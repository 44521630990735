import { SupportedAssets } from 'types/1delta'
import { Call } from 'utils/multicall'
import { getLenderTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { FlatAddressDictionary } from 'hooks/1delta/addresses'
import { Lender } from 'types/lenderData/base'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { getLenderAssets } from 'constants/getAssets'
import { getAaveTypePoolAddress, getAaveTypePoolDataProviderAddress } from 'hooks/1delta'
import { getAaveStyleATokenMap, getAaveStyleSTokenMap, getAaveStyleVTokenMap } from 'hooks/1delta/tokens'

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

const getBaseAaveV3Calls = (
  chainId: number,
  account: string,
  lender: Lender,
  assetsToQuery: SupportedAssets[],
  aTokens: FlatAddressDictionary,
  vTokens: FlatAddressDictionary,
  sTokens: FlatAddressDictionary
) => {
  const underlyings = getLenderTokenAddresses(chainId, lender)
  const broker = ONE_DELTA_COMPOSER[chainId]
  return assetsToQuery.flatMap((tk) => [
    {
      address: getAaveTypePoolDataProviderAddress(chainId, lender),
      name: 'getUserReserveData',
      params: [underlyings[tk], account],
    },
    {
      address: underlyings[tk],
      name: 'allowance',
      params: [account, getAaveTypePoolAddress(chainId, lender)],
    },
    {
      address: aTokens[tk],
      name: 'allowance',
      params: [account, broker],
    },
    {
      address: vTokens[tk] ?? fallbackDebtToken,
      name: 'borrowAllowance',
      params: [account, broker],
    },
    {
      address: sTokens[tk] ?? fallbackDebtToken,
      name: 'borrowAllowance',
      params: [account, broker],
    },
  ])
}

export const buildAaveV3UserCall = (chainId: number, lender: Lender, account: string): Call[] => {
  switch (lender) {
    case Lender.AAVE_V3: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const aTokens = getAaveStyleATokenMap(chainId, lender)
      const vTokens = getAaveStyleVTokenMap(chainId, lender)
      const sTokens = getAaveStyleSTokenMap(chainId, lender)

      const callDataEmode = {
        address: getAaveTypePoolAddress(chainId, lender),
        name: 'getUserEMode',
        params: [account],
      }
      return [
        ...getBaseAaveV3Calls(
          chainId,
          account,
          lender,
          assetsToQuery,
          aTokens,
          vTokens,
          sTokens
        ),
        callDataEmode
      ]
    }
    default:
      return []
  }
}
