
import fetch from "axios"
import { SupportedAssets } from "types/1delta"

const prefixEthereum = 'ethereum:'
const WBTC = '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
const WETH = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
const MNT = '0x3c3a81e81dc49a522a592e7622a7e711c06bf354'
const USDE = '0x4c9edd5852cd905f086c759e8383e09bff1e68b3'
const METH = '0xd5f7838f5c461feff7fe49ea5ebaf7728bb0adfa'
const USDC = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
const USDT = '0xdac17f958d2ee523a2206206994597c13d831ec7'
// const USDY = '0x96f6ef951840721adbf46ac996b59e0235cb985c'
const CRV = '0xD533a949740bb3306d119CC777fa900bA034cd52'
const BAL = '0xba100000625a3754423978a60c9317c58a424e3D'
const AGEUR = '0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8'
const LINK = '0x514910771AF9Ca656af840dff83E8264EcF986CA'
const DAI = '0x6B175474E89094C44Da98b954EedeAC495271d0F'
const MATIC = '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'
const BNB = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52'
const STMATIC = '0x9ee91F9f426fA633d227f7a9b000E28b9dfd8599'
const MATICX = '0xf03A7Eb46d01d9EcAA104558C732Cf82f6B6B645'
const AAVE = '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9'
const COMP = '0xc00e94cb662c3520282e6f5717214004a7f26888'
const EURS = '0xdB25f211AB05b1c97D595516F45794528a807ad8'
const FBTC = '0xC96dE26018A54D51c097160568752c4E3BD6C364'
const TAIKO = '0x10dea67478c5F8C5E2D90e5E9B26dBe60c54d800'
const CMETH = '0xe6829d9a7ee3040e1276fa75293bde931859e8fa'
const AUSD = '0x00000000efe302beaa2b3e6e1b18d08d69a9012a'
const datasEthereum = [
  WBTC,
  WETH,
  MNT,
  USDC,
  USDT,
  USDE,
  METH,
  // USDY,
  CRV,
  BAL,
  AGEUR,
  LINK,
  DAI,
  BNB,
  MATIC,
  STMATIC,
  MATICX,
  AAVE,
  COMP,
  EURS,
  FBTC,
  TAIKO,
  CMETH,
  AUSD
].map(d => prefixEthereum + d).join(',')

const prefixAvalanche = 'avax:'
const WAVAX = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
const datasAvalanche = [WAVAX].map(d => prefixAvalanche + d).join(',')
const URL = `https://coins.llama.fi/prices/current/${[datasEthereum, datasAvalanche].join(',')}?searchWidth=4h`
const URL_HIST = (ref: number) => `https://coins.llama.fi/prices/historical/${ref}/${[datasEthereum, datasAvalanche].join(',')}?searchWidth=4h`

const SYMBOL_MAP = {
  'weth': SupportedAssets.WETH,
  'usde': SupportedAssets.USDE,
  'meth': SupportedAssets.METH,
  'mnt': SupportedAssets.WMNT,
  'usdc': SupportedAssets.USDCE,
  'usdt': SupportedAssets.USDT,
  'wbtc': SupportedAssets.WBTC,
  // 'usdy': SupportedAssets.USDY,
  'crv': SupportedAssets.CRV,
  'bal': SupportedAssets.BAL,
  'ageur': SupportedAssets.AGEUR,
  'link': SupportedAssets.LINK,
  'dai': SupportedAssets.DAI,
  'bnb': SupportedAssets.WBNB,
  'matic': SupportedAssets.WMATIC,
  'stmatic': SupportedAssets.STMATIC,
  'maticx': SupportedAssets.MATICX,
  'wavax': SupportedAssets.WAVAX,
  'aave': SupportedAssets.AAVE,
  'comp': SupportedAssets.COMP,
  'eurs': SupportedAssets.EURS,
  'fbtc': SupportedAssets.FBTC,
  'taiko': SupportedAssets.TAIKO,
  'cmeth': SupportedAssets.CMETH,
  'ausd': SupportedAssets.AUSD,
}

export async function fetchDefillamaData() {
  try {
    const res: any = await fetch(URL)
    const prices = Object.assign(
      {},
      ...Object.values(res.data.coins).map(
        (k: any) => { return { [SYMBOL_MAP[k.symbol.toLowerCase()]]: k.price } }
      )
    )
    return {
      prices
    }
  }
  catch (e: any) {
    console.log("error fetching prices from defillama", e)
    return {
      prices: {}
    }
  }
}

export async function fetchDefillamaHistData() {
  try {
    const nowMinus24 = Math.floor(Date.now() / 1000 - 24 * 3600)
    const resHist: any = await fetch(URL_HIST(nowMinus24))

    const histPrices = Object.assign(
      {},
      ...Object.values(resHist.data.coins).map(
        (k: any) => { return { [SYMBOL_MAP[k.symbol.toLowerCase()]]: k.price } }
      )
    )
    return {
      histPrices
    }
  }
  catch (e: any) {
    console.log("error fetching prices from defillama", e)
    return {
      histPrices: {}
    }
  }
}

