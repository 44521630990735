import { TOKEN_META } from "constants/1delta"
import { getLenderAssets } from "constants/getAssets"
import { assetToAddress } from "hooks/1delta/addressesTokens"
import { lenderToReduxSlice, toLenderAssetKey } from "hooks/lenders"
import { useWeb3ReactWrapped } from "hooks/web3"
import { useMemo } from "react"
import { useGetSelectedSubAccountData } from "state/1delta/hooks"
import { useNativeBalance } from "state/globalNetwork/hooks"
import { TokenData } from "state/globalNetwork/reducer"
import { useAppSelector } from "state/hooks"
import { useBaseAsset } from "state/margin/hooks"
import { usePrices } from "state/oracles/hooks"
import { SupportedAssets, toOracleKey } from "types/1delta"
import { EModeData } from "types/lenderData/aave-v3"
import { Lender, LenderPublicBase, LenderUserBalances, UserRewardEntry } from "types/lenderData/base"
import { SimpleAprMap } from "./useExpandAssetData"
import { formatEther, formatUnits } from "viem"

export interface UserApr {
  apr: number
  borrowApr: number
  depositApr: number
}
export enum UserAprKey {
  apr = 'apr',
  borrowApr = 'borrowApr',
  depositApr = 'depositApr',
}
export type UserAprMap = { [rewardAsset: string]: UserApr }

export interface AprData extends UserApr {
  // rewards, paid out in e.g. governance token
  rewardApr: number
  rewardBorrowApr: number
  rewardDepositApr: number
  rewards?: UserAprMap
  // staking
  stakingApr: number
  stakingBorrowApr: number
  stakingDepositApr: number
}

export function totalUserApr(map: UserAprMap | undefined, key: UserAprKey) {
  if (!map) return 0
  return Object.values(map).map(e => e[key]).reduce((a, b) => a + b, 0)
}

/** Extract a simple asset->numer map from UserAprMap by given key */
export function filterUserRewards(data: UserAprMap | undefined, rewardKey: UserAprKey, sign = 1) {
  if (!data) return {}
  let d: SimpleAprMap = {}
  Object.entries(data).map(([s, b]) => {
    d[s] = sign * (b[rewardKey] ?? 0)
  })
  return d
}

export interface BalanceData {
  borrowDiscountedCollateral: number
  collateral: number
  deposits: number
  debt: number
  adjustedDebt: number
  nav: number
  rewards?: UserRewardEntry
}

export interface PreparedAssetData extends LenderPublicBase, LenderUserBalances {
  assetId: SupportedAssets;
  price: number;

  walletBalance: number
  walletBalanceUSD: number

  /** data that some lenders do have, others don't */

  collateralActive: boolean;
  hasStable: boolean

  // eMode stuff
  eMode?: EModeData
  userEMode: number

  // ceilings / caps
  debtCeiling: number
  supplyCap?: number
  borrowCap?: number
  isFrozen?: boolean
  // lender rewards
  claimableRewards: number
}


export const NO_BALANCE = {
  borrowDiscountedCollateral: 0,
  collateral: 0,
  deposits: 0,
  debt: 0,
  adjustedDebt: 0,
  nav: 0,
  rewards: {}
}

export const NO_APR = {
  apr: 0,
  borrowApr: 0,
  depositApr: 0,
  rewardApr: 0,
  rewardBorrowApr: 0,
  rewardDepositApr: 0,
  stakingApr: 0,
  stakingBorrowApr: 0,
  stakingDepositApr: 0,
  rewards: {}
}

const generateAssetData = (
  assetList: SupportedAssets[],
  deltaAssets: any,
  userAssets: any,
  walletBalancesDict: TokenData,
  native: string | undefined,
  prices: number[],
  baseAsset: SupportedAssets,
  selectedAccount: string | undefined,
  lender: Lender
): any[] => {

  const assetData = assetList.map((assetId, index) => {
    const rawWalletBalance = walletBalancesDict[assetId]?.balance
    const walletBalance = selectedAccount ?
      (assetId === SupportedAssets.ETH || assetId === SupportedAssets.MATIC) ?
        Number(formatEther(BigInt(native ?? 0))) :
        Number(
          formatUnits(
            BigInt(rawWalletBalance ?? 0), TOKEN_META[assetId].decimals ?? 0
          )
        ) : 0
    const lenderKey = toLenderAssetKey(assetId, lender, baseAsset)
    return {
      assetId,
      price: prices[index],
      walletBalance,
      walletBalanceUSD: walletBalance * prices[index],
      ...deltaAssets[lenderKey],
      ...(userAssets?.[lenderKey] ?? {}),
    }
  })

  return assetData
}

/**
 * Enriches the asset data for frontend-use
 * @param lender the lending protocol as enum
 * @param chainId chainId
 * @param account user wallet address
 * @returns enriched asset data, balances in lender and apr data
 */
export const usePrepareLenderAssetData = (lender: Lender, chainId: number, account: string | undefined): {
  balanceData: BalanceData,
  aprData: AprData,
  assetData: PreparedAssetData[],
} => {
  const selectedAccount = useGetSelectedSubAccountData(lender, chainId, account)
  const reduxSlice = lenderToReduxSlice(lender)
  const deltaAssets = useAppSelector(state => state[reduxSlice]?.[chainId]?.lenderData)
  const userAssets = useAppSelector(state => state[reduxSlice]?.[chainId]?.userData?.[selectedAccount?.accountId ?? ''])

  const aprData = useAppSelector(state => state[reduxSlice]?.[chainId]?.aprData?.[selectedAccount?.accountId ?? '']) ?? NO_APR

  const balanceData = useAppSelector(state => state[reduxSlice]?.[chainId]?.balanceData?.[selectedAccount?.accountId ?? '']) ?? NO_BALANCE

  const walletBalancesDict = useAppSelector(state => state.globalNetwork.networkData[chainId].tokenData)
  const assetList = useMemo(() => getLenderAssets(chainId, lender), [chainId, lender])

  const native = useNativeBalance()
  const prices = usePrices(assetList)
  const baseAsset = useBaseAsset()

  const memodApr = useMemo(() => !account ? NO_APR : aprData, [aprData, account])
  const memodBalance = useMemo(() => !account ? NO_BALANCE : balanceData, [balanceData, account])

  return useMemo(() => {
    if (Object.keys(deltaAssets).length === 0) {
      return { assetData: [], balanceData: memodBalance, aprData: memodApr }
    }
    // we add wallet balances and prices to the asset data
    const assetData = generateAssetData(
      assetList,
      deltaAssets,
      userAssets,
      walletBalancesDict,
      native,
      prices,
      baseAsset,
      selectedAccount?.accountId,
      lender
    )
    return {
      assetData,
      // memorize the data 
      aprData: memodApr,
      balanceData: memodBalance
    }
  },
    [assetList, lender, selectedAccount, userAssets, deltaAssets]
  )
}

/**
 * Same as above, composes everything within `useAppSelector`
 * @param lender the lending protocol as enum
 * @param chainId chainId
 * @param account user wallet address
 * @returns enriched asset data, balances in lender and apr data
 */
export const useCompactLenderAssetData = (lender: Lender, chainId: number, account: string | undefined): {
  balanceData: BalanceData,
  aprData: AprData,
  assetData: PreparedAssetData[],
} => {
  const selectedAccount = useGetSelectedSubAccountData(lender, chainId, account)
  const reduxSlice = lenderToReduxSlice(lender)
  const assetList = useMemo(() => getLenderAssets(chainId, lender), [chainId, lender])

  const baseAsset = useBaseAsset()
  const native = useNativeBalance()

  const assetData = useAppSelector(
    state => assetList.map(a => {
      const price = state.oracles.live[toOracleKey(a)]
      const bal = state.globalNetwork.networkData[chainId]?.tokenData?.[assetToAddress(a, chainId).toLowerCase()]?.balance
      const walletBalance = selectedAccount ?
        (a === SupportedAssets.ETH || a === SupportedAssets.MATIC) ?
          Number(formatEther(BigInt(native ?? 0))) :
          Number(
            formatUnits(
              BigInt(bal ?? 0),
              TOKEN_META[a].decimals ?? 0
            )
          ) : 0
      const lenderKey = toLenderAssetKey(a, lender, baseAsset)
      return {
        assetId: a,
        price,
        walletBalance,
        walletBalanceUSD: walletBalance * price,
        ...state[reduxSlice]?.[chainId]?.lenderData?.[lenderKey],
        ...state[reduxSlice]?.[chainId]?.userData?.[selectedAccount?.accountId ?? '']?.[lenderKey]
      }
    })
  )
  const aprData = useAppSelector(state => state[reduxSlice]?.[chainId]?.aprData?.[selectedAccount?.accountId ?? '']) ?? NO_APR
  const balanceData = useAppSelector(state => state[reduxSlice]?.[chainId]?.balanceData?.[selectedAccount?.accountId ?? '']) ?? NO_BALANCE

  return useMemo(() => {
    if (!account) return { assetData, balanceData: NO_BALANCE, aprData: NO_APR }
    if (assetData.length === 0) {
      return { assetData: [], balanceData, aprData }
    }

    return {
      assetData,
      // memorize the data 
      aprData,
      balanceData
    }
  }, [assetData]
  )
}

const generateGeneralAssetData = (
  assetList: SupportedAssets[],
  deltaAssets: any,
  walletBalancesDict: TokenData,
  native: string | undefined,
  prices: number[],
  baseAsset: SupportedAssets,
  isConnected: boolean | undefined,
  lender: Lender
): any[] => {

  const assetData = assetList.map((assetId, index) => {
    const rawWalletBalance = walletBalancesDict[assetId]?.balance
    const walletBalance = isConnected ?
      (assetId === SupportedAssets.ETH || assetId === SupportedAssets.MATIC) ?
        Number(formatEther(BigInt(native ?? 0))) :
        Number(
          formatUnits(
            BigInt(rawWalletBalance ?? 0),
            TOKEN_META[assetId].decimals ?? 0
          )
        ) : 0
    const lenderKey = toLenderAssetKey(assetId, lender, baseAsset)
    return {
      assetId,
      price: prices[index],
      walletBalance,
      walletBalanceUSD: walletBalance * prices[index],
      ...deltaAssets[lenderKey],
    }
  })

  return assetData
}

const generateSubAccountAssetData = (
  assetList: SupportedAssets[],
  deltaAssets: any,
  userAssets: any,
  prices: number[],
  baseAsset: SupportedAssets,
  lender: Lender
): any[] => {

  const assetData = assetList.map((assetId, index) => {

    const lenderKey = toLenderAssetKey(assetId, lender, baseAsset)
    return {
      assetId,
      price: prices[index],
      ...deltaAssets[lenderKey],
      ...(userAssets?.[lenderKey] ?? {}),
    }
  })

  return assetData
}

/**
 * Enriches the asset data for frontend-use
 * @param lender the lending protocol as enum
 * @param chainId chainId
 * @param account user wallet address
 * @returns enriched asset data, balances in lender and apr data
 */
export const usePrepareLenderAssetDataForSubAccounts = (lender: Lender, chainId: number, subAccounts: string[]): {
  accountDatas: {
    [accountId: string]: {
      balanceData: BalanceData,
      aprData: AprData,
      assetData: PreparedAssetData[],
    }
  },
  generalData: PreparedAssetData[]
} => {
  const { account } = useWeb3ReactWrapped()
  const reduxSlice = lenderToReduxSlice(lender)
  const deltaAssets = useAppSelector(state => state[reduxSlice]?.[chainId]?.lenderData)
  const userAssets = useAppSelector(state => state[reduxSlice]?.[chainId]?.userData)

  const aprData = useAppSelector(state => state[reduxSlice]?.[chainId]?.aprData) ?? NO_BALANCE
  const balanceData = useAppSelector(state => state[reduxSlice]?.[chainId]?.balanceData) ?? NO_APR

  const walletBalancesDict = useAppSelector(state => state.globalNetwork.networkData[chainId].tokenData)
  const assetList = useMemo(() => getLenderAssets(chainId, lender), [chainId, lender])

  const native = useNativeBalance()
  const prices = usePrices(assetList)
  const baseAsset = useBaseAsset()

  const accountDatas: {
    [accountId: string]: {
      balanceData: BalanceData, aprData: AprData, assetData: PreparedAssetData[]
      accountId: string
    }
  } = {}
  const generalData = generateGeneralAssetData(
    assetList,
    deltaAssets,
    walletBalancesDict,
    native,
    prices,
    baseAsset,
    Boolean(account),
    lender
  )
  if (Object.keys(deltaAssets).length === 0) {
    subAccounts.forEach(subAccount => {
      accountDatas[subAccount] = { balanceData: NO_BALANCE, aprData: NO_APR, assetData: [], accountId: subAccount }
    })
    return { accountDatas, generalData }
  }

  subAccounts.forEach(subAccount => {
    const assetData = generateSubAccountAssetData(
      assetList,
      deltaAssets,
      userAssets[subAccount],
      prices,
      baseAsset,
      lender
    )
    accountDatas[subAccount] = {
      balanceData: balanceData[subAccount] ?? NO_BALANCE,
      aprData: aprData[subAccount] ?? NO_APR,
      assetData,
      accountId: subAccount
    }
  })

  return {
    accountDatas,
    generalData
  }
}
