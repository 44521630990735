import { getConnection } from 'connection'
import { SupportedChainId } from 'constants/chains'
import { useCallback } from 'react'
import { setToLoading } from 'state/1delta/actions'
import { updateConnectionError } from 'state/connection/reducer'
import { setAccount, setChainId, setIsLoading } from 'state/globalNetwork/actions'
import { useAppDispatch } from 'state/hooks'
import { setOraclesToLoading } from 'state/oracles/actions'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { useWeb3ReactWrapped } from './web3'
import { useToast } from '@chakra-ui/react'
import { getChainInfo } from 'constants/chainInfo'

/**
 * Chain switcher for wallet 
 * @param postHook hook to be called after switch is completed
 * @returns chain switcher function
 */
export default function useSelectChain() {
  const dispatch = useAppDispatch()
  const { connector } = useWeb3ReactWrapped()
  const switchChain = useSwitchChain()
  const toast = useToast()
  return useCallback(
    async (targetChain: SupportedChainId, postHook = () => null) => {
      // if not connected, just switch the state
      if (!connector) {
        dispatch(setChainId({ chainId: targetChain }))
        return true
      }

      const connectionType = getConnection(connector).type

      const chainInfo = getChainInfo(targetChain)

      try {
        dispatch(updateConnectionError({ connectionType, error: undefined }))
        dispatch(setIsLoading({ loading: true }))
        await switchChain(connector, targetChain)
        // set chainId in globalNetwork state
        dispatch(setChainId({ chainId: targetChain }))
        dispatch(setIsLoading({ loading: false }))
        // set loading to true to reload data
        dispatch(setToLoading())
        dispatch(setOraclesToLoading())
        return true
      } catch (error) {
        console.error('Failed to switch networks', error)
        dispatch(setIsLoading({ loading: false }))
        dispatch(updateConnectionError({ connectionType, error: error.message }))
        toast({
          title: `Failed to switch network. In order to use 1delta on ${chainInfo.label}, you must change the network in your wallet.`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
        return false
      } finally {
        postHook()
      }
    },
    [connector, dispatch]
  )
}


export function useSelectChainNoWallet() {
  const dispatch = useAppDispatch()
  const { account } = useWeb3ReactWrapped()

  return useCallback(
    async (targetChain: SupportedChainId) => {
      if (account) return

      try {
        // set chainId in globalNetwork state
        dispatch(setChainId({ chainId: targetChain }))
        // set loading to true to reload data
        dispatch(setToLoading())
        dispatch(setOraclesToLoading())
        dispatch(setAccount({ account: undefined }))
      } catch (error) {
        console.error('Failed to switch networks', error)
      }
    },
    [account, dispatch]
  )
}



/**
 * Chain switcher for state and NOT the wallet
 * @returns chain switcher function
 */
export function useSoftSelectChain() {
  const dispatch = useAppDispatch()
  return useCallback(
    async (targetChain: SupportedChainId) => {
      dispatch(setChainId({ chainId: targetChain }))
      return true
    },
    [dispatch]
  )
}

