import {
  AccordionButton,
  AccordionButtonProps,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionPanelProps,
  Box,
  Divider,
  Image,
} from '@chakra-ui/react'
import { filledArrowDownIcon } from 'constants/1delta'
import { useUserTheme } from 'state/user/hooks'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'

interface CustomAccordionItemProps extends Omit<AccordionItemProps, 'title'> {
  title?: React.ReactNode
  body: React.ReactNode
  footer?: React.ReactNode
  isDisabled?: boolean
  bodyProps?: AccordionPanelProps
  accordionButtonProps?: AccordionButtonProps
  onExpand?: () => void
  showArrow?: boolean
  addArrowPadding?: boolean
}

/**
 * NOTE: This component has to be wrapped in an Accordion component
 * @param title title of the accordion item (optional - if not provided, there won't be a toggle button)
 * @param body body of the accordion item
 * @param footer footer of the accordion item (optional)
 * @returns the custom accordion item
 */
export const CustomAccordionItem: React.FC<CustomAccordionItemProps> = ({
  title,
  body,
  footer,
  isDisabled = false,
  bodyProps,
  accordionButtonProps,
  onExpand,
  showArrow = true,
  addArrowPadding = false,
  ...props
}) => {
  const theme = useUserTheme()
  const arrowDownImg = filledArrowDownIcon[theme]
  return (
    <AccordionItem
      isDisabled={isDisabled}
      borderRadius={'0.25rem'}
      border={'1px solid'}
      borderColor={validateColor('Borders/Border-accordion')}
      w="100%"
      {...props}
    >
      {({ isExpanded }) => {
        return (
          <>
            <AccordionButton
              p="1rem"
              _hover={{
                background: 'transparent',
              }}
              display={title ? 'flex' : 'none'}
              onClick={onExpand}
              {...accordionButtonProps}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                style={getTypography('Typography/Small/Normal/Small 1')}
                color={validateColor('Text/Lables/Label-text-default')}
              >
                {title}
              </Box>
              {showArrow && (
                <Image
                  src={arrowDownImg}
                  alt="Expand"
                  transform={isExpanded ? 'rotate(180deg)' : ''}
                  transition="transform 0.2s"
                  w="1.25rem"
                  h="1.25rem"
                  mr="-7px"
                />
              )}
              {!showArrow && addArrowPadding && <Box w="1.25rem" h="1.25rem" mr="-7px" />}
            </AccordionButton>
            {!isDisabled && (
              <AccordionPanel
                p={'1rem'}
                display="flex"
                flexDirection="column"
                style={getTypography('Typography/Small/Normal/Small 2')}
                color={validateColor('Text/Lables/Label-text-disabled')}
                {...bodyProps}
              >
                {body}
                {footer ? (
                  <>
                    <Divider mt="1rem" mb="1rem" borderColor={validateColor('Borders/Border-secondary')} opacity={1} />
                    {footer}
                  </>
                ) : null}
              </AccordionPanel>
            )}
          </>
        )
      }}
    </AccordionItem>
  )
}
