import { SupportedAssets, TokenMeta } from 'types/1delta'
import { default as wethLogoUrl } from 'assets/svg/tokens/weth.svg'
import { default as daiLogoUrl } from 'assets/svg/tokens/dai.svg'
import { default as linkLogoUrl } from 'assets/svg/tokens/link.svg'
import { default as usdcLogoUrl } from 'assets/svg/tokens/usdc.svg'
import { default as wbtcLogoUrl } from 'assets/svg/tokens/wbtc.svg'
import { default as usdtLogoUrl } from 'assets/svg/tokens/usdt.svg'
import { default as aaveLogoUrl } from 'assets/svg/tokens/aave.svg'
import { default as ethLogoUrl } from 'assets/svg/tokens/eth.svg'
import { default as uniLogoUrl } from 'assets/svg/tokens/uni.svg'
import { default as eursLogoUrl } from 'assets/svg/tokens/eurs.svg'
import { default as compLogoUrl } from 'assets/svg/tokens/comp.svg'
import { default as wmaticLogoUrl } from 'assets/svg/tokens/wmatic.svg'
import { default as ghstLogoUrl } from 'assets/svg/tokens/ghst.svg'
import { default as jeurLogoUrl } from 'assets/svg/tokens/jeur.svg'
import { default as dpiLogoUrl } from 'assets/svg/tokens/dpi.svg'
import { default as sushiLogoUrl } from 'assets/svg/tokens/sushi.svg'
import { default as balLogoUrl } from 'assets/svg/tokens/bal.svg'
import { default as ageurLogoUrl } from 'assets/svg/tokens/ageur.svg'
import { default as crvLogoUrl } from 'assets/svg/tokens/crv.svg'
import { default as batLogoUrl } from 'assets/svg/tokens/bat.svg'
import { default as feiLogoUrl } from 'assets/svg/tokens/fei.svg'
import { default as usdpLogoUrl } from 'assets/svg/tokens/usdp.svg'
import { default as zrxLogoUrl } from 'assets/svg/tokens/zrx.svg'
import { default as mkrLogoUrl } from 'assets/svg/tokens/mkr.svg'
import { default as saiLogoUrl } from 'assets/svg/tokens/sai.svg'
import { default as repLogoUrl } from 'assets/svg/tokens/rep.svg'
import { default as yfiLogoUrl } from 'assets/svg/tokens/yfi.svg'
import { default as tusdLogoUrl } from 'assets/svg/tokens/tusd.svg'
import { default as stmaticLogoUrl } from 'assets/svg/tokens/stmatic.svg'
import { default as maticxLogoUrl } from 'assets/svg/tokens/maticx.svg'
import { default as mimaticLogoUrl } from 'assets/svg/tokens/mimatic.svg'
import { default as gdaiLogoUrl } from 'assets/svg/tokens/gdai.svg'
import { default as wstethLogoUrl } from 'assets/svg/tokens/wsteth.svg'
import { default as vghstLogoUrl } from 'assets/svg/tokens/vghst.svg'
import { default as ghoLogoUrl } from 'assets/svg/tokens/gho.svg'
import { default as usdeLogoUrl } from 'assets/svg/tokens/usde.svg'

import { default as dogeLogoUrl } from 'assets/svg/tokens/doge.svg'
import { default as bnbLogoUrl } from 'assets/svg/tokens/bnb.svg'
import { default as bchLogoUrl } from 'assets/svg/tokens/bch.svg'
import { default as xrpLogoUrl } from 'assets/svg/tokens/xrp.svg'
import { default as ltcLogoUrl } from 'assets/svg/tokens/ltc.svg'
import { default as lunaLogoUrl } from 'assets/svg/tokens/luna.svg'
import { default as vaiLogoUrl } from 'assets/svg/tokens/vai.svg'
import { default as xvsLogoUrl } from 'assets/svg/tokens/xvs.svg'
import { default as wbethLogoUrl } from 'assets/svg/tokens/wbeth.svg'
import { default as vrtLogoUrl } from 'assets/svg/tokens/vrt.svg'
import { default as trxLogoUrl } from 'assets/svg/tokens/trx.svg'
import { default as sxpLogoUrl } from 'assets/svg/tokens/sxp.svg'
import { default as ustLogoUrl } from 'assets/svg/tokens/ust.svg'
import { default as filLogoUrl } from 'assets/svg/tokens/fil.svg'
import { default as dotLogoUrl } from 'assets/svg/tokens/dot.svg'
import { default as adaLogoUrl } from 'assets/svg/tokens/ada.svg'
import { default as bethLogoUrl } from 'assets/svg/tokens/beth.svg'
import { default as cakeLogoUrl } from 'assets/svg/tokens/cake.svg'
// import { default as canLogoUrl } from 'assets/svg/tokens/can.svg'
import { default as btcbLogoUrl } from 'assets/svg/tokens/btcb.svg'
import { default as busdLogoUrl } from 'assets/svg/tokens/busd.svg'
import { default as mntLogoUrl } from 'assets/svg/tokens/mnt.svg'
import { default as methLogoUrl } from 'assets/svg/tokens/meth.svg'
import { default as lendLogoUrl } from 'assets/svg/tokens/lend.svg'
import { default as oauLogoUrl } from 'assets/images/oAU.svg'
import { default as usdyLogoUrl } from 'assets/svg/tokens/usdy.svg'
import { default as usdbLogoUrl } from 'assets/images/usdb.webp'
import { default as avaxLogoUrl } from 'assets/svg/avax-logo.svg'
import { default as taikoLogoUrl } from 'assets/images/taiko-token.svg'


import { default as aave } from 'assets/svg/aave-logo.svg'
import { default as compoundV3 } from 'assets/svg/compound-v3-logo.svg'
import { default as lendle } from 'assets/svg/lendle-logo.svg'
import { default as meridian } from 'assets/svg/meridian.svg'
import { default as init } from 'assets/svg/init-logo.svg'
import { default as aurelius } from 'assets/images/aurelius-logo.png'
import { default as takotako } from 'assets/images/takotako-logo.png'
import { default as deltaAccount } from 'assets/svg/logo_light.svg'

import { default as ovixStandalone } from 'assets/svg/logos/logo-0vix.svg'
import { default as aaveV3Standalone } from 'assets/svg/logos/logo-aave.svg'
import { default as compoundStandalone } from 'assets/svg/logos/logo-compound-2.svg'

import { default as eMatic } from 'assets/svg/e-matic.svg'
import { default as eDefault } from 'assets/svg/e-default.svg'
import { default as eEth } from 'assets/svg/e-eth.svg'
import { default as eStable } from 'assets/svg/e-stable.svg'
import { default as deactivated } from 'assets/svg/deactivated.svg'

import { default as frozenFlagIcon } from 'assets/svg/flags/frozen_asset.svg'
import { default as supplyCapIcon } from 'assets/svg/flags/supply_cap.svg'
import { default as borrowCapIcon } from 'assets/svg/flags/borrow_cap.svg'

import { SupportedChainId } from './chains'
import { Lender } from 'types/lenderData/base'

import { ColorMode } from 'theme'

export const BPS = 10000

export const C_TOKEN_DECIMALS = 8

export const C_TOKEN_PRICE_DECIMALS = 18

export const RESERVE_MANTISSA_DECIMALS = 18

//decimals for collateral and debt factor
export const COMET_RISK_DECIMALS = 18

export const COMET_PRICE_DECIMALS = 18

export const TOKEN_META: { [k: string]: TokenMeta } = {
  [SupportedAssets.WETH]: { symbol: 'WETH', name: 'Wrapped Ether', decimals: 18 },
  [SupportedAssets.UNI]: { symbol: 'UNI', name: 'Uniswap', decimals: 18 },
  [SupportedAssets.DAI]: { symbol: 'DAI', name: 'Dai', decimals: 18 },
  [SupportedAssets.LINK]: { symbol: 'LINK', name: 'ChainLink', decimals: 18 },
  [SupportedAssets.USDCE]: { symbol: 'USDC.e', name: 'Bridged USD Coin', decimals: 6 },
  [SupportedAssets.USDC]: { symbol: 'USDC', name: 'USD Coin', decimals: 6 },
  [SupportedAssets.WBTC]: { symbol: 'WBTC', name: 'Wrapped Bitcoin', decimals: 8 },
  [SupportedAssets.USDT]: { symbol: 'USDT', name: 'Tether', decimals: 6 },
  [SupportedAssets.AAVE]: { symbol: 'AAVE', name: 'AAVE Token', decimals: 18 },
  [SupportedAssets.EURS]: { symbol: 'EURS', name: 'Stasis Euro', decimals: 2 },
  [SupportedAssets.WMATIC]: { symbol: 'WMATIC', name: 'Wrapped Matic', decimals: 18 },
  [SupportedAssets.AGEUR]: { symbol: 'AGEUR', name: 'Angle Euro', decimals: 18 },
  [SupportedAssets.BAL]: { symbol: 'BAL', name: 'Balancer', decimals: 18 },
  [SupportedAssets.CRV]: { symbol: 'CRV', name: 'Curve', decimals: 18 },
  [SupportedAssets.DPI]: { symbol: 'DPI', name: 'DeFi Pulse', decimals: 18 },
  [SupportedAssets.GHST]: { symbol: 'GHST', name: 'Aavegotchi', decimals: 18 },
  [SupportedAssets.JEUR]: { symbol: 'JEUR', name: 'Jarvis Euro', decimals: 18 },
  [SupportedAssets.SUSHI]: { symbol: 'SUSHI', name: 'Sushi Token', decimals: 18 },
  [SupportedAssets.ETH]: { symbol: 'ETH', name: 'Ether', decimals: 18 },
  [SupportedAssets.MATIC]: { symbol: 'MATIC', name: 'Matic', decimals: 18 },
  [SupportedAssets.COMP]: { symbol: 'COMP', name: 'Compound', decimals: 18 },
  [SupportedAssets.BAT]: { symbol: 'BAT', name: 'Basic Attention Token', decimals: 18 },
  [SupportedAssets.FEI]: { symbol: 'FEI', name: 'Fei USD', decimals: 18 },
  [SupportedAssets.MKR]: { symbol: 'MKR', name: 'Maker', decimals: 18 },
  [SupportedAssets.ZRX]: { symbol: 'ZRX', name: 'ZRX', decimals: 18 },
  [SupportedAssets.YFI]: { symbol: 'YFI', name: 'Yearn', decimals: 18 },
  [SupportedAssets.WBTC2]: { symbol: 'WBTC2', name: 'Wrapped Bitcoin', decimals: 8 },
  [SupportedAssets.USDP]: { symbol: 'USDP', name: 'USDP Stablecoin', decimals: 18 },
  [SupportedAssets.TUSD]: { symbol: 'TUSD', name: 'True USDC', decimals: 18 },
  [SupportedAssets.SAI]: { symbol: 'SAI', name: 'Sai', decimals: 18 },
  [SupportedAssets.REP]: { symbol: 'REP', name: 'REP', decimals: 18 },
  [SupportedAssets.MATICX]: { symbol: 'MATICX', name: 'Stader MaticX', decimals: 18 },
  [SupportedAssets.MAI]: { symbol: 'MAI', name: 'MAI', decimals: 18 },
  'MIMATIC': { symbol: 'MAI', name: 'MAI', decimals: 18 },
  'miMATIC': { symbol: 'MAI', name: 'MAI', decimals: 18 },
  [SupportedAssets.STMATIC]: { symbol: 'stMATIC', name: 'Lido Staked Matic', decimals: 18 },
  [SupportedAssets.VGHST]: { symbol: 'VGHST', name: 'Gotchi Vault', decimals: 18 },
  [SupportedAssets.GDAI]: { symbol: 'GDAI', name: 'Gains DAI', decimals: 18 },
  [SupportedAssets.WSTETH]: { symbol: 'wstETH', name: 'Wrapped stETH', decimals: 18 },
  [SupportedAssets.GHO]: { symbol: 'GHO', name: 'GHO Stablecoin', decimals: 18 },
  [SupportedAssets.USD]: { symbol: 'USD', name: 'Dollar', decimals: 18 },
  [SupportedAssets.EUR]: { symbol: 'EUR', name: 'Euro', decimals: 18 },
  [SupportedAssets.FBTC]: { symbol: 'FBTC', name: 'Fire Bitcoin', decimals: 8 },
  [SupportedAssets.OAU]: { symbol: 'oAU', name: 'Aurelius Call Option Token', decimals: 18 },
  [SupportedAssets.AU]: { symbol: 'AU', name: 'Aurelius Token', decimals: 18 },
  // Venus additions
  [SupportedAssets.ADA]: { symbol: 'ADA', name: 'Cardano', decimals: 18 },
  [SupportedAssets.BCH]: { symbol: 'BCH', name: 'Bitcoin Cash', decimals: 18 },
  [SupportedAssets.BETH]: { symbol: 'BETH', name: 'Beacon ETH', decimals: 18 },
  [SupportedAssets.BTCB]: { symbol: 'BTCB', name: 'Binance-peg BTC', decimals: 18 },
  [SupportedAssets.BUSD]: { symbol: 'BUSD', name: 'Binance USD', decimals: 18 },
  [SupportedAssets.CAKE]: { symbol: 'CAKE', name: 'Pancakeswap', decimals: 18 },
  [SupportedAssets.CAN]: { symbol: 'CAN', name: 'CAN', decimals: 18 },
  [SupportedAssets.DOGE]: { symbol: 'DOGE', name: 'Dogecin', decimals: 8 },
  [SupportedAssets.DOT]: { symbol: 'DOT', name: 'Polkadot', decimals: 18 },
  [SupportedAssets.FIL]: { symbol: 'FIL', name: 'Filecoin', decimals: 18 },
  [SupportedAssets.LTC]: { symbol: 'LTC', name: 'Litecoin', decimals: 18 },
  [SupportedAssets.LUNA]: { symbol: 'LUNA', name: 'Terra', decimals: 18 },
  [SupportedAssets.SXP]: { symbol: 'SXP', name: 'Swipe', decimals: 18 },
  [SupportedAssets.TRX]: { symbol: 'TRX', name: 'Tron Token', decimals: 6 },
  [SupportedAssets.TRXOLD]: { symbol: 'TRXOLD', name: 'Tron Token', decimals: 18 },
  [SupportedAssets.TUSDOLD]: { symbol: 'TUSDOLD', name: 'True USD', decimals: 18 },
  [SupportedAssets.UST]: { symbol: 'UST', name: 'Terra USD', decimals: 18 },
  [SupportedAssets.VAI]: { symbol: 'VAI', name: 'VAI', decimals: 18 },
  [SupportedAssets.VRT]: { symbol: 'VRT', name: 'Venus Reward Token', decimals: 18 },
  [SupportedAssets.WBETH]: { symbol: 'WBETH', name: 'Wrapped Beacon ETH', decimals: 18 },
  [SupportedAssets.XRP]: { symbol: 'XRP', name: 'Ripple', decimals: 18 },
  [SupportedAssets.XVS]: { symbol: 'XVS', name: 'Venus Token', decimals: 18 },
  [SupportedAssets.BNB]: { symbol: 'BNB', name: 'Binance Coin', decimals: 18 },
  [SupportedAssets.WBNB]: { symbol: 'WBNB', name: 'Wrapped BNB', decimals: 18 },
  [SupportedAssets.BUSDC]: { symbol: 'BUSDC', name: 'Binance-peg USDC', decimals: 18 },
  [SupportedAssets.BUSDT]: { symbol: 'BUSDT', name: 'Binance-peg USDT', decimals: 18 },
  [SupportedAssets.WMNT]: { symbol: 'WMNT', name: 'Wrapped MNT', decimals: 18 },
  [SupportedAssets.MNT]: { symbol: 'MNT', name: 'Mantle', decimals: 18 },
  [SupportedAssets.METH]: { symbol: 'mETH', name: 'Mantle Staked Ether', decimals: 18 },
  [SupportedAssets.LEND]: { symbol: 'LEND', name: 'Lendle', decimals: 18 },
  [SupportedAssets.USDY]: { symbol: 'USDY', name: 'Ondo USD Yield', decimals: 18 },
  [SupportedAssets.USDE]: { symbol: 'USDe', name: 'Ethena USD', decimals: 18 },
  [SupportedAssets.USDB]: { symbol: 'USDB', name: 'USDB', decimals: 18 },
  [SupportedAssets.BLAST]: { symbol: 'BLAST', name: 'Blast', decimals: 18 },
  [SupportedAssets.ANDY]: { symbol: 'ANDY', name: 'Andy', decimals: 18 },
  [SupportedAssets.CBETH]: { symbol: 'cbETH', name: 'Coinbase Wrapped Staked ETH', decimals: 18 },
  [SupportedAssets.WAVAX]: { symbol: 'WAVAX', name: 'Wrapped AVAX', decimals: 18 },
  [SupportedAssets.AVAX]: { symbol: 'AVAX', name: 'Avalanche', decimals: 18 },
  [SupportedAssets.TAIKO]: { symbol: 'TAIKO', name: 'Taiko', decimals: 18 },
  [SupportedAssets.PENDLE]: { symbol: 'PENDLE', name: 'Pendle', decimals: 18 },
  [SupportedAssets.OHM]: { symbol: 'OHM', name: 'Olympus', decimals: 9 },
  [SupportedAssets.USDTE]: { symbol: 'USDT.E', name: 'Stargate Bridged Tether', decimals: 6 },
  [SupportedAssets.ARB]: { symbol: 'ARB', name: 'Arbitrum', decimals: 18 },
  [SupportedAssets.WEETH]: { symbol: 'weETH', name: 'Wrapped eETH', decimals: 18 },
  [SupportedAssets.EZETH]: { symbol: 'ezETH', name: 'Renzo Restaked ETH', decimals: 18 },
  [SupportedAssets.CMETH]: { symbol: 'cmETH', name: 'cmETH', decimals: 18 },
  [SupportedAssets.AUSD]: { symbol: 'AUSD', name: 'Agora USD', decimals: 6 },
}

/** Return asset symbol from TOKEN_META assigned via enum or the asset as fallback */
export function getAssetSymbol(asset: string) {
  return TOKEN_META[asset.toUpperCase()]?.symbol ?? asset
}

export const ETHEREUM_CHAINS = [
  SupportedChainId.MAINNET,
  SupportedChainId.TAIKO
]

export const POLYGON_CHAINS = [SupportedChainId.POLYGON, SupportedChainId.POLYGON_MUMBAI]

export const MAINNET_CHAINS = [
  SupportedChainId.POLYGON,
  SupportedChainId.MAINNET,
  SupportedChainId.BSC,
  SupportedChainId.MANTLE,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BASE,
  SupportedChainId.OPTIMISM,
  SupportedChainId.LINEA,
  SupportedChainId.AVALANCHE,
  SupportedChainId.BLAST,
  SupportedChainId.TAIKO
]


export const TOKEN_SVGS: { [asset: string]: string } = {
  [SupportedAssets.WETH]: wethLogoUrl,
  [SupportedAssets.DAI]: daiLogoUrl,
  [SupportedAssets.LINK]: linkLogoUrl,
  [SupportedAssets.USDCE]: usdcLogoUrl,
  [SupportedAssets.USDC]: usdcLogoUrl,
  [SupportedAssets.WBTC]: wbtcLogoUrl,
  [SupportedAssets.USDT]: usdtLogoUrl,
  [SupportedAssets.AAVE]: aaveLogoUrl,
  [SupportedAssets.EURS]: eursLogoUrl,
  [SupportedAssets.WMATIC]: wmaticLogoUrl,
  [SupportedAssets.AGEUR]: ageurLogoUrl,
  [SupportedAssets.BAL]: balLogoUrl,
  [SupportedAssets.CRV]: crvLogoUrl,
  [SupportedAssets.DPI]: dpiLogoUrl,
  [SupportedAssets.GHST]: ghstLogoUrl,
  [SupportedAssets.JEUR]: jeurLogoUrl,
  [SupportedAssets.SUSHI]: sushiLogoUrl,
  [SupportedAssets.UNI]: uniLogoUrl,
  [SupportedAssets.ETH]: ethLogoUrl,
  [SupportedAssets.MATIC]: wmaticLogoUrl,
  [SupportedAssets.COMP]: compLogoUrl,
  [SupportedAssets.BAT]: batLogoUrl,
  [SupportedAssets.FEI]: feiLogoUrl,
  [SupportedAssets.MKR]: mkrLogoUrl,
  [SupportedAssets.ZRX]: zrxLogoUrl,
  [SupportedAssets.YFI]: yfiLogoUrl,
  [SupportedAssets.WBTC2]: wbtcLogoUrl,
  [SupportedAssets.USDP]: usdpLogoUrl,
  [SupportedAssets.TUSD]: tusdLogoUrl,
  [SupportedAssets.SAI]: saiLogoUrl,
  [SupportedAssets.REP]: repLogoUrl,
  [SupportedAssets.MATICX]: maticxLogoUrl,
  [SupportedAssets.MAI]: mimaticLogoUrl,
  'MIMATIC': mimaticLogoUrl,
  [SupportedAssets.STMATIC]: stmaticLogoUrl,
  [SupportedAssets.VGHST]: vghstLogoUrl,
  [SupportedAssets.GDAI]: gdaiLogoUrl,
  [SupportedAssets.WSTETH]: wstethLogoUrl,
  [SupportedAssets.GHO]: ghoLogoUrl,
  // venus assets
  [SupportedAssets.ADA]: adaLogoUrl,
  [SupportedAssets.BCH]: bchLogoUrl,
  [SupportedAssets.BETH]: bethLogoUrl,
  [SupportedAssets.BTCB]: btcbLogoUrl,
  [SupportedAssets.BUSD]: busdLogoUrl,
  [SupportedAssets.CAKE]: cakeLogoUrl,
  [SupportedAssets.CAN]: '',
  [SupportedAssets.DOGE]: dogeLogoUrl,
  [SupportedAssets.DOT]: dotLogoUrl,
  [SupportedAssets.FIL]: filLogoUrl,
  [SupportedAssets.LTC]: ltcLogoUrl,
  [SupportedAssets.LUNA]: lunaLogoUrl,
  [SupportedAssets.SXP]: sxpLogoUrl,
  [SupportedAssets.TRX]: trxLogoUrl,
  [SupportedAssets.TRXOLD]: trxLogoUrl,
  [SupportedAssets.TUSDOLD]: tusdLogoUrl,
  [SupportedAssets.UST]: ustLogoUrl,
  [SupportedAssets.VAI]: vaiLogoUrl,
  [SupportedAssets.VRT]: vrtLogoUrl,
  [SupportedAssets.WBETH]: wbethLogoUrl,
  [SupportedAssets.XRP]: xrpLogoUrl,
  [SupportedAssets.XVS]: xvsLogoUrl,
  [SupportedAssets.BNB]: bnbLogoUrl,
  [SupportedAssets.WBNB]: bnbLogoUrl,
  [SupportedAssets.BUSDC]: usdcLogoUrl,
  [SupportedAssets.BUSDT]: usdtLogoUrl,
  [SupportedAssets.WMNT]: mntLogoUrl,
  [SupportedAssets.MNT]: mntLogoUrl,
  [SupportedAssets.METH]: methLogoUrl,
  [SupportedAssets.LEND]: lendLogoUrl,
  [SupportedAssets.OAU]: oauLogoUrl,
  [SupportedAssets.USDY]: usdyLogoUrl,
  [SupportedAssets.USDE]: usdeLogoUrl,
  [SupportedAssets.USDB]: usdbLogoUrl,
  [SupportedAssets.AVAX]: avaxLogoUrl,
  [SupportedAssets.TAIKO]: taikoLogoUrl,
}

const EMODE_DEFAULT_SVG = eDefault

const EMODE_SVGS: { [chainId: number]: { [eMode: number]: string } } = {
  [SupportedChainId.POLYGON]: {
    0: deactivated,
    1: eStable,
    2: eMatic,
    3: eEth
  }
}

const MODE_SVGS: { [chainId: number]: { [mode: number]: string } } = {
  [SupportedChainId.MANTLE]: {
    1: deactivated,
    3: eStable,
    2: eMatic,
    4: eEth
  }
}


export const getEmodeSvg = (chainId: number, id: number, lender = Lender.AAVE_V3) => {
  if (lender === Lender.AAVE_V3)
    return EMODE_SVGS[chainId]?.[id] ?? EMODE_DEFAULT_SVG
  return MODE_SVGS[chainId]?.[id] ?? EMODE_DEFAULT_SVG
}

export const VENUS_SVG = xvsLogoUrl

export const AAVE_SVG = aave

export const LENDLE_SVG = lendle

export const INIT_SVG = init

export const AURELIUS_SVG = aurelius

export const TAKOTAKO_SVG = takotako

export const MERIDIAN_SVG = meridian

export const COMPOUNDV3_SVG = compoundV3

export const LOGO_ACCOUNT_SVG = deltaAccount

export const AAVE_V3_SVG_STANDALONE = aaveV3Standalone

export const AAVE_V2_SVG_STANDALONE = aaveV3Standalone

export const OVIX_SVG_STANDALONE = ovixStandalone

export const COMPOUNDV2_SVG_STANDALONE = compoundStandalone

export enum AssetFlags {
  FROZEN = 'frozen',
  SUPPLY_CAP = 'supply cap',
  BORROW_CAP = 'borrow cap',
  LOW_LIQUIDITY = 'low liquidity',
}

export const ASSET_FLAG_SVGS: { [asset: string]: string } = {
  [AssetFlags.FROZEN]: frozenFlagIcon,
  [AssetFlags.SUPPLY_CAP]: supplyCapIcon,
  [AssetFlags.BORROW_CAP]: borrowCapIcon,
}

export const getAssetFlagIcon = (asset: AssetFlags | string): string => {
  return ASSET_FLAG_SVGS[String(asset).toLocaleUpperCase()] || ASSET_FLAG_SVGS[String(asset)]
}

export const GREEKS = [
  'alpha',
  'beta',
  'gamma',
  'Delta',
  'delta',
  'epsilon',
  'zeta',
  'eta',
  'Theta',
  'theta',
  'iota',
  'kappa',
  'Lambda',
  'lambda',
  'mu',
  'nu',
  'Xi',
  'xi',
  'Pi',
  'pi',
  'varpi',
  'Sigma',
  'sigma',
  'tau',
  'Phi',
  'phi',
  'varphi',
  'chi',
  'Psi',
  'psi',
  'Omega',
  'omega',
]

export const WRAPPED_NATIVE_SYMBOL: { [key: number]: string } = {
  [SupportedChainId.POLYGON]: 'WMATIC',
  [SupportedChainId.GOERLI]: 'WETH',
  [SupportedChainId.MAINNET]: 'WETH',
  [SupportedChainId.POLYGON_MUMBAI]: 'WMATIC',
  [SupportedChainId.BSC]: 'WBNB',
  [SupportedChainId.MANTLE]: 'WMNT',
  [SupportedChainId.ARBITRUM_ONE]: 'WETH',
  [SupportedChainId.BASE]: 'WETH',
  [SupportedChainId.AVALANCHE]: 'WAVAX',
  [SupportedChainId.OPTIMISM]: 'WETH',
  [SupportedChainId.BLAST]: 'WETH',
  [SupportedChainId.LINEA]: 'WETH',
  [SupportedChainId.TAIKO]: 'WETH',
}

export const NATIVE_SYMBOL: { [key: number]: string } = {
  [SupportedChainId.POLYGON]: 'MATIC',
  [SupportedChainId.GOERLI]: 'ETH',
  [SupportedChainId.MAINNET]: 'ETH',
  [SupportedChainId.POLYGON_MUMBAI]: 'MATIC',
  [SupportedChainId.BSC]: 'BNB',
  [SupportedChainId.MANTLE]: 'MNT',
  [SupportedChainId.ARBITRUM_ONE]: 'ETH',
  [SupportedChainId.BASE]: 'ETH',
  [SupportedChainId.AVALANCHE]: 'AVAX',
  [SupportedChainId.OPTIMISM]: 'ETH',
  [SupportedChainId.BLAST]: 'ETH',
  [SupportedChainId.LINEA]: 'ETH',
  [SupportedChainId.TAIKO]: 'ETH',
}

/**
 * Get the lender text for the input mask
 * @param lender lender enum
 * @param chainId chainId
 * @returns the name of the correct lender
 */
export const toLenderText = (lender: Lender) => {
  return lender
}

export enum PositionType {
  LONG = 'Long',
  SHORT = 'Short',
  SHORT_STABLE = 'Short Stable'
}

import { default as oneDeltaLogoSvg } from 'assets/logos/1delta_logo.svg'
import { default as oneDeltaLogoDarkSvg } from 'assets/logos/1delta_logo_dark.svg'

export const oneDeltaLogo = {
  [ColorMode.DARK]: oneDeltaLogoSvg,
  [ColorMode.LIGHT]: oneDeltaLogoDarkSvg,
}

import { default as oneDeltaLogoSmallSvg } from 'assets/logos/1delta_logo_small.svg'
import { default as oneDeltaLogoSmallDarkSvg } from 'assets/logos/1delta_logo_small_dark.svg'

export const oneDeltaLogoSmall = {
  [ColorMode.DARK]: oneDeltaLogoSmallSvg,
  [ColorMode.LIGHT]: oneDeltaLogoSmallDarkSvg,
}

import { default as chevronDownLightSvg } from 'assets/icons/chevron-down-light.svg'
import { default as chevronDownDarkSvg } from 'assets/icons/chevron-down-dark.svg'

export const chevronDown = {
  [ColorMode.DARK]: chevronDownLightSvg,
  [ColorMode.LIGHT]: chevronDownDarkSvg,
}

import { default as bellLightSvg } from 'assets/icons/bell-light.svg'
import { default as bellDarkSvg } from 'assets/icons/bell-dark.svg'

export const notificationBell = {
  [ColorMode.DARK]: bellLightSvg,
  [ColorMode.LIGHT]: bellDarkSvg,
}

import { default as settingsLightSvg } from 'assets/icons/settings-light.svg'
import { default as settingsDarkSvg } from 'assets/icons/settings-dark.svg'

export const settings = {
  [ColorMode.DARK]: settingsLightSvg,
  [ColorMode.LIGHT]: settingsDarkSvg,
}

import { default as dottedLineLighterSvg } from 'assets/images/dot_line_lighter.png'
import { default as dottedLineDarkSvg } from 'assets/images/dot_line_lighter_inverted.png'

export const dottedLine = {
  [ColorMode.DARK]: dottedLineLighterSvg,
  [ColorMode.LIGHT]: dottedLineDarkSvg,
}

import { default as doubleChevronRightLight } from "assets/icons/double-chevron-right-light.svg"
import { default as doubleChevronRightDark } from "assets/icons/double-chevron-right-dark.svg"

export const doubleChevronRight = {
  [ColorMode.DARK]: doubleChevronRightLight,
  [ColorMode.LIGHT]: doubleChevronRightDark,
}

import { default as cashLight } from 'assets/icons/cash-light.svg'
import { default as cashDark } from 'assets/icons/cash-dark.svg'

export const cashIcon = {
  [ColorMode.DARK]: cashLight,
  [ColorMode.LIGHT]: cashDark,
}

import { default as bitcoinLight } from 'assets/icons/bitcoin-light.svg'
import { default as bitcoinDark } from 'assets/icons/bitcoin-dark.svg'

export const bitcoinIcon = {
  [ColorMode.DARK]: bitcoinLight,
  [ColorMode.LIGHT]: bitcoinDark,
}

import { default as debtLight } from 'assets/icons/debt-light.svg'
import { default as debtDark } from 'assets/icons/debt-dark.svg'

export const debtIcon = {
  [ColorMode.DARK]: debtLight,
  [ColorMode.LIGHT]: debtDark,
}

import { default as coinsLight } from 'assets/icons/coins-light.svg'
import { default as coinsDark } from 'assets/icons/coins-dark.svg'

export const coinsIcon = {
  [ColorMode.DARK]: coinsLight,
  [ColorMode.LIGHT]: coinsDark,
}

import { default as aprLight } from 'assets/icons/apr-light.svg'
import { default as aprDark } from 'assets/icons/apr-dark.svg'

export const aprIcon = {
  [ColorMode.DARK]: aprLight,
  [ColorMode.LIGHT]: aprDark,
}

import { default as gridsDark } from 'assets/images/Grids.png'
import { default as gridsLight } from 'assets/images/Grids-light.png'

export const gridsImage = {
  [ColorMode.DARK]: gridsDark,
  [ColorMode.LIGHT]: gridsLight,
}

import { default as closeDark } from 'assets/icons/close-dark.svg'
import { default as closeLight } from 'assets/icons/close-light.svg'

export const closeIcon = {
  [ColorMode.DARK]: closeLight,
  [ColorMode.LIGHT]: closeDark,
}

import { default as borrowDark } from 'assets/hoverableRow/borrow-dark.svg'
import { default as borrowLight } from 'assets/hoverableRow/borrow-light.svg'

export const borrowIcon = {
  [ColorMode.DARK]: borrowLight,
  [ColorMode.LIGHT]: borrowDark,
}

import { default as depositDark } from 'assets/hoverableRow/deposit-dark.svg'
import { default as depositLight } from 'assets/hoverableRow/deposit-light.svg'

export const depositIcon = {
  [ColorMode.DARK]: depositLight,
  [ColorMode.LIGHT]: depositDark,
}

import { default as marginDark } from 'assets/hoverableRow/margin-dark.svg'
import { default as marginLight } from 'assets/hoverableRow/margin-light.svg'

export const marginIcon = {
  [ColorMode.DARK]: marginLight,
  [ColorMode.LIGHT]: marginDark,
}

import { default as repayDark } from 'assets/hoverableRow/repay-dark.svg'
import { default as repayLight } from 'assets/hoverableRow/repay-light.svg'

export const repayIcon = {
  [ColorMode.DARK]: repayLight,
  [ColorMode.LIGHT]: repayDark,
}

import { default as swapDark } from 'assets/hoverableRow/swap-dark.svg'
import { default as swapLight } from 'assets/hoverableRow/swap-light.svg'

export const swapIcon = {
  [ColorMode.DARK]: swapLight,
  [ColorMode.LIGHT]: swapDark,
}

import { default as withdrawDark } from 'assets/hoverableRow/withdraw-dark.svg'
import { default as withdrawLight } from 'assets/hoverableRow/withdraw-light.svg'

export const withdrawIcon = {
  [ColorMode.DARK]: withdrawLight,
  [ColorMode.LIGHT]: withdrawDark,
}

import { default as externalLinkIconLight } from 'assets/icons/external-link-light.svg'
import { default as externalLinkIconDark } from 'assets/icons/external-link-dark.svg'

export const externalLinkIcon = {
  [ColorMode.DARK]: externalLinkIconLight,
  [ColorMode.LIGHT]: externalLinkIconDark,
}

import { default as sortLight } from 'assets/icons/sort-light.svg'
import { default as sortDark } from 'assets/icons/sort-dark.svg'

export const sortIcon = {
  [ColorMode.DARK]: sortLight,
  [ColorMode.LIGHT]: sortDark,
}

import { default as arrowRightLight } from 'assets/icons/arrow-right-light.svg'
import { default as arrowRightDark } from 'assets/icons/arrow-right-dark.svg'

export const arrowRightIcon = {
  [ColorMode.DARK]: arrowRightLight,
  [ColorMode.LIGHT]: arrowRightDark,
}

import { default as copyLight } from 'assets/icons/copy-light.svg'
import { default as copyDark } from 'assets/icons/copy-dark.svg'

export const copyIcon = {
  [ColorMode.DARK]: copyLight,
  [ColorMode.LIGHT]: copyDark,
}

import { default as filledArrowDownLight } from 'assets/icons/filled-arrow-down-light.svg'
import { default as filledArrowDownDark } from 'assets/icons/filled-arrow-down-dark.svg'

export const filledArrowDownIcon = {
  [ColorMode.DARK]: filledArrowDownLight,
  [ColorMode.LIGHT]: filledArrowDownDark,
}

import { default as infoLight } from 'assets/icons/info-light.svg'
import { default as infoDark } from 'assets/icons/info-dark.svg'

export const infoIcon = {
  [ColorMode.DARK]: infoLight,
  [ColorMode.LIGHT]: infoDark,
}

import { default as noOpenPositionsDark } from 'assets/icons/no-open-positions-dark.svg'
import { default as noOpenPositionsLight } from 'assets/icons/no-open-positions-light.svg'

export const noOpenPositionsIcon = {
  [ColorMode.DARK]: noOpenPositionsDark,
  [ColorMode.LIGHT]: noOpenPositionsLight,
}

import { default as nothingToShowDark } from 'assets/icons/nothing-to-show-dark.svg'
import { default as nothingToShowLight } from 'assets/icons/nothing-to-show-light.svg'

export const nothingToShowIcon = {
  [ColorMode.DARK]: nothingToShowDark,
  [ColorMode.LIGHT]: nothingToShowLight,
}

import { default as connectWalletDark } from 'assets/icons/connect-wallet-dark.svg'
import { default as connectWalletLight } from 'assets/icons/connect-wallet-light.svg'

export const connectWalletIcon = {
  [ColorMode.DARK]: connectWalletDark,
  [ColorMode.LIGHT]: connectWalletLight,
}

import { default as checkDark } from 'assets/icons/check-dark.svg'
import { default as checkLight } from 'assets/icons/check-light.svg'

export const checkIcon = {
  [ColorMode.DARK]: checkLight,
  [ColorMode.LIGHT]: checkDark,
}

import { default as transferDark } from 'assets/icons/transfer-dark.svg'
import { default as transferLight } from 'assets/icons/transfer-light.svg'

export const transferIcon = {
  [ColorMode.DARK]: transferLight,
  [ColorMode.LIGHT]: transferDark,
}

import { default as arrowDownLight } from 'assets/icons/arrow-down-light.svg'
import { default as arrowDownDark } from 'assets/icons/arrow-down-dark.svg'

export const arrowDownIcon = {
  [ColorMode.DARK]: arrowDownLight,
  [ColorMode.LIGHT]: arrowDownDark,
}

import { default as arrowUpLight } from 'assets/icons/arrow-up-light.svg'
import { default as arrowUpDark } from 'assets/icons/arrow-up-dark.svg'

export const arrowUpIcon = {
  [ColorMode.DARK]: arrowUpLight,
  [ColorMode.LIGHT]: arrowUpDark,
}

import { default as loginDark } from 'assets/icons/login-dark.svg'
import { default as loginLight } from 'assets/icons/login-light.svg'

export const loginIcon = {
  [ColorMode.DARK]: loginLight,
  [ColorMode.LIGHT]: loginDark,
}

import { default as walletDark } from 'assets/icons/login-dark.svg'
import { default as walletLight } from 'assets/icons/wallet-light.svg'

export const walletIcon = {
  [ColorMode.DARK]: walletLight,
  [ColorMode.LIGHT]: walletDark,
}

import { default as refreshLight } from 'assets/icons/refresh-light.svg'
import { default as refreshDark } from 'assets/icons/refresh-dark.svg'

export const refreshIcon = {
  [ColorMode.DARK]: refreshLight,
  [ColorMode.LIGHT]: refreshDark,
}