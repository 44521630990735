import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { Lender } from 'types/lenderData/base'
import { AaveTypeGetUserReserveIndexes, AaveV3UserReserveResponse } from './types'
import { getLenderAssets } from 'constants/getAssets'
import { AllowanceData } from '../aave-v2-type/types'
import { toOracleKey } from 'types/1delta'


export const getAaveV3UserDataConverter = (
  lender: Lender,
  chainId: number,
  account: string,
  prices: { [asset: string]: number }
): [(data: any[]) => AaveV3UserReserveResponse | undefined, number] => {
  switch (lender) {
    case Lender.AAVE_V3: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 5 + 1
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }
          const userEMode = Number(data[expectedNumberOfCalls - 1])
          const tokensData: { [asset: string]: any } = {}
          const allowances: { [asset: string]: AllowanceData } = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 5]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const price = prices[toOracleKey(asset)] ?? 1

            const currentATokenBalance = parseRawAmount(reserveData[AaveTypeGetUserReserveIndexes.currentATokenBalance].toString(), decimals)
            const currentStableDebt = parseRawAmount(reserveData[AaveTypeGetUserReserveIndexes.currentStableDebt].toString(), decimals)
            const currentVariableDebt = parseRawAmount(reserveData[AaveTypeGetUserReserveIndexes.currentVariableDebt].toString(), decimals)

            tokensData[asset] = {
              deposits: currentATokenBalance,
              debtStable: currentStableDebt,
              debt: currentVariableDebt,
              depositsUSD: currentATokenBalance * price,
              debtStableUSD: currentStableDebt * price,
              debtUSD: currentVariableDebt * price,
              stableBorrowRate: reserveData[AaveTypeGetUserReserveIndexes.stableBorrowRate].toString(),
              collateralActive: Boolean(reserveData[AaveTypeGetUserReserveIndexes.usageAsCollateralEnabled]),
              claimableRewards: 0,
            }
            allowances[asset] = {
              allowanceDepositDirect: data[i * 5 + 1].toString(),
              allowanceWithdrawal: data[i * 5 + 2].toString(),
              allowanceBorrowingVariable: data[i * 5 + 3].toString(),
              allowanceBorrowingStable: data[i * 5 + 4].toString(),
            }
          }

          return {
            chainId,
            account,
            tokensData,
            rewards: {},
            userEMode,
            allowances
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}