import { checkIcon, copyIcon } from 'constants/1delta'
import { useEffect, useState } from 'react'
import { useUserTheme } from 'state/user/hooks'
import { IconButtonWithTooltip, IconButtonWithTooltipProps } from './IconButtonWithTooltip'
import React from 'react'

interface CopyButtonProps extends Omit<IconButtonWithTooltipProps, 'src'> {
  text: string
}

export const CopyButton: React.FC<CopyButtonProps> = ({ text, ...props }) => {
  const theme = useUserTheme()
  const copyImage = copyIcon[theme]
  const checkImage = checkIcon[theme]
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  return (
    <IconButtonWithTooltip
      imageProps={{ w: '1rem', h: '1rem' }}
      onClick={(e) => {
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        setCopied(true)
      }}
      src={copied ? checkImage : copyImage}
      {...props}
    />
  )
}
