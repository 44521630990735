import { Tooltip, TooltipProps, BoxProps, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { validateColor } from 'theme/colors'

interface CustomTooltipProps extends TooltipProps {
  tooltipContent: React.ReactNode
  innerBoxProps?: BoxProps
  noUnderline?: boolean
}

/**
 * @param tooltipContent the content of the tooltip, shown when hovering over the children
 * @param children the children that will trigger the tooltip
 * @param innerBoxProps BoxProps
 */
export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  tooltipContent,
  children,
  innerBoxProps,
  noUnderline = false,
  ...props
}) => {
  // the manual activation is done for mobile
  const [isLabelOpen, setIsLabelOpen] = useState(false)
  return (
    <Tooltip
      isOpen={isLabelOpen}
      hasArrow
      label={tooltipContent}
      bg={validateColor('Surface/Surface-primary')}
      boxShadow="dark"
      borderRadius={'0.25rem'}
      p={'1rem'}
      zIndex={100}
      {...props}
    >
      <Flex
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
        width={'fit-content'}
        borderBottom={noUnderline ? 'none' : '1px dashed'}
        borderColor={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
        _hover={{
          cursor: 'default',
          borderColor: validateColor('Text/Lables/Label-text-disabled'),
          transition: 'border-color 0.3s',
        }}
        transition={'border-color 0.3s'}
        {...innerBoxProps}
      >
        {children}
      </Flex>
    </Tooltip>
  )
}
