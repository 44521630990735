import { createAction } from '@reduxjs/toolkit'
import { ChainIdMap } from 'utils/types'

export const setChainId = createAction<{ chainId: number }>('globalNetwork/setChainId')

export const setIsLoading = createAction<{ loading: boolean }>('globalNetwork/setIsLoading')

export const setAccount = createAction<{ account: string | undefined }>('globalNetwork/setAccount')

export const setImpersonatedAccount = createAction<{ account: string | undefined }>('globalNetwork/setImpersonatedAccount')

export const setUseImpersonatedAccount = createAction<{ isUsed: boolean }>('globalNetwork/setUseImpersonatedAccount')

export const setIsSupported = createAction<{ isSupported: boolean }>('globalNetwork/setIsSupported')

export const setBlockNumber = createAction<{ chainId: number, blockNumber: number }>('globalNetwork/setBlockNumber')

interface SubConfigParams {
  account?: string
  tokens?: string[]
  approvalTargets?: string[]
}

export const setSubscribeConfigXChain = createAction<ChainIdMap<SubConfigParams>>('globalNetwork/setSubscribeConfigXChain')
