import { Lender } from "types/lenderData/base"
import { SupportedChainId } from "./chains"
import { SupportedAssets } from "types/1delta"

export const getLenderAssets = (
  chainId: number,
  lendingProtocol = Lender.AAVE_V3
): SupportedAssets[] => {
  switch (chainId) {
    case SupportedChainId.POLYGON: {
      switch (lendingProtocol) {
        case Lender.AAVE_V3: {
          return [
            SupportedAssets.WMATIC,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.DAI,
            SupportedAssets.USDCE,
            SupportedAssets.USDC,
            SupportedAssets.USDT,
            SupportedAssets.AAVE,
            SupportedAssets.BAL,
            SupportedAssets.AGEUR,
            SupportedAssets.EURS,
            SupportedAssets.JEUR,
            SupportedAssets.MAI,
            SupportedAssets.CRV,
            SupportedAssets.DPI,
            SupportedAssets.GHST,
            SupportedAssets.LINK,
            SupportedAssets.MATICX,
            SupportedAssets.STMATIC,
            SupportedAssets.SUSHI,
            SupportedAssets.WSTETH,
          ]
        }
        case Lender.AAVE_V2: {
          return [
            SupportedAssets.DAI,
            SupportedAssets.USDCE,
            SupportedAssets.USDT,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.WMATIC,
            SupportedAssets.AAVE,
            SupportedAssets.GHST,
            SupportedAssets.BAL,
            SupportedAssets.DPI,
            SupportedAssets.CRV,
            SupportedAssets.SUSHI,
            SupportedAssets.LINK,
          ]
        }
        case Lender.OVIX: {
          return [
            SupportedAssets.DAI,
            SupportedAssets.USDCE,
            SupportedAssets.USDT,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.MATIC,
            SupportedAssets.MATICX,
            SupportedAssets.MAI,
            SupportedAssets.STMATIC,
            SupportedAssets.JEUR,
            // SupportedAssets.WSTETH
          ]
        }
        case Lender.COMPOUND_V3: {
          return [
            // SupportedAssets.COMP,
            SupportedAssets.USDCE,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.WMATIC,
            SupportedAssets.MATICX,
            SupportedAssets.STMATIC,
          ]
        }

        default:
          return []

      }
    }
    case SupportedChainId.MANTLE: {
      switch (lendingProtocol) {
        case Lender.LENDLE: {
          return [
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.METH,
            SupportedAssets.USDC,
            SupportedAssets.USDT,
            SupportedAssets.WMNT,
            SupportedAssets.USDE,
            SupportedAssets.FBTC,
            SupportedAssets.AUSD,
            SupportedAssets.CMETH,
          ]
        }
        case Lender.INIT: {
          return [
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.METH,
            SupportedAssets.USDC,
            SupportedAssets.USDT,
            SupportedAssets.WMNT,
            SupportedAssets.USDY,
            SupportedAssets.USDE,
            SupportedAssets.FBTC,
            SupportedAssets.CMETH,
          ]
        }
        case Lender.AURELIUS: {
          return [
            SupportedAssets.USDT,
            SupportedAssets.USDC,
            SupportedAssets.WETH,
            SupportedAssets.WMNT,
            SupportedAssets.WBTC,
            SupportedAssets.METH,
            SupportedAssets.USDE,
            SupportedAssets.USDY,
            SupportedAssets.FBTC,
            SupportedAssets.CMETH,
          ]
        }
        default:
          return []
      }
    }
    case SupportedChainId.TAIKO: {
      switch (lendingProtocol) {
        case Lender.MERIDIAN: {
          return [
            SupportedAssets.WETH,
            SupportedAssets.TAIKO,
            SupportedAssets.USDCE,
            SupportedAssets.USDTE,
            SupportedAssets.USDC,
          ]
        }
        case Lender.TAKOTAKO: {
          return [
            SupportedAssets.USDC,
            SupportedAssets.USDT,
            SupportedAssets.TAIKO,
            SupportedAssets.WETH,
          ]
        }
        default:
          return []
      }
    }
    case SupportedChainId.POLYGON_MUMBAI:
      switch (lendingProtocol) {
        case Lender.AAVE_V3: {
          return [
            SupportedAssets.AAVE,
            SupportedAssets.AGEUR,
            SupportedAssets.BAL,
            SupportedAssets.CRV,
            SupportedAssets.DAI,
            SupportedAssets.DPI,
            SupportedAssets.EURS,
            SupportedAssets.GHST,
            SupportedAssets.JEUR,
            SupportedAssets.LINK,
            SupportedAssets.SUSHI,
            SupportedAssets.USDCE,
            SupportedAssets.USDT,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.WMATIC,
          ]
        }
        case Lender.COMPOUND_V2: {
          return [
            SupportedAssets.DAI,
            SupportedAssets.USDCE,
            SupportedAssets.USDT,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.MATIC,
          ]
        }
        case Lender.COMPOUND_V3: {
          return [
            SupportedAssets.DAI,
            SupportedAssets.USDCE,
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.WMATIC,
          ]
        }
        default:
          return []
      }
    case SupportedChainId.BSC: {
      switch (lendingProtocol) {
        case Lender.VENUS: {
          return [
            SupportedAssets.AAVE,
            SupportedAssets.ADA,
            SupportedAssets.BCH,
            SupportedAssets.BNB,
            SupportedAssets.BETH,
            SupportedAssets.BTCB,
            SupportedAssets.BUSD,
            SupportedAssets.CAKE,
            // SupportedAssets.CAN,
            SupportedAssets.DAI,
            SupportedAssets.DOGE,
            SupportedAssets.DOT,
            SupportedAssets.WETH,
            SupportedAssets.FIL,
            SupportedAssets.LINK,
            SupportedAssets.LTC,
            SupportedAssets.LUNA,
            SupportedAssets.WMATIC,
            SupportedAssets.SXP,
            SupportedAssets.TRX,
            SupportedAssets.TRXOLD,
            SupportedAssets.TUSD,
            SupportedAssets.TUSDOLD,
            SupportedAssets.BUSDC,
            SupportedAssets.BUSDT,
            SupportedAssets.UST,
            SupportedAssets.VAI,
            SupportedAssets.VRT,
            SupportedAssets.WBETH,
            SupportedAssets.XRP,
            SupportedAssets.XVS,
          ]
        }
        default:
          return []
      }
    }
    case SupportedChainId.MAINNET: {
      switch (lendingProtocol) {
        case Lender.AAVE_V3: {
          return []
        }
        case Lender.COMPOUND_V2: {
          return [
            SupportedAssets.ETH,
            SupportedAssets.DAI,
            SupportedAssets.UNI,
            SupportedAssets.USDCE,
            SupportedAssets.USDT,
            SupportedAssets.WBTC,
            SupportedAssets.COMP,
            SupportedAssets.ZRX,
            SupportedAssets.YFI,
            SupportedAssets.WBTC2,
            SupportedAssets.USDP,
            SupportedAssets.FEI,
            SupportedAssets.LINK,
            SupportedAssets.MKR,
            SupportedAssets.REP,
            SupportedAssets.AAVE,
            SupportedAssets.BAT,
          ]
        }

        default:
          return []
      }
    }
    default:
      return [
        SupportedAssets.WETH,
        SupportedAssets.DAI,
        SupportedAssets.LINK,
        SupportedAssets.USDCE,
        SupportedAssets.WBTC,
        SupportedAssets.USDT,
        SupportedAssets.AAVE,
        SupportedAssets.EURS,
        // SupportedAssets.WMATIC
      ]
  }
}