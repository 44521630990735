import { Text, HStack, TableCellProps, Tr, Flex, Image, Box } from '@chakra-ui/react'
import { useKnownAssetsOnChain } from 'hooks/Tokens'
import { getTypography } from 'theme/typographies'
import { NewWalletAssetData } from './WalletMenuAssets'
import { SupportedAssets } from 'types/1delta'
import { CustomAccordionItem } from 'components/CustomAccordionItem'
import CurrencyLogo from 'components/CurrencyLogo'
import { Currency } from '@1delta/base-sdk'
import { CustomTable, TableCell } from 'components/Table'
import { HoverableRow } from 'components/Table/HoverableRow'
import { ColorMode } from 'theme'
import { useEffect, useMemo } from 'react'
import { validateColor } from 'theme/colors'
import { getChainInfo } from 'constants/chainInfo'
import { FadeInAnimation } from 'components/PageLayout'
import { formatAbbreviatedDollarNumber, formatAbbreviatedNumber } from 'utils/tableUtils/format'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { chainIdToNetworkName } from 'lib/hooks/useCurrencyLogoURIs'
import { getAvailableSpotChainIds } from 'constants/chains'

function CurrencyLogoWithChainId({ currency }: { currency: Currency | undefined }) {
  const logoUrl = getChainInfo(currency?.chainId ?? (0 as any))?.logoUrl
  return !currency ? null : (
    <Flex alignItems="flex-end" mr={'-0.75rem'}>
      <CurrencyLogo currency={currency} size={'24px'} />
      <Flex
        position="relative"
        right="12px"
        borderRadius="full"
        bg={validateColor('Surface/Surface-primary 2')}
        p="0.1rem"
      >
        <Image src={logoUrl} alt={'Network Logo'} width={'10px'} height={'10px'} />
      </Flex>
    </Flex>
  )
}

const ChainAssetRowCell: React.FC<TableCellProps> = ({ children, ...props }) => {
  return (
    <TableCell p="0.25rem 0.5rem" border="none" {...props}>
      {children}
    </TableCell>
  )
}

interface ChainAssetRowProps {
  currency?: Currency
  walletBalance: number
  walletBalanceUsd: number
  theme: ColorMode
  isLastRow: boolean
  onSwapIn: (() => void) | undefined
  onSwapOut: (() => void) | undefined
  onExplorer: () => void
}

const ChainAssetRow: React.FC<ChainAssetRowProps> = ({
  currency,
  walletBalanceUsd,
  walletBalance,
  theme,
  isLastRow,
  onSwapIn,
  onSwapOut,
  onExplorer,
}) => {
  return !currency ? null : (
    <>
      <HoverableRow
        theme={theme}
        border="none"
        borderRadius="0.25rem"
        onSwapIn={onSwapIn}
        onSwapOut={onSwapOut}
        onExplorer={onExplorer}
        small={true}
        iconButtonImageProps={{
          w: '1rem',
          h: '1rem',
        }}
        iconButtonProps={{
          w: '1.5rem',
          h: '1.5rem',
        }}
        appendCss={FadeInAnimation}
      >
        <ChainAssetRowCell borderLeftRadius="0.25rem">
          <HStack>
            <CurrencyLogoWithChainId currency={currency} />
            <Text style={getTypography('Typography/Body-Labels/Medium/Body-Label-Medium')}>{currency.symbol}</Text>
          </HStack>
        </ChainAssetRowCell>
        <ChainAssetRowCell textAlign="right" borderRightRadius="0.25rem">
          <HStack justifyContent="flex-end">
            <Text
              opacity={0.33}
              color={validateColor('Text/Lables/Label-text-default')}
              style={getTypography('Typography/Small/Normal/Small 1')}
            >
              {formatAbbreviatedNumber(walletBalance)}
            </Text>
            <Text
              color={validateColor('Text/Lables/Label-text-default')}
              style={getTypography('Typography/Small/Normal/Small 1')}
            >
              {formatAbbreviatedDollarNumber(walletBalanceUsd)}
            </Text>
          </HStack>
        </ChainAssetRowCell>
      </HoverableRow>
      {!isLastRow && <Tr bg="transparent" h="0.75rem" />}
    </>
  )
}

interface WalletMenuTableRowProps {
  assetId: SupportedAssets
  assetData: NewWalletAssetData
  selectedChainIds: number[]
  theme: ColorMode
  accordionIndex: number[]
  setAccordionIndex: (index: number[]) => void
  assetRowIndex: number
  history: any
  onCloseWalletMenu: () => void
}

export const WalletMenuAssetsRow: React.FC<WalletMenuTableRowProps> = ({
  assetId,
  assetData,
  selectedChainIds,
  theme,
  setAccordionIndex,
  accordionIndex,
  assetRowIndex,
  history,
  onCloseWalletMenu,
}) => {
  const knownMapping = useKnownAssetsOnChain(assetId)
  const isMapped = Object.values(knownMapping).length > 0
  const currencies = isMapped ? knownMapping : currenciesFromAssetData(assetData)

  const [leadCurrency, chainIdsForCurrency] = useMemo(() => {
    const filteredChainIds = Object.keys(assetData).filter((chainId) => selectedChainIds.includes(Number(chainId)))
    if (Object.keys(currencies).length === 0) {
      const lead = Object.values(assetData)[0]?.[0]?.currency
      return [lead, filteredChainIds]
    }
    return [Object.values(currencies).filter((a) => Boolean(a))[0], filteredChainIds]
  }, [currencies, selectedChainIds])

  const totalBalanceUsd = selectedChainIds
    .map((chainId) => assetData[chainId]?.reduce((a, b) => (a ?? 0) + (b?.walletBalanceUSD ?? 0), 0))
    .reduce((acc, chainData) => {
      return acc + (chainData ?? 0)
    }, 0)

  const totalBalance = selectedChainIds
    .map((chainId) => assetData[chainId]?.reduce((a, b) => (a ?? 0) + (b?.walletBalance ?? 0), 0))
    .reduce((acc, chainData) => {
      return acc + (chainData ?? 0)
    }, 0)

  const handleExpandItem = (isExpanded: boolean) => {
    if (isExpanded) {
      setAccordionIndex([...accordionIndex, assetRowIndex])
    } else {
      setAccordionIndex(accordionIndex.filter((index) => index !== assetRowIndex))
    }
  }

  const isCurrentItemExpanded = accordionIndex.includes(assetRowIndex)

  useEffect(() => {
    if (isCurrentItemExpanded && totalBalanceUsd === 0) {
      handleExpandItem(false)
    }
  }, [selectedChainIds])

  const title =
    !isMapped && !selectedChainIds.includes(leadCurrency?.chainId) ? null : ( // show nothing if notMapped and not matching chainId
      <HStack w="100%" justify="space-between">
        <HStack gap="0.375rem">
          {isMapped ? (
            <CurrencyLogo currency={leadCurrency} size="1.375rem" />
          ) : (
            <CurrencyLogoWithChainId currency={leadCurrency} />
          )}
          <Text style={getTypography('Typography/Body-Labels/Medium/Body-Label-Medium')}>{leadCurrency?.symbol}</Text>
        </HStack>
        <HStack justify="flex-end">
          <Text
            opacity={0.33}
            color={
              isCurrentItemExpanded
                ? validateColor('Text/Lables/Label-text-disabled')
                : validateColor('Text/Headings & Titles/Title-text')
            }
            style={getTypography('Typography/Body-Labels/Normal/Sub-Label')}
            transition="all 0.2s"
          >
            {Number.isNaN(totalBalance) || totalBalance === 0 ? '' : `${formatAbbreviatedNumber(totalBalance)}`}
          </Text>
          <Text
            color={
              isCurrentItemExpanded
                ? validateColor('Text/Lables/Label-text-disabled')
                : validateColor('Text/Headings & Titles/Title-text')
            }
            style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
            transition="all 0.2s"
          >
            {Number.isNaN(totalBalanceUsd) || totalBalanceUsd === 0
              ? ''
              : formatAbbreviatedDollarNumber(totalBalanceUsd)}
          </Text>
        </HStack>
      </HStack>
    )

  const getSwapUrl = (chainId: number) => {
    return `/swap?chain=${chainIdToNetworkName(chainId)}`
  }
  const getSwapIsSupported = (chainId: number) => {
    return getAvailableSpotChainIds().includes(chainId)
  }
  const getCurrencySymbolOrAddress = (currency?: Currency) => {
    return currency ? (currency?.isNative ? currency.symbol : currency?.wrapped.address) : ''
  }
  const onSwapIn = (swapIsSupported: boolean, swapUrl: string, inputCurrency: string) => {
    return swapIsSupported
      ? () => {
        onCloseWalletMenu()
        history.push(`${swapUrl}&inputCurrency=${inputCurrency}`)
      }
      : undefined
  }
  const onSwapOut = (swapIsSupported: boolean, swapUrl: string, outputCurrency: string) => {
    return swapIsSupported
      ? () => {
        onCloseWalletMenu()
        history.push(`${swapUrl}&outputCurrency=${outputCurrency}`)
      }
      : undefined
  }
  const onExplorer = (currency: Currency) => {
    return () => {
      window.open(getExplorerLink(currency.chainId, currency.wrapped.address, ExplorerDataType.TOKEN), '_blank')
    }
  }

  const body = !isMapped ? null : (
    <>
      {chainIdsForCurrency.map((chainId) => {
        if (!selectedChainIds.includes(Number(chainId))) return null
        const chainData = assetData[chainId] ?? []
        let isLastRow = false
        return chainData.map((tokenEntry) => {
          if (chainId === chainIdsForCurrency[chainIdsForCurrency.length - 1]) {
            isLastRow = chainData.indexOf(tokenEntry) === chainData.length - 1
          }
          const onSwapInToken = onSwapIn(
            getSwapIsSupported(Number(chainId)),
            getSwapUrl(Number(chainId)),
            getCurrencySymbolOrAddress(tokenEntry?.currency)
          )
          const onSwapOutToken = onSwapOut(
            getSwapIsSupported(Number(chainId)),
            getSwapUrl(Number(chainId)),
            getCurrencySymbolOrAddress(tokenEntry?.currency)
          )
          const onExplorerToken = onExplorer(tokenEntry?.currency)

          return tokenEntry?.walletBalance && tokenEntry.walletBalance > 0 ? (
            <ChainAssetRow
              key={chainId + tokenEntry?.currency.symbol}
              currency={tokenEntry.currency}
              walletBalance={tokenEntry?.walletBalance || 0}
              walletBalanceUsd={tokenEntry?.walletBalanceUSD || 0}
              theme={theme}
              isLastRow={isLastRow}
              onSwapIn={onSwapInToken}
              onSwapOut={onSwapOutToken}
              onExplorer={onExplorerToken}
            />
          ) : null
        })
      })}
    </>
  )

  const swapIsSupported = getSwapIsSupported(leadCurrency?.chainId || 0)
  const swapUrl = getSwapUrl(leadCurrency?.chainId || 0)
  const currencySymbolOrAddress = getCurrencySymbolOrAddress(leadCurrency)

  return (
    <CustomAccordionItem
      display={leadCurrency && totalBalance > 0 ? 'block' : 'none'}
      title={
        isMapped ? (
          title
        ) : (
          <CustomTable
            header={<></>}
            body={
              <HoverableRow
                theme={theme}
                border="none"
                borderRadius="0.25rem"
                small={true}
                iconButtonImageProps={{
                  w: '1rem',
                  h: '1rem',
                }}
                iconButtonProps={{
                  w: '1.5rem',
                  h: '1.5rem',
                }}
                appendCss={FadeInAnimation}
                onSwapOut={onSwapOut(swapIsSupported, swapUrl, currencySymbolOrAddress)}
                onSwapIn={onSwapIn(swapIsSupported, swapUrl, currencySymbolOrAddress)}
                onExplorer={onExplorer(leadCurrency)}
              >
                {title}
              </HoverableRow>
            }
            border="none"
            borderRadius="0.25rem"
            background={'transparent'}
          />
        )
      }
      body={
        isMapped ? (
          <CustomTable
            header={<></>}
            body={body}
            border="none"
            borderRadius="0.25rem"
            py="1rem"
            background={'transparent'}
          />
        ) : null
      }
      accordionButtonProps={{
        px: '0',
        py: '0.25rem',
        _disabled: {
          opacity: 1,
          cursor: 'default',
        },
      }}
      bodyProps={{
        padding: '0',
      }}
      border="none"
      onExpand={() => handleExpandItem(!isCurrentItemExpanded)}
      showArrow={isMapped}
      addArrowPadding
    />
  )
}

function currenciesFromAssetData(assetData: NewWalletAssetData) {
  let ad: any = {}
  Object.entries(assetData).map(([k, v]) => {
    if (v.currency) ad[k] = v.currency
  })
  return ad
}
