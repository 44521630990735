import { useEffect, useMemo, useState } from 'react'
import { useAllTokenAddressesPerChain, useSubscribeConfigs } from 'state/globalNetwork/hooks'
import { useAppDispatch } from 'state/hooks'
import { useWeb3ReactWrapped } from 'hooks/web3'
import _ from 'lodash'
import { setSubscribeConfigXChain } from 'state/globalNetwork/actions'
import { fetchAllBalances, fetchgasData } from 'state/globalNetwork/fetchAllBalanceData'
import useDebounce from 'hooks/useDebounce'

export function usePollingXChain() {
  // we call the hook to update chainId, account, provider etc.
  const { account } = useWeb3ReactWrapped()

  // fetches timestamp and
  const [repeater, setRepeater] = useState(0)
  const dispatch = useAppDispatch()
  const data = useSubscribeConfigs()
  const chainIds = Object.keys(data).map(d => Number(d))
  const allAddresses = useAllTokenAddressesPerChain(chainIds)

  const config = useMemo(() => Object.assign(
    {},
    ...chainIds.map(cId => {
      return {
        [cId]: {
          account,
          tokens: _.uniq([...allAddresses[cId], ...(data[cId]?.tokens ?? [])]),
          approvalTargets: data[cId]?.approvalTargets ?? [],
        }
      }
    })
  ), [allAddresses, account, data])

  // matadata as trigger for subscribe change
  const meta = useDebounce(_.sum(Object.values(config).map((c: any) => c.tokens?.length)), 500)

  useEffect(() => {
    dispatch(setSubscribeConfigXChain(config))
  }, [meta, account])

  useEffect(() => {
    // we also directly fetch the native balance if connected
    dispatch(fetchAllBalances())
    const intervalId = setInterval(() => {
      setRepeater((prevState) => prevState + 1)
    }, 30000) // 30s
    return () => clearInterval(intervalId)
  }, [repeater, meta, account])

  const deps = chainIds.join("-")
  useEffect(() => {
    dispatch(fetchgasData({ chainIds }))
    const intervalId = setInterval(() => {
      setRepeater((prevState) => prevState + 1)
    }, 60000) // 1min
    return () => clearInterval(intervalId)
  }, [repeater, deps])
}